// ThirdEyeVisualizer.js - Enhanced with multiple visualization styles
import React, { useState, useEffect, useRef } from 'react';

/**
 * ThirdEyeVisualizer - A meditation-focused audio visualization component
 * with three distinct visualization styles and improved error handling
 */
const ThirdEyeVisualizer = ({
  analyzerNode = null,
  isPlaying = false,
  size = 300,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  tertiaryColor = '#9e65ff',
  sensitivity = 1.5,
  voiceFocus = true,
  id = 'default-visualizer'
}) => {
  // References
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const dataArrayRef = useRef(null);
  const timeRef = useRef(0);
  const lastTimeRef = useRef(0);
  const mountedRef = useRef(true);
  
  // State
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, dpr: 1 });
  const [error, setError] = useState(null);
  const [analyzerReady, setAnalyzerReady] = useState(false);
  const [visualStyle, setVisualStyle] = useState(0); // 0, 1, or 2 for the three styles
  
  // Define safe colors that won't cause rendering issues
  const safeColors = {
    style0: {
      primary: '#0066ff',      // Blue spiral color (Image 1)
      secondary: '#0033cc',
      background: '#000000'
    },
    style1: {
      primary: '#ff0000',      // Center color for radial gradient (Image 2)
      middle: '#ffff00',       // Middle yellow color
      outer: '#00ff99',        // Outer green/teal color
      background: '#000000'
    },
    style2: {
      primary: '#00ffcc',      // Teal/turquoise spiral color (Image 3)
      secondary: '#00ccaa',
      background: '#000000'
    },
    // Fallback colors from props
    fallback: {
      primary: primaryColor || '#7d12ff',
      secondary: secondaryColor || '#5636f3',
      tertiary: tertiaryColor || '#9e65ff',
      background: '#02073c'
    }
  };
  
  // Ensure sensitivity is a valid number
  const safeSensitivity = typeof sensitivity === 'number' && !isNaN(sensitivity) ? sensitivity : 1.5;

  // Set up event listener for analyzer availability
  useEffect(() => {
    // Try to get shared analyzer from DOM element if available
    if (!analyzerNode) {
      const handleAnalyzerAvailable = (event) => {
        if (event.detail && event.detail.analyzer) {
          console.log("ThirdEyeVisualizer: Received analyzer from event", id);
          setAnalyzerReady(true);
        }
      };
      
      // Listen for analyzer availability event
      if (typeof document !== 'undefined') {
        document.addEventListener('analyzer-available', handleAnalyzerAvailable);
      }
      
      return () => {
        if (typeof document !== 'undefined') {
          document.removeEventListener('analyzer-available', handleAnalyzerAvailable);
        }
      };
    }
  }, [analyzerNode, id]);

  // Handle canvas resizing and initialization
  useEffect(() => {
    const updateDimensions = () => {
      if (!canvasRef.current) return;
      
      const dpr = window.devicePixelRatio || 1;
      const rect = canvasRef.current.getBoundingClientRect();
      const safeWidth = Math.max(100, rect.width || 100);
      const safeHeight = Math.max(100, rect.height || 100);
      
      setDimensions({ width: safeWidth, height: safeHeight, dpr });
      
      // Update canvas dimensions
      canvasRef.current.width = safeWidth * dpr;
      canvasRef.current.height = safeHeight * dpr;
      
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) ctx.scale(dpr, dpr);
    };
    
    updateDimensions();
    
    // Set up debounced resize handler
    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(updateDimensions, 100);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeout) clearTimeout(resizeTimeout);
    };
  }, [size]);

  // Initialize and validate the analyzer
  useEffect(() => {
    mountedRef.current = true;
    
    const validateAnalyzer = () => {
      if (!analyzerNode) {
        setAnalyzerReady(false);
        return;
      }
      
      try {
        // Check if analyzer has required methods
        const hasGetByteFrequency = typeof analyzerNode.getByteFrequencyData === 'function';
        const hasValidFFTSize = typeof analyzerNode.fftSize === 'number' && analyzerNode.fftSize > 0;
        
        // Initialize data array if needed
        if (hasValidFFTSize && !dataArrayRef.current) {
          try {
            dataArrayRef.current = new Uint8Array(analyzerNode.frequencyBinCount || 1024);
          } catch (err) {
            console.warn("ThirdEyeVisualizer: Error creating data array:", err);
            dataArrayRef.current = new Uint8Array(1024);
          }
        }
        
        // Test if analyzer is working
        if (hasGetByteFrequency && hasValidFFTSize) {
          try {
            analyzerNode.getByteFrequencyData(dataArrayRef.current);
            setAnalyzerReady(true);
          } catch (err) {
            console.warn("ThirdEyeVisualizer: Analyzer test failed:", err);
            setAnalyzerReady(false);
          }
        } else {
          setAnalyzerReady(false);
        }
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Analyzer validation error:", err);
        setAnalyzerReady(false);
      }
    };
    
    validateAnalyzer();
    
    // Cleanup animation on unmount
    return () => {
      mountedRef.current = false; 
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
    };
  }, [analyzerNode]);

  // Utility function for value mapping
  const mapValue = (value, inMin, inMax, outMin, outMax) => {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  };

  // Draw polygon shapes for visualization
  const polygon = (ctx, x, y, radius, npoints, rotation = 0) => {
    const angle = (Math.PI * 2) / npoints;
    ctx.beginPath();
    for (let a = rotation; a < Math.PI * 2 + rotation; a += angle) {
      const sx = x + Math.cos(a) * radius;
      const sy = y + Math.sin(a) * radius;
      if (a === rotation) ctx.moveTo(sx, sy);
      else ctx.lineTo(sx, sy);
    }
    ctx.closePath();
  };

  // Get audio data for visualization
  const getAudioData = () => {
    let bass = 0, mid = 0, treble = 0, hasReal = false;
    
    // Try to get data from analyzer
    if (analyzerNode && analyzerReady) {
      try {
        if (!dataArrayRef.current) {
          dataArrayRef.current = new Uint8Array(analyzerNode.frequencyBinCount || 1024);
        }
        
        analyzerNode.getByteFrequencyData(dataArrayRef.current);
        
        // Calculate bass average (low frequencies)
        let bassSum = 0, bassCount = 0;
        for (let i = 1; i < 15 && i < dataArrayRef.current.length; i++) {
          bassSum += dataArrayRef.current[i] || 0;
          bassCount++;
        }
        bass = bassCount ? bassSum / bassCount : 0;
        
        // Calculate mid-range average
        let midSum = 0, midCount = 0;
        for (let i = 15; i < 80 && i < dataArrayRef.current.length; i++) {
          midSum += dataArrayRef.current[i] || 0;
          midCount++;
        }
        mid = midCount ? midSum / midCount : 0;
        
        // Calculate treble average (high frequencies)
        let trebleSum = 0, trebleCount = 0;
        for (let i = 80; i < 200 && i < dataArrayRef.current.length; i++) {
          trebleSum += dataArrayRef.current[i] || 0;
          trebleCount++;
        }
        treble = trebleCount ? trebleSum / trebleCount : 0;
        
        // Apply voice focus if requested
        if (voiceFocus) {
          mid *= 1.2;
          bass *= 0.8;
          treble *= 0.7;
        }
        
        hasReal = true;
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Error getting analyzer data:", err);
      }
    }
    
    // If no real data, generate simulated data
    if (!hasReal) {
      const t = timeRef.current;
      
      // Generate dynamic values based on playing state
      bass = isPlaying ? 
        50 + Math.sin(t * 0.6) * 45 : 
        30 + Math.sin(t * 0.3) * 20;
      
      mid = isPlaying ? 
        60 + Math.sin(t * 0.8 + 0.5) * 50 : 
        25 + Math.sin(t * 0.4 + 0.3) * 15;
      
      treble = isPlaying ? 
        55 + Math.sin(t * 0.7 + 0.9) * 40 : 
        20 + Math.sin(t * 0.35 + 0.7) * 15;
    }
    
    // Apply sensitivity and return
    return {
      bass: bass * safeSensitivity,
      mid: mid * safeSensitivity,
      treble: treble * safeSensitivity,
      isReal: hasReal
    };
  };

  // Style 0: Blue Spiral (Image 1)
  const drawStyle0 = (ctx, canvasWidth, canvasHeight, audio, time) => {
    // Set up canvas
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radius = Math.min(centerX, centerY) * 0.9;
    
    // Clear background
    ctx.fillStyle = safeColors.style0.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    
    // Get audio values to modulate visualization
    const intensity = isPlaying ? 
      0.8 + (audio.mid / 255) * 0.2 : 
      0.6 + Math.sin(time * 0.5) * 0.1;
    
    const waveCount = isPlaying ? 
      8 + Math.floor((audio.bass / 255) * 4) : 
      8;
    
    const detail = isPlaying ? 
      80 + Math.floor((audio.treble / 255) * 40) : 
      100;
    
    // Draw spiral wave pattern
    ctx.strokeStyle = safeColors.style0.primary;
    ctx.lineWidth = 1;
    
    for (let wave = 0; wave < waveCount; wave++) {
      const phaseOffset = (Math.PI * 2 * wave) / waveCount;
      
      ctx.beginPath();
      for (let i = 0; i <= detail; i++) {
        const angle = (i / detail) * Math.PI * 8 + time + phaseOffset;
        const radiusOffset = (Math.sin(angle * 3) * 0.15 + 0.85) * intensity;
        const waveRadius = radius * (0.4 + (i / detail) * 0.4) * radiusOffset;
        
        const x = centerX + Math.cos(angle) * waveRadius;
        const y = centerY + Math.sin(angle) * waveRadius;
        
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();
    }
  };

  // Style 1: Radial Concentric Pattern (Image 2)
  const drawStyle1 = (ctx, canvasWidth, canvasHeight, audio, time) => {
    // Set up canvas
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radius = Math.min(centerX, centerY) * 0.9;
    
    // Clear background
    ctx.fillStyle = safeColors.style1.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    
    // Get audio values to modulate visualization
    const intensity = isPlaying ? 
      (audio.bass / 255) * 0.4 + 0.6 : 
      0.7 + Math.sin(time * 0.3) * 0.1;
    
    const density = isPlaying ? 
      Math.floor(30 + (audio.treble / 255) * 20) : 
      40;
    
    // Draw radial concentric pattern
    const ringCount = 80;
    const dotsPerRing = density;
    
    for (let ring = 0; ring < ringCount; ring++) {
      const progress = ring / ringCount;
      const ringRadius = radius * progress;
      
      // Create gradient from center to outer edge
      let color;
      if (progress < 0.3) {
        // Red to yellow gradient for inner portion
        const localProgress = progress / 0.3;
        color = interpolateColors(
          safeColors.style1.primary,
          safeColors.style1.middle,
          localProgress
        );
      } else {
        // Yellow to green/teal gradient for outer portion
        const localProgress = (progress - 0.3) / 0.7;
        color = interpolateColors(
          safeColors.style1.middle,
          safeColors.style1.outer,
          localProgress
        );
      }
      
      ctx.fillStyle = color;
      
      // Draw dots around the ring
      for (let dot = 0; dot < dotsPerRing; dot++) {
        const angle = (dot / dotsPerRing) * Math.PI * 2 + time * 0.1 * (ring % 2 === 0 ? 1 : -1);
        
        // Calculate dot position
        const x = centerX + Math.cos(angle) * ringRadius;
        const y = centerY + Math.sin(angle) * ringRadius;
        
        // Modify dot size based on audio and position
        const baseDotSize = ringRadius < 30 ? 2 : 1.5;
        const dotSizeModifier = isPlaying ?
          1 + Math.sin(time * 3 + ring * 0.2 + dot * 0.1) * 0.3 * intensity :
          1 + Math.sin(time * 0.5 + ring * 0.1) * 0.1;
        
        const dotSize = baseDotSize * dotSizeModifier;
        
        // Draw the dot
        ctx.beginPath();
        ctx.arc(x, y, dotSize, 0, Math.PI * 2);
        ctx.fill();
      }
    }
  };

  // Style 2: Teal Spiral (Image 3)
  const drawStyle2 = (ctx, canvasWidth, canvasHeight, audio, time) => {
    // Set up canvas
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radius = Math.min(centerX, centerY) * 0.9;
    
    // Clear background
    ctx.fillStyle = safeColors.style2.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    
    // Get audio values to modulate visualization
    const intensity = isPlaying ? 
      0.8 + (audio.mid / 255) * 0.2 : 
      0.6 + Math.sin(time * 0.5) * 0.1;
    
    const waveCount = isPlaying ? 
      10 + Math.floor((audio.bass / 255) * 4) : 
      12;
    
    const detail = isPlaying ? 
      80 + Math.floor((audio.treble / 255) * 40) : 
      100;
    
    // Draw spiral wave pattern
    ctx.strokeStyle = safeColors.style2.primary;
    ctx.lineWidth = 1;
    
    for (let wave = 0; wave < waveCount; wave++) {
      const phaseOffset = (Math.PI * 2 * wave) / waveCount;
      
      ctx.beginPath();
      for (let i = 0; i <= detail; i++) {
        const angle = (i / detail) * Math.PI * 8 + time + phaseOffset;
        const radiusOffset = (Math.sin(angle * 3) * 0.15 + 0.85) * intensity;
        const waveRadius = radius * (0.4 + (i / detail) * 0.4) * radiusOffset;
        
        const x = centerX + Math.cos(angle) * waveRadius;
        const y = centerY + Math.sin(angle) * waveRadius;
        
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();
    }
  };

  // Helper function to interpolate between colors
  const interpolateColors = (color1, color2, factor) => {
    if (factor <= 0) return color1;
    if (factor >= 1) return color2;
    
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);
    
    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);
    
    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));
    
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  // Animation effect - core visualization rendering
  useEffect(() => {
    if (!canvasRef.current || !mountedRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      setError(new Error("Could not get canvas context"));
      return;
    }
    
    // Get canvas dimensions
    const canvasWidth = dimensions.width || canvas.width / (dimensions.dpr || 1);
    const canvasHeight = dimensions.height || canvas.height / (dimensions.dpr || 1);
    
    // Animation function
    const animate = (timestamp) => {
      if (!mountedRef.current || !canvasRef.current) return;
      
      // Calculate delta time
      const deltaTime = timestamp - (lastTimeRef.current || timestamp);
      lastTimeRef.current = timestamp;
      
      // Cap delta to prevent jumps after tab switching
      const cappedDelta = Math.min(deltaTime, 100);
      timeRef.current += cappedDelta * 0.001;
      
      // Get audio data
      const audioData = getAudioData();
      
      // Choose the correct drawing function based on visual style
      try {
        switch (visualStyle) {
          case 0:
            drawStyle0(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
            break;
          case 1:
            drawStyle1(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
            break;
          case 2:
            drawStyle2(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
            break;
          default:
            drawStyle0(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
        }
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Error in drawing function:", err);
        
        // Clear canvas and show error state
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
      }
      
      // Continue animation loop
      animationRef.current = requestAnimationFrame(animate);
    };
    
    // Start animation
    animationRef.current = requestAnimationFrame(animate);
    
    // Cleanup on unmount or when dependencies change
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
    };
  }, [safeColors, isPlaying, safeSensitivity, voiceFocus, dimensions, visualStyle]);

  // Handle clicks to switch visualization styles
  const handleVisualizerClick = () => {
    setVisualStyle((prev) => (prev + 1) % 3);
  };

  // If error occurred, return nothing
  if (error) return null;

  // Render the canvas
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        boxShadow: 'inset 0 0 30px rgba(0,0,0,0.5)',
        cursor: 'pointer'
      }}
      data-visualizer-id={id}
      onClick={handleVisualizerClick}
    >
      <canvas 
        ref={canvasRef} 
        style={{ 
          width: '100%', 
          height: '100%', 
          display: 'block', 
          objectFit: 'cover' 
        }} 
      />
    </div>
  );
};

export default React.memo(ThirdEyeVisualizer);