/**
 * SilentAudioBlob.js
 * Utility functions for creating silent audio fallbacks and handling audio URLs
 */

/**
 * Creates a silent audio blob URL to use as a fallback
 * @returns {string} URL to a silent audio blob
 */
export function createSilentAudioBlob() {
  try {
    const bufferLength = 10000;
    const arrayBuffer = new ArrayBuffer(bufferLength);
    const uint8View = new Uint8Array(arrayBuffer);
    const silentHeader = [
      0xFF, 0xFB, 0x90, 0x44, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0
    ];
    const headerLength = silentHeader.length;
    
    // Fill the buffer with repeated silent header bytes
    for (let i = 0; i < bufferLength; i += headerLength) {
      for (let j = 0; j < headerLength; j++) {
        const pos = i + j;
        if (pos < bufferLength) {
          uint8View[pos] = silentHeader[j];
        }
      }
    }
    
    const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error creating silent audio blob:", error);
    // Fallback data URL (minified version)
    return "data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4LjI5LjEwMAAAAAAAAAAAAAAA//tQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWGluZwAAAA8AAAACAAADmAD///////////////////////////////////////////8AAAA5TEFNRTMuMTAwAc0AAAAAAAAAABSAJAJAQgAAgAAAA5iJWtsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//sQZAAP8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAETEFNRTMuMTAwVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV//sQZB4P8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAEVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
  }
}

/**
 * Creates a fallback audio blob for use when other sources fail
 * @returns {string} URL to fallback audio
 */
export function createFallbackAudioBlob() {
  return createSilentAudioBlob();
}

/**
 * Returns the first valid audio URL from various sources or a silent blob if none is valid
 * @param {string} initialAudioUrl - Initial URL to check
 * @param {string} currentAudioUrl - Current URL to check
 * @param {Object} globalAudioRef - Reference to global audio state
 * @param {Object} audioUrlRef - Reference to audio URL
 * @returns {string} A valid audio URL or fallback
 */
export function getValidAudioUrl(initialAudioUrl, currentAudioUrl, globalAudioRef, audioUrlRef) {
  const sources = [
    initialAudioUrl,
    currentAudioUrl,
    globalAudioRef?.current?.getBestAudioUrl?.(),
    audioUrlRef?.current
  ];
  
  for (const url of sources) {
    if (
      url &&
      typeof url === 'string' &&
      (url.startsWith('http://') ||
        url.startsWith('https://') ||
        url.startsWith('blob:') ||
        url.startsWith('data:'))
    ) {
      return url;
    }
  }
  
  console.warn("No valid URL found, using silent audio");
  return createSilentAudioBlob();
}

