import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SafeListenStepWrapper from './components/steps/SafeListenStepWrapper';
import StableListenStep from './components/steps/ListenStep';

// Global state and hooks
import globalAudioState from './components/audio/globalAudioState';
import useLoading from './components/steps/hooks/useLoading';
import useToast from './hooks/useToast';

// Step components
import CreateStep from './components/steps/CreateStep';
import ScriptStep from './components/steps/ScriptStep';
import VoiceStep from './components/steps/VoiceStep';
import MusicSelection from './components/steps/MusicSelection';
import AudioStep from './components/steps/AudioStep';

// Common components
import ProgressBar from './components/common/ProgressBar';
import StepNavigation from './components/common/StepNavigation';
import LoadingOverlay from './components/common/LoadingOverlay';
import CustomToastContainer from './components/common/CustomToast';

// Theme and layout
import { ThemeProvider } from '../Dashboard/components/contexts/ThemeContext';
import { useLayout } from '../Dashboard/components/layout/AppLayout';

// Styles and external services
import './App.css';
import audioService from '../../services/audioService';
import sseManager from './utils/sseManager';
import 'react-toastify/dist/ReactToastify.css';

// Audio utilities
import { createSilentAudioBlob } from './components/audio/SilentAudioBlob';



/* ===================== ErrorBoundary Component ===================== */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: 20, color: 'red' }}>
          An error occurred in the audio player.
        </div>
      );
    }
    return this.props.children;
  }
}

/* ===================== Helper Functions ===================== */

const OPERATION_PHASES = {
  PREPARATION: "preparation",
  SCRIPT_GENERATION: "script_generation",
  SCRIPT_PROCESSING: "script_processing",
  VOICE_SYNTHESIS: "voice_synthesis",
  AUDIO_LOADING: "audio_loading",
  AUDIO_PROCESSING: "audio_processing",
  AUDIO_EFFECTS: "audio_effects",
  EXPORT: "export",
  FINALIZATION: "finalization"
};

const isValidUrl = (url) => {
  return !!(url && typeof url === 'string' &&
    (url.startsWith('http://') || url.startsWith('https://') || 
     url.startsWith('blob:') || url.startsWith('data:')));
};

// Generate TTS URL from a filename and base URL.
const getTtsUrl = (filename, baseUrl) => {
  if (!filename) return '';
  const DEFAULT_API_BASE = 'https://api.pause.site';
  let apiBase = baseUrl || process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE;
  if (!apiBase || apiBase === 'undefined' || typeof apiBase !== 'string') {
    console.warn('Invalid API base URL detected, using default:', DEFAULT_API_BASE);
    apiBase = DEFAULT_API_BASE;
  }
  let cleanFilename = filename.includes('/') ? filename.split('/').pop() : filename;
  if (cleanFilename.startsWith("TTS/")) {
    cleanFilename = cleanFilename.substring(4);
  }
  const url = `${apiBase}/tts/${cleanFilename}`;
  if (url.includes('undefined') || !url.startsWith('http')) {
    console.error('Generated invalid TTS URL:', url);
    return `${DEFAULT_API_BASE}/tts/${cleanFilename}`;
  }
  return url;
};

/**
 * Verifies if an audio URL is valid and accessible
 * @param {string} url - The URL to verify
 * @returns {Promise<Object>} - Object with validity status and details
 */
const verifyAudioUrl = async (url) => {
  if (!url || typeof url !== 'string' || !url.startsWith('http')) {
    return { valid: false, status: 0, reason: 'Invalid URL' };
  }
  
  try {
    console.log(`Verifying audio URL using Range GET request: ${url.substring(0, 60)}...`);
    
    // Use a range request (first 1000 bytes) instead of HEAD
    // This is more likely to work with S3 signed URLs
    const response = await fetch(url, {
      method: 'GET', // Use GET instead of HEAD
      headers: {
        'Range': 'bytes=0-1000', // Request just the first 1000 bytes
        'Accept': 'audio/mpeg, audio/*, */*' // Accept audio types
      },
      cache: 'no-store'
    });
    
    console.log(`File existence check: ${response.ok ? 'OK' : 'Failed'} (status: ${response.status})`);
    
    // 206 (Partial Content) is expected for successful range requests
    // 200 (OK) may also occur if range requests are not supported but file exists
    if (response.status === 206 || response.status === 200) {
      // Try to get content type to verify it's audio
      const contentType = response.headers.get('content-type');
      const isAudio = contentType && (
        contentType.includes('audio') ||
        contentType.includes('mpeg') ||
        contentType.includes('mp3') ||
        contentType.includes('ogg') ||
        contentType.includes('wave')
      );
      
      if (isAudio) {
        console.log("Verified audio file with correct content type");
      } else {
        console.log("File exists but content type is not audio:", contentType);
      }
      
      return { valid: true, status: response.status };
    }
    
    // For all other status codes (404, 403, etc.)
    return { valid: false, status: response.status, reason: response.statusText };
  } catch (error) {
    console.warn(`Error verifying URL: ${error.message}`);
    return { valid: false, status: 0, reason: error.message };
  }
};

const verifyMixedAudioFile = async (mixResult) => {
  try {
    if (!mixResult || !mixResult.file_path) {
      console.warn("Cannot verify file: missing file path in mix result");
      return { valid: false, status: 0, reason: 'Missing file path' };
    }
    
    const mixedUrl = mixResult.file_path;
    console.log("Verifying file exists by fetching a small portion...");
    const verifyResult = await verifyAudioUrl(mixedUrl);
    
    console.log(`File existence check: ${verifyResult.valid ? 'OK' : 'Failed'} (status: ${verifyResult.status})`);
    
    if (!verifyResult.valid) {
      console.warn("File verification failed, but continuing anyway");
    }
    
    return verifyResult;
  } catch (checkErr) {
    console.warn("Error checking file (continuing anyway):", checkErr);
    return { valid: false, status: 0, reason: checkErr.message };
  }
};

// Fetch audio with multiple fallback strategies
const fetchWithFallback = async (url) => {
  if (url.startsWith('blob:')) {
    return new Blob([await (await fetch(url)).arrayBuffer()], { type: 'audio/mpeg' });
  }
  let attempts = 0;
  const maxAttempts = 3;
  while (attempts < maxAttempts) {
    try {
      console.log(`Attempt ${attempts + 1} to fetch audio: ${url}`);
      const response = await fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Accept': 'audio/mpeg, audio/*, */*',
          'Cache-Control': 'no-cache'
        }
      });
      if (response.ok) {
        console.log("Fetch succeeded with standard approach");
        return await response.blob();
      }
      if (attempts === 0) {
        const cacheBustUrl = `${url}${url.includes('?') ? '&' : '?'}_cb=${Date.now()}`;
        console.log(`Trying with cache buster: ${cacheBustUrl}`);
        const cacheBustResponse = await fetch(cacheBustUrl, {
          method: 'GET',
          cache: 'no-cache',
          headers: { 'Accept': 'audio/mpeg, audio/*, */*' }
        });
        if (cacheBustResponse.ok) {
          console.log("Fetch succeeded with cache busting");
          return await cacheBustResponse.blob();
        }
      } else if (attempts === 1) {
        console.log("Trying with no-cors mode");
        const fallbackResponse = await fetch(url, {
          method: 'GET',
          cache: 'no-cache',
          mode: 'no-cors',
          headers: { 'Accept': 'audio/mpeg, audio/*, */*' }
        });
        if (fallbackResponse.type === 'opaque') {
          console.log("Got opaque response with no-cors, creating object URL");
          return await fallbackResponse.blob();
        }
      }
      attempts++;
      const delay = Math.pow(2, attempts) * 500;
      await new Promise(resolve => setTimeout(resolve, delay));
    } catch (err) {
      console.error(`Error fetching audio (attempt ${attempts + 1}):`, err);
      attempts++;
      const delay = Math.pow(2, attempts) * 500;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  console.error("All fetch attempts failed, returning null");
  return null;
};

// Format seconds into m:ss
const formatTime = (timeInSeconds) => {
  if (isNaN(timeInSeconds) || timeInSeconds < 0) return '0:00';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

// Clean up text by reducing excess whitespace/newlines
const cleanText = (text) => {
  if (!text) return '';
  return text.replace(/\n{3,}/g, '\n\n').replace(/\s{2,}/g, ' ').trim();
};

// Mark a step as completed (if not already)
// Mark a step as completed (if not already) and ensure uniqueness
const markStepCompleted = (step, completedSteps, setCompletedSteps) => {
  if (!completedSteps.includes(step)) {
    // Use Set to ensure uniqueness (no duplicates)
    const uniqueSteps = [...new Set([...completedSteps, step])];
    setCompletedSteps(uniqueSteps);
    
    // Store in session storage to prevent navigation loops
    try {
      sessionStorage.setItem('completedStep_' + step, 'true');
    } catch (e) {
      console.warn("Error storing completed step in session storage:", e);
    }
  }
};

// Return a CSS class for step transitions.
const getStepContentClass = (transitionDirection) => {
  if (transitionDirection === 'forward') return 'step-transition-forward';
  if (transitionDirection === 'backward') return 'step-transition-backward';
  return '';
};

// Determine if the user can advance based on current step and required fields.
const canAdvance = (currentStep, customPrompt, meditationScript, selectedVoice, selectedMusic, audioUrl) => {
  switch (currentStep) {
    case 1:
      return customPrompt.trim().length > 0;
    case 2:
      return meditationScript.trim().length > 0;
    case 3:
      return selectedVoice !== '';
    case 4:
      return selectedMusic && selectedMusic.trim().length > 0;
    case 5:
      return audioUrl !== '';
    default:
      return true;
  }
};

// Alias manageSSEConnection from our SSE manager.
const manageSSEConnection = sseManager.createConnection;

// Custom hook for mixing state.
const useMixingState = () => useRef({
  startTime: 0,
  abortController: null,
  progress: 0,
  completed: false,
  failed: false,
  url: null,
});

// When the music library loads, update state accordingly.
const handleMusicLibraryLoaded = (loadedLibrary, setMusicLibrary, setMusicSelectionComplete) => {
  console.log("Music library loaded with", loadedLibrary.length, "tracks");
  setMusicLibrary(loadedLibrary);
  setMusicSelectionComplete(true);
};

// Handle volume changes.
const handleVolumeChange = (type, value, setVolume) => {
  if (typeof type === 'object') {
    setVolume(type);
  } else if (typeof type === 'string') {
    setVolume(prev => ({ ...prev, [type]: parseFloat(value) }));
  }
};

// Handle audio errors by resetting to best available URL.
const handleAudioError = (error, setAudioUrl, getBestAudioUrl) => {
  console.error('Audio error occurred:', error);
  setAudioUrl(getBestAudioUrl());
};

/* ===================== Main Component ===================== */
const MeditationApp = () => {
  const navigate = useNavigate();
  const toastInstance = useToast();
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';

  // ---------------------------
  // State declarations
  // ---------------------------
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [error, setError] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [isBackgroundTTSProcessing, setIsBackgroundTTSProcessing] = useState(false);
  const [backgroundTTSProgress, setBackgroundTTSProgress] = useState(0);
  const [backgroundTTSResult, setBackgroundTTSResult] = useState(null);
  const [transitionDirection, setTransitionDirection] = useState(null);
  const [inputMethod, setInputMethod] = useState(null);
  const [customPrompt, setCustomPrompt] = useState('');
  const [meditationScript, setMeditationScript] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('onyx');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [globalPauseFactor, setGlobalPauseFactor] = useState(1.2);
  const [isStepTransitioning, setIsStepTransitioning] = useState(false);
  const [mixedAudioInfo, setMixedAudioInfo] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [ttsKey, setTTSKey] = useState('');
  const [musicLibrary, setMusicLibrary] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [volume, setVolume] = useState({ overall_volume: 1, tts_volume: 1, bg_volume: 0.2 });
  const [filters, setFilters] = useState({
    stereoPan: { enabled: false, value: 0.5, speed: 30, pattern: 'sine' },
    reverb: { enabled: false, wet: 0.5, dry: 1.0, preset: '' },
    eq: { enabled: false, low_cut: 80, mid_gain: 3.0, mid_freq: 1000, mid_Q: 1.0, high_cut: 12000, preset: '' }
  });
  const [isMixingAudio, setIsMixingAudio] = useState(false);
  const [isMusicLoading, setIsMusicLoading] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(false);
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);
  const [lastMixedAudio, setLastMixedAudio] = useState(null);
  const [musicSelectionComplete, setMusicSelectionComplete] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorRecoveryMode, setErrorRecoveryMode] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionMessage, setTransitionMessage] = useState('');
  const [transitionSubtitle, setTransitionSubtitle] = useState('');
  const useSpecialTransition = true;
  const audioElementRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Layout hook
  const { isSidebarOpen, setIsSidebarOpen, isNotificationsOpen, setIsNotificationsOpen, notifications, handleClearNotification, handleMarkAsRead, handleClearAllNotifications } = useLayout();
  const {
    isLoading: loaderIsLoading,
    message: loaderMessage,
    subtitle: loaderSubtitle,
    progress: loaderProgress,
    currentJobId,
    currentPhase,
    withSSELoading,
    withLoading,
    clearLoading,
    updateJobId: updateJobIdFromHook,
    handleServerUpdate
  } = useLoading();

  // ---------------------------
  // Refs for state and cleanup
  // ---------------------------
  const mounted = useRef(true);
  const audioUrlRef = useRef('');
  const blobUrlsRef = useRef([]);
  const lastErrorTimeRef = useRef(0);
  const mixingStateRef = useMixingState();

  // ---------------------------
  // Effect: Window Resize
  // ---------------------------

  useEffect(() => {
    console.log("MeditationApp rendering:", {
      currentStep,
      audioUrl,
      ttsKey
    });
  }, [currentStep, audioUrl, ttsKey]);

  // Example: move "Screen reader status" logging into an effect:
  useEffect(() => {
    console.log("Screen reader status - Step", currentStep, steps[currentStep - 1]?.name);
  }, [currentStep]);

  useEffect(() => {
    console.log("GlobalAudioState debug:", globalAudioState.getDebugState());
  }, []);

  useEffect(() => {
    console.log("SessionStorage - ttsKey:", sessionStorage.getItem("ttsKey"));
    console.log("SessionStorage - ttsAudioUrl:", sessionStorage.getItem("ttsAudioUrl"));
    console.log("SessionStorage - mixedAudioUrl:", sessionStorage.getItem("mixedAudioUrl"));
    console.log("SessionStorage - currentAudioUrl:", sessionStorage.getItem("currentAudioUrl"));
    console.log("SessionStorage - stableTransitionUrl:", sessionStorage.getItem("stableTransitionUrl"));
    console.log("SessionStorage - urlPriority:", sessionStorage.getItem("urlPriority"));
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const debugSessionState = () => {
    const state = {
      currentStep: sessionStorage.getItem('currentStep'),
      mixedAudioUrl: sessionStorage.getItem('mixedAudioUrl'),
      currentAudioUrl: sessionStorage.getItem('currentAudioUrl'),
      ttsUrl: sessionStorage.getItem('ttsAudioUrl'),
      urlPriority: sessionStorage.getItem('urlPriority'),
      urlLocked: sessionStorage.getItem('urlLocked'),
      mixingCompleted: sessionStorage.getItem('mixingCompleted'),
      completedSteps: completedSteps,
      windowMixedUrl: window.__mixedAudioUrl,
      windowCurrentUrl: window.__currentAudioUrl,
      globalStateUrl: window.globalAudioState?.state?.currentAudioUrl
    };
    
    console.log("DEBUG STATE:", state);
    return state;
  };
  
  // Call this in useEffect or when needed
  useEffect(() => {
    // Debug state when step changes
    debugSessionState();
  }, [currentStep]);
  

  // ---------------------------
  // Effect: Enforce URL Priority
  // ---------------------------
  useEffect(() => {
    const enforceMixedAudioPriority = () => {
      const mixedAudioUrl = sessionStorage.getItem('mixedAudioUrl');
      const mixingCompleted = sessionStorage.getItem('mixingCompleted') === 'true';
      const urlPriority = sessionStorage.getItem('urlPriority');
      if (mixingCompleted && urlPriority === 'mixed' && mixedAudioUrl &&
          mixedAudioUrl !== audioUrl && mixedAudioUrl.startsWith('http')) {
        console.log("Priority enforcement: Restoring mixed audio URL");
        setAudioUrl(mixedAudioUrl);
      }
    };
    enforceMixedAudioPriority();
    const priorityInterval = setInterval(enforceMixedAudioPriority, 1000);
    return () => clearInterval(priorityInterval);
  }, [audioUrl]);

  // ---------------------------
  // Effect: Diagnose URL Conflicts
  // ---------------------------
  const diagnoseUrlConflicts = useCallback(() => {
    console.log("URL Diagnostic:", {
      audioUrl,
      audioUrlRef: audioUrlRef.current,
      ttsKey,
      sessionMixedUrl: sessionStorage.getItem('mixedAudioUrl'),
      sessionCurrentUrl: sessionStorage.getItem('currentAudioUrl'),
      sessionaudioUrl: sessionStorage.getItem('stableTransitionUrl'),
      globalStateCurrentUrl: globalAudioState.state.currentAudioUrl,
      globalStateMixedUrl: globalAudioState.state.mixedAudioUrl,
      globalStatePriority: globalAudioState.state.urlPriority,
      windowMixedUrl: window.__mixedAudioUrl,
      windowCurrentUrl: window.__currentAudioUrl
    });
  }, [audioUrl, ttsKey]);

  useEffect(() => {
    if (audioUrl) {
      diagnoseUrlConflicts();
    }
  }, [audioUrl, diagnoseUrlConflicts]);

  // ---------------------------
  // Effect: Listen for Global Audio URL Updates
  // ---------------------------
  useEffect(() => {
    const handleAudioUrlUpdate = (event) => {
      if (event.detail && event.detail.url && mounted.current) {
        const newUrl = event.detail.url;
        console.log("Received global audio URL update:", newUrl);
        if (newUrl !== audioUrl) {
          setAudioUrl(newUrl);
        }
      }
    };
    window.addEventListener('audio-url-updated', handleAudioUrlUpdate);
    return () => window.removeEventListener('audio-url-updated', handleAudioUrlUpdate);
  }, [audioUrl]);

  // ---------------------------
  // Effect: Sync Mixing State
  // ---------------------------
  const syncMixingState = useCallback(() => {
    const isMixingInProgress = 
      window.globalAudioState?.state?.mixingInProgress === true ||
      sessionStorage.getItem('mixingInProgress') === 'true' ||
      window.__mixingInProgress === true;
    if (isMixingInProgress) {
      setIsMixingAudio(true);
      const mixingStartTime =
        window.globalAudioState?.state?.mixingStartTime ||
        parseInt(sessionStorage.getItem('mixingStartTime') || '0', 10) ||
        window.__mixingStartTime ||
        0;
      if (mixingStartTime > 0) {
        const mixingDuration = Date.now() - mixingStartTime;
        if (mixingDuration > 60000) {
          console.warn("Detected stuck mixing state, forcing completion");
          window.__mixingCompleted = true;
          window.__mixingFailed = true;
          window.__mixingInProgress = false;
          try {
            sessionStorage.setItem('mixingCompleted', 'true');
            sessionStorage.setItem('mixingFailed', 'true');
            sessionStorage.setItem('mixingInProgress', 'false');
          } catch (e) {
            console.warn("Error updating session storage:", e);
          }
          if (window.globalAudioState) {
            window.globalAudioState.state.mixingCompleted = true;
            window.globalAudioState.state.mixingFailed = true;
            window.globalAudioState.state.mixingInProgress = false;
            window.globalAudioState.saveToSessionStorage();
          }
          setIsMixingAudio(false);
        }
      }
    }
  }, [setIsMixingAudio]);

  useEffect(() => {
    syncMixingState();
    const checkInterval = setInterval(syncMixingState, 10000);
    return () => clearInterval(checkInterval);
  }, [syncMixingState]);

  // ---------------------------
  // Effect: Restore Global Audio State on Mount
  // ---------------------------
  useEffect(() => {
    mounted.current = true;
    if (globalAudioState.state.initialized) {
      if (globalAudioState.state.ttsKey && !ttsKey) {
        setTTSKey(globalAudioState.state.ttsKey);
      }
      if (globalAudioState.state.ttsProcessing && !isBackgroundTTSProcessing) {
        setIsBackgroundTTSProcessing(true);
        setBackgroundTTSProgress(globalAudioState.state.ttsProgress || 0);
      }
      const bestUrl = globalAudioState.getBestAudioUrl();
      if (bestUrl && !audioUrl) {
        setAudioUrl(bestUrl);
      }
    }
    return () => {
      mounted.current = false;
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.warn("Error revoking blob URL:", e);
        }
      });
    };
  }, [ttsKey, isBackgroundTTSProcessing, audioUrl]);

  // ---------------------------
  // Effect: TTS Safety Timeout
  // ---------------------------
  useEffect(() => {
    if (isBackgroundTTSProcessing) {
      const safetyTimeout = setTimeout(() => {
        if (mounted.current && isBackgroundTTSProcessing) {
          console.log("TTS safety timeout triggered");
          setBackgroundTTSProgress(100);
          setIsBackgroundTTSProcessing(false);
          globalAudioState.completeTTSProcessing();
          if (!ttsKey && currentStep >= 4) {
            const fallbackTtsKey = `fallback_voice_${Date.now()}.mp3`;
            setTTSKey(fallbackTtsKey);
            globalAudioState.updateTTSKey(fallbackTtsKey);
            if (!audioUrl) {
              const fallbackUrl = getTtsUrl(fallbackTtsKey, baseUrl);
              setAudioUrl(fallbackUrl);
              globalAudioState.state.ttsUrl = fallbackUrl;
              globalAudioState.state.currentAudioUrl = fallbackUrl;
              globalAudioState.saveToSessionStorage();
            }
            toastInstance.info("Using fallback voice due to timeout", { toastId: 'tts-fallback', autoClose: 5000 });
          }
        }
      }, 60000);
      return () => clearTimeout(safetyTimeout);
    }
  }, [isBackgroundTTSProcessing, ttsKey, audioUrl, baseUrl, currentStep, toastInstance]);

  // ---------------------------
  // Effect: Preload TTS Audio on Step 5
  // ---------------------------
  useEffect(() => {
    if (currentStep === 5 && ttsKey && !errorRecoveryMode) {
      const preloadAudio = new Audio();
      preloadAudio.src = getTtsUrl(ttsKey, baseUrl);
      preloadAudio.preload = 'auto';
      preloadAudio.load();
      window.__preloadAudio = preloadAudio;
    }
  }, [currentStep, ttsKey, baseUrl, errorRecoveryMode]);

  // ---------------------------
  // Effect: Notify When TTS Completes
  // ---------------------------
  useEffect(() => {
    if (ttsKey && isBackgroundTTSProcessing && backgroundTTSProgress >= 100) {
      setIsBackgroundTTSProcessing(false);
      if (currentStep === 4) {
        toastInstance.success("Voice generation completed successfully!", { toastId: 'voice-complete-music-step', autoClose: 3000 });
      } else if (currentStep === 5) {
        toastInstance.success("Voice generation complete! Ready for mixing.", { toastId: 'voice-complete-audio-step', autoClose: 5000 });
      }
      globalAudioState.completeTTSProcessing();
    }
  }, [ttsKey, isBackgroundTTSProcessing, backgroundTTSProgress, currentStep, toastInstance]);

  // ---------------------------
  // Effect: Inform User if TTS is in Progress
  // ---------------------------
  useEffect(() => {
    if (isBackgroundTTSProcessing) {
      if (currentStep === 5 && backgroundTTSProgress < 95) {
        toastInstance.info("Voice generation in progress; mixing will update when ready.", { toastId: 'tts-in-progress-audio-step', autoClose: 5000 });
      } else if (currentStep === 4 && completedSteps.includes(4)) {
        toastInstance.info("Voice generation continues in the background.", { toastId: 'returned-to-music-with-tts-in-progress', autoClose: 5000 });
      }
    }
  }, [currentStep, isBackgroundTTSProcessing, backgroundTTSProgress, completedSteps, toastInstance]);

  // ---------------------------
  // Effect: Step 6 Transition Animation
  // ---------------------------
  useEffect(() => {
    if (currentStep === 6) {
      setIsStepTransitioning(true);
      const timer = setTimeout(() => setIsStepTransitioning(false), 600);
      return () => clearTimeout(timer);
    }
  }, [currentStep]);

  // ---------------------------
  // Effect: Log Step Changes (for debugging)
  // ---------------------------
  useEffect(() => {
    console.log(`Step ${currentStep} changed. Completed steps:`, completedSteps);
    console.log("Current ttsKey:", ttsKey, "Audio URL:", audioUrl);
  }, [currentStep, completedSteps, ttsKey, audioUrl]);

  // ---------------------------
  // Steps array
  // ---------------------------
  const steps = [
    { id: 1, name: "Create", completed: completedSteps.includes(1) },
    { id: 2, name: "Script", completed: completedSteps.includes(2) },
    { id: 3, name: "Voice", completed: completedSteps.includes(3) },
    { id: 4, name: "Music", completed: completedSteps.includes(4) },
    { id: 5, name: "Audio", completed: completedSteps.includes(5) },
    { id: 6, name: "Listen", completed: completedSteps.includes(6) }
  ];

  // ---------------------------
  // Handler Functions
  // ---------------------------
  const handleGenerateScript = useCallback(async (prompt) => {
    if (!prompt || !mounted.current) return null;
    setIsScriptLoading(true);
    const toastId = toastInstance.loading("Creating your meditation script...", { toastId: 'script-generation' });
    try {
      const result = await withSSELoading(async (updateProgress) => {
        updateProgress(10, 'Preparing your meditation journey...');
        await new Promise((resolve) => setTimeout(resolve, 300));
        updateProgress(25, 'Creating your personalized guidance...');
        toastInstance.update(toastId, { render: 'Creating your personalized guidance...', type: "loading" });
        const response = await fetch(`${baseUrl}/generate-ssml`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt,
            selected_options: selectedOptions,
            selected_voice: selectedVoice,
            globalPauseFactor
          })
        });
        updateProgress(50, 'Crafting mindful language for you...');
        toastInstance.update(toastId, { render: 'Crafting mindful language for you...', type: "loading" });
        if (!response.ok) {
          const errorText = await response.text();
          toastInstance.update(toastId, { render: `Script generation failed: ${response.status}`, type: "error", autoClose: 5000 });
          throw new Error(`Server error: ${response.status} - ${errorText}`);
        }
        const data = await response.json();
        updateProgress(75, 'Refining your meditation experience...');
        toastInstance.update(toastId, { render: 'Refining your meditation experience...', type: "loading" });
        if (data.status === 'success') {
          const newJobId = data.data?.job_id || data.job_id;
          if (newJobId) {
            console.log(`Setting job ID for script generation: ${newJobId}`);
            updateJobIdFromHook(newJobId);
            manageSSEConnection(newJobId);
          }
          const rawScript = data.data?.raw_script || data.raw_script;
          const cleanedScript = cleanText(rawScript);
          if (mounted.current) {
            setMeditationScript(cleanedScript);
            setIsScriptGenerated(true);
          }
          updateProgress(95, 'Finalizing your meditation script...');
          toastInstance.update(toastId, { render: 'Finalizing your meditation script...', type: "loading" });
          return cleanedScript;
        } else {
          toastInstance.update(toastId, { render: data.message || 'Failed to generate script', type: "error", autoClose: 5000 });
          throw new Error(data.message || 'Failed to generate script');
        }
      });
      toastInstance.update(toastId, { render: 'Script generated successfully!', type: "success", autoClose: 3000 });
      return result;
    } catch (err) {
      console.error('Script generation failed:', err);
      if (mounted.current) {
        if (!toastInstance.isActive(toastId)) {
          toastInstance.error(`Script generation failed: ${err.message}`, { toastId: 'script-generation', autoClose: 5000 });
        } else {
          toastInstance.update(toastId, { render: `Script generation failed: ${err.message}`, type: "error", autoClose: 5000 });
        }
      }
      return null;
    } finally {
      if (mounted.current) setIsScriptLoading(false);
    }
  }, [baseUrl, selectedOptions, selectedVoice, globalPauseFactor, cleanText, withSSELoading, updateJobIdFromHook, toastInstance]);

  const handleCreateTTS = useCallback(async () => {
    if (!meditationScript.trim()) {
      toastInstance.error('Meditation script cannot be empty');
      return;
    }
    console.log("Starting voice synthesis process");
    setIsBackgroundTTSProcessing(true);
    setBackgroundTTSProgress(5);
    globalAudioState.startTTSProcessing();
    updateJobIdFromHook(null);
    markStepCompleted(3, completedSteps, setCompletedSteps);
    setCurrentStep(4);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toastInstance.info("Voice is being generated in the background while you select music", { toastId: 'background-tts', autoClose: 5000 });
    
    const progressTimer = setInterval(() => {
      setBackgroundTTSProgress(prev => {
        const newProgress = Math.min(prev + Math.random() * 2, 85);
        
        // Properly update GlobalAudioState if available
        if (typeof window !== 'undefined' && window.globalAudioState) {
          try {
            window.globalAudioState.updateTTSProgress(newProgress);
          } catch (e) {
            console.warn("Error updating global TTS progress:", e);
          }
        }
        
        // Return new progress state for local state update
        return newProgress;
      });
    }, 1000);
    
    try {
      const result = await audioService.createTTS({
        text: meditationScript,
        voice: selectedVoice,
        speaking_rate: 1.0
      });
      
      clearInterval(progressTimer);
      
      if (result) {
        if (result.placeholder || result.silent) {
          console.log("TTS returned a placeholder or silent audio:", result);
          let ttsUrl, ttsKey;
          if (result.localBlob && result.filePath && result.filePath.startsWith('blob:')) {
            ttsUrl = result.filePath;
            ttsKey = result.filename || `silent_audio_${Date.now()}.mp3`;
          } else {
            const silentAudioUrl = createSilentAudioBlob();
            if (silentAudioUrl) {
              ttsUrl = silentAudioUrl;
              ttsKey = result.filename || `silent_audio_${Date.now()}.mp3`;
            } else {
              ttsUrl = result.filePath;
              ttsKey = result.filename;
            }
          }
          setTTSKey(ttsKey);
          setAudioUrl(ttsUrl);
          globalAudioState.updateTTSKey(ttsKey, ttsUrl);
          globalAudioState.completeTTSProcessing();
          toastInstance.warning('Voice generation used a silent placeholder. Your meditation will still work.', { autoClose: 5000 });
          setBackgroundTTSProgress(100);
          setIsBackgroundTTSProcessing(false);
          return { success: true, silent: true };
        }
        
        let cleanFilename = result.filename;
        if (cleanFilename.includes('/')) {
          cleanFilename = cleanFilename.split('/').pop();
        }
        if (cleanFilename.startsWith("TTS/")) {
          cleanFilename = cleanFilename.substring(4);
        }
        
        try {
          const verifyResponse = await fetch(`${baseUrl}/verify-tts/${cleanFilename}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            cache: 'no-cache'
          });
          
          if (verifyResponse.ok) {
            const verifyData = await verifyResponse.json();
            const fileExists = verifyData && verifyData.exists;
            if (fileExists) {
              const ttsUrl = verifyData.presigned_url || result.filePath || getTtsUrl(cleanFilename, baseUrl);
              setTTSKey(cleanFilename);
              setAudioUrl(ttsUrl);
              globalAudioState.updateTTSKey(cleanFilename, ttsUrl);
              globalAudioState.completeTTSProcessing();
              if (currentStep === 4) {
                toastInstance.success("Voice generation completed successfully!", { toastId: 'voice-complete-music-step', autoClose: 3000 });
              }
              setBackgroundTTSProgress(100);
              setIsBackgroundTTSProcessing(false);
              return { success: true };
            } else {
              console.warn("TTS verification says file doesn't exist");
            }
          } else {
            console.warn("TTS verification request failed");
          }
        } catch (verifyError) {
          console.error("Error verifying TTS file:", verifyError);
        }
        
        try {
          const ttsUrl = result.filePath || getTtsUrl(cleanFilename, baseUrl);
          setTTSKey(cleanFilename);
          setAudioUrl(ttsUrl);
          globalAudioState.updateTTSKey(cleanFilename, ttsUrl);
          globalAudioState.completeTTSProcessing();
          if (currentStep === 4) {
            toastInstance.success("Voice generation completed!", { toastId: 'voice-complete-music-step', autoClose: 3000 });
          }
          setBackgroundTTSProgress(100);
          setIsBackgroundTTSProcessing(false);
          return { success: true };
        } catch (urlError) {
          console.error("Error setting direct TTS URL:", urlError);
        }
      }
      
      const silentAudioUrl = createSilentAudioBlob();
      if (silentAudioUrl) {
        const silentFilename = `silent_audio_${Date.now()}.mp3`;
        setTTSKey(silentFilename);
        setAudioUrl(silentAudioUrl);
        globalAudioState.updateTTSKey(silentFilename, silentAudioUrl);
        globalAudioState.completeTTSProcessing();
        toastInstance.warning('Voice generation encountered an issue. Using silent audio for meditation.', { autoClose: 5000 });
      } else {
        toastInstance.error("Failed to generate voice audio.");
      }
      
      setBackgroundTTSProgress(100);
      setIsBackgroundTTSProcessing(false);
      return { success: false, silent: true };
    } catch (err) {
      clearInterval(progressTimer);
      console.error(`Error generating TTS: ${err.message || 'Unknown error'}`);
      const silentAudioUrl = createSilentAudioBlob();
      if (silentAudioUrl) {
        const silentFilename = `silent_audio_${Date.now()}.mp3`;
        setTTSKey(silentFilename);
        setAudioUrl(silentAudioUrl);
        globalAudioState.updateTTSKey(silentFilename, silentAudioUrl);
        globalAudioState.completeTTSProcessing();
        toastInstance.warning('Voice generation failed. Using silent audio for meditation.', { autoClose: 5000 });
      } else {
        toastInstance.error("Failed to generate voice audio.");
        globalAudioState.failAudioMixing('tts-failed');
      }
      setBackgroundTTSProgress(100);
      setIsBackgroundTTSProcessing(false);
      return { success: false, error: err.message, silent: true };
    }
  }, [meditationScript, selectedVoice, baseUrl, toastInstance, completedSteps, currentStep, updateJobIdFromHook]);

// Replace the handleAudioMixingHandler function with this version
const handleAudioMixingHandler = async (providedVolume, providedFilters) => {
  console.log("Starting audio mixing process");
  
  // Show a loading toast
  const toastId = toastInstance.loading('Preparing your meditation audio...');
  
  // Track mixing state
  mixingStateRef.current = {
    startTime: Date.now(),
    abortController: new AbortController(),
    progress: 0,
    completed: false,
    failed: false,
    url: null
  };
  
  // Update global state to indicate mixing in progress
  globalAudioState.startAudioMixing();
  window.__mixingInProgress = true;
  window.__mixingStartTime = Date.now();
  window.__mixingCompleted = false;
  window.__mixingFailed = false;
  
  try {
    sessionStorage.setItem('mixingInProgress', 'true');
    sessionStorage.setItem('mixingStartTime', Date.now().toString());
    sessionStorage.setItem('mixingCompleted', 'false');
    sessionStorage.setItem('mixingFailed', 'false');
  } catch (e) {
    console.warn("Error setting mixing state in session storage:", e);
  }
  
  // Use provided values or fallback to component state
  const volToUse = providedVolume || volume;
  const filtersToUse = providedFilters || filters;
  
  // Transform filters for the backend format
  const transformedFilters = {};
  
  // Handle stereoPan -> stereo_pan conversion
  if (filtersToUse.stereoPan?.enabled) {
    transformedFilters.stereo_pan = {
      enabled: true,
      ...filtersToUse.stereoPan,
      preset_key: filtersToUse.stereoPan.preset?.toLowerCase().replace(/\s+/g, '_')
    };
  }
  
  // Handle reverb conversion
  if (filtersToUse.reverb?.enabled) {
    transformedFilters.reverb = {
      enabled: true,
      ...filtersToUse.reverb,
      preset_key: filtersToUse.reverb.preset?.toLowerCase().replace(/\s+/g, '_')
    };
  }
  
  // Handle eq/equalizer conversion
  if (filtersToUse.eq?.enabled) {
    transformedFilters.eq = {
      enabled: true,
      ...filtersToUse.eq,
      preset_key: filtersToUse.eq.preset?.toLowerCase().replace(/\s+/g, '_')
    };
  } else if (filtersToUse.equalizer?.enabled) {
    transformedFilters.eq = {
      enabled: true,
      ...filtersToUse.equalizer,
      preset_key: filtersToUse.equalizer.preset?.toLowerCase().replace(/\s+/g, '_')
    };
  }
  
  // Store fallback URL for error cases
  let fallbackAudioUrl = '';
  if (ttsKey) {
    fallbackAudioUrl = `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, '')}`;
    try {
      sessionStorage.setItem('fallbackAudioUrl', fallbackAudioUrl);
      sessionStorage.setItem('currentAudioUrl', fallbackAudioUrl);
    } catch (e) {
      console.warn("Error storing fallback URL in session storage:", e);
    }
    globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
  }
  
  // Prepare mixing payload with transformed filters
  const mixPayload = {
    tts_audio: ttsKey ? `TTS/${ttsKey.replace(/^TTS\//, '')}` : '',
    bg_audio: selectedMusic ? `Background_Music/${selectedMusic}.mp3` : '',
    bg_volume: volToUse.bg_volume,
    tts_volume: volToUse.tts_volume,
    overall_volume: volToUse.overall_volume,
    filters: transformedFilters
  };
  
  console.log("Sending to /get-mixed-audio:", mixPayload);
  
  // Set up progress tracking
  let progress = 0;
  const progressInterval = setInterval(() => {
    progress = Math.min(95, progress + Math.random() * 2);
    globalAudioState.updateMixingProgress(progress);
    window.__mixingProgress = progress;
    window.dispatchEvent(new CustomEvent('mixing-progress', { 
      detail: { progress, timestamp: Date.now() } 
    }));
    try {
      sessionStorage.setItem('mixingProgress', progress.toString());
    } catch (e) {
      console.warn("Error storing mixing progress:", e);
    }
  }, 1000);
  
  window.__progressTimer = progressInterval;
  
  try {
    // Make the mixing request with better error handling
    let mixAttempt = 0;
    let mixResult = null;
    
    while (mixAttempt < 2 && !mixResult) {
      try {
        if (mixAttempt > 0) {
          await new Promise(resolve => setTimeout(resolve, 1000));
          console.log(`Retrying audio mixing (attempt ${mixAttempt + 1})...`);
        }
        
        const response = await fetch(`${baseUrl}/get-mixed-audio`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(mixPayload),
          signal: mixingStateRef.current.abortController.signal,
          timeout: 30000
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Server error: ${response.status} - ${errorText}`);
        }
        
        mixResult = await response.json();
        console.log("Mix result received:", JSON.stringify(mixResult, null, 2));
        
      } catch (fetchError) {
        console.warn(`Mix attempt ${mixAttempt + 1} failed:`, fetchError);
        mixAttempt++;
        
        if (mixAttempt >= 2) {
          throw fetchError;
        }
      }
    }
    
    // Clear the progress interval
    clearInterval(progressInterval);
    window.__progressTimer = null;
    
    // Process the successful mix result
    if (mixResult && mixResult.status === 'success') {
      // Extract the URL using extractMixedAudioUrl
      let extractResult;
      if (typeof globalAudioState.extractMixedAudioUrl === 'function') {
        extractResult = globalAudioState.extractMixedAudioUrl(mixResult);
        if (extractResult.success && extractResult.url) {
          console.log("Successfully extracted mixed audio URL:", extractResult.url);
          mixResult = { ...mixResult, extracted_url: extractResult.url };
        }
      }
      
      // Determine the final URL to use
      let mixedUrl = null;
      
      // Try different paths to get the URL
      if (mixResult.extracted_url && isValidUrl(mixResult.extracted_url)) {
        mixedUrl = mixResult.extracted_url;
      } else if (mixResult.data?.file_path && isValidUrl(mixResult.data.file_path)) {
        mixedUrl = mixResult.data.file_path;
      } else if (mixResult.file_path && isValidUrl(mixResult.file_path)) {
        mixedUrl = mixResult.file_path;
      } else {
        // Handle API inconsistency
        // Sometimes the API returns a relative path without the domain
        if (mixResult.data?.file_path && typeof mixResult.data.file_path === 'string') {
          if (mixResult.data.file_path.startsWith('/')) {
            mixedUrl = baseUrl + mixResult.data.file_path;
          } else {
            mixedUrl = baseUrl + '/' + mixResult.data.file_path;
          }
        }
      }
      
      if (!mixedUrl || !isValidUrl(mixedUrl)) {
        console.warn("No valid URL found in successful response:", mixResult);
        throw new Error("No valid URL found in mix result");
      }
      
      console.log("Final mixed audio URL:", mixedUrl);
      
      // Verify the file exists
      try {
        console.log("Verifying file exists by fetching a small portion...");
        const headResponse = await fetch(mixedUrl, {
          method: 'GET',
          headers: {
            'Range': 'bytes=0-1000',
            'Accept': 'audio/mpeg, audio/*'
          }
        });
        
        console.log(`File existence check: ${headResponse.ok ? 'OK' : 'Failed'} (status: ${headResponse.status})`);
        
        if (!headResponse.ok) {
          console.warn("File verification failed, but continuing anyway");
        }
      } catch (checkErr) {
        console.warn("Error checking file (continuing anyway):", checkErr);
      }
      
      // Preload the audio
      try {
        const preloadAudio = new Audio();
        preloadAudio.crossOrigin = "anonymous";
        preloadAudio.onerror = (err) => {
          console.warn("Audio preload error:", preloadAudio.error?.message || "Unknown error");
        };
        preloadAudio.onloadedmetadata = () => {
          console.log("Audio preload successful - metadata loaded, duration:", preloadAudio.duration);
        };
        preloadAudio.src = mixedUrl;
        preloadAudio.preload = 'metadata';
        preloadAudio.volume = 0;
        preloadAudio.load();
        window.__preloadAudio = preloadAudio;
      } catch (preloadErr) {
        console.warn("Audio preload setup error:", preloadErr);
      }
      
      // Store URL in global state with proper priority
      if (typeof globalAudioState.lockUrl === 'function') {
        globalAudioState.lockUrl(mixedUrl, 'mixed');
        
        // Set a timeout to unlock after 2 seconds
        setTimeout(() => {
          if (typeof globalAudioState.unlockUrl === 'function') {
            globalAudioState.unlockUrl();
          }
        }, 2000);
      } else {
        // Fallback to updateAllAudioUrls if lockUrl not available
        globalAudioState.updateAllAudioUrls(mixedUrl);
        
        // Set lock in session storage manually
        try {
          sessionStorage.setItem('urlLocked', 'true');
          sessionStorage.setItem('urlLockTime', Date.now().toString());
          sessionStorage.setItem('urlPriority', 'mixed');
          
          // Auto unlock after 2 seconds
          setTimeout(() => {
            try {
              sessionStorage.setItem('urlLocked', 'false');
            } catch (e) {}
          }, 2000);
        } catch (e) {
          console.warn("Error setting URL lock in session storage:", e);
        }
      }
      
      // Store mixed audio info for later use
      setMixedAudioInfo({
        url: mixedUrl,
        duration: window.__preloadAudio?.duration || 0,
        timestamp: Date.now()
      });
      
      // Update toast and return success
      toastInstance.update(toastId, { 
        render: 'Your meditation audio is ready!', 
        type: "success", 
        autoClose: 3000 
      });
      
      mixingStateRef.current.completed = true;
      mixingStateRef.current.failed = false;
      mixingStateRef.current.url = mixedUrl;
      
      return {
        status: "success",
        data: {
          file_path: mixedUrl,
          filename: mixResult.data?.filename || "mixed_audio.mp3"
        },
        originalResult: mixResult 
      };
        } else {
      console.warn("Mix result invalid:", mixResult);
      throw new Error(mixResult?.message || "Invalid response from server");
    }
  } catch (error) {
    console.error("Audio mixing error:", error);
    
    // Clean up progress interval
    clearInterval(progressInterval);
    window.__progressTimer = null;
    
    // Mark mixing as failed
    globalAudioState.failAudioMixing('server-error');
    window.__mixingInProgress = false;
    window.__mixingFailed = true;
    window.__mixingFailedTime = Date.now();
    window.__mixingFailedReason = error.message;
    
    try {
      sessionStorage.setItem('mixingInProgress', 'false');
      sessionStorage.setItem('mixingFailed', 'true');
      sessionStorage.setItem('mixingFailedTime', Date.now().toString());
      sessionStorage.setItem('mixingFailedReason', error.message);
      sessionStorage.setItem('urlPriority', 'tts');
    } catch (e) {
      console.warn("Error updating session storage:", e);
    }
    
    // Notify other components
    window.dispatchEvent(new CustomEvent('mixing-failed', { 
      detail: { 
        error: error.message, 
        timestamp: Date.now(),
        fallbackUrl: fallbackAudioUrl
      } 
    }));
    
    // Try fallback URL
    if (fallbackAudioUrl) {
      console.log("Using fallback audio URL:", fallbackAudioUrl);
      
      // Update toast and global state
      toastInstance.update(toastId, { 
        render: 'Audio processing encountered an issue. Using voice audio only.', 
        type: "warning", 
        autoClose: 5000 
      });
      
      globalAudioState.updateAllAudioUrls(fallbackAudioUrl);
      
      return fallbackAudioUrl;
    } else {
      console.error("No fallback URL available");
      
      // Try silent audio as last resort
      try {
        const silentAudioUrl = globalAudioState.getSilentAudioUrl();
        console.log("Using silent audio as last resort:", silentAudioUrl);
        
        toastInstance.update(toastId, { 
          render: 'Error creating meditation audio. Using silent fallback.', 
          type: "error", 
          autoClose: 5000 
        });
        
        globalAudioState.updateAllAudioUrls(silentAudioUrl);
        return silentAudioUrl;
      } catch (silentError) {
        console.error("Even silent audio fallback failed:", silentError);
        
        toastInstance.update(toastId, { 
          render: 'Error creating meditation audio. Please try again.', 
          type: "error", 
          autoClose: 5000 
        });
        
        throw error;
      }
    }
  }
};

  const handleStepTransitionHandler = async (fromStep, toStep) => {
    console.log(`Transitioning from step ${fromStep} to step ${toStep}`);
    
    // PREVENT BACKWARDS NAVIGATION FROM LISTEN TO AUDIO
    if (fromStep > toStep && currentStep === 6) {
      console.warn("Prevented backwards navigation from ListenStep");
      return;
    }
    
    if (fromStep === 5 && toStep === 6) {
      // Mark step as completed FIRST before transition effects
      markStepCompleted(fromStep, completedSteps, setCompletedSteps);
      
      // Set transition direction and show loading UI
      setTransitionDirection('forward');
      setIsTransitioning(true);
      setTransitionMessage("Preparing your meditation...");
      setTransitionSubtitle("Setting up your audio");
      
      // CRITICAL FIX: Explicitly set fullscreen to false
      window.__fullscreenAtStart = false;
      try {
        sessionStorage.setItem('fullscreenAtStart', 'false');
      } catch (e) {
        console.warn("Error storing fullscreen flag:", e);
      }
      
      // Store current step in session storage to prevent navigation loops
      try {
        sessionStorage.setItem('currentStep', toStep.toString());
        sessionStorage.setItem('previousStep', fromStep.toString());
        sessionStorage.setItem('isTransitioningToListen', 'true');
        sessionStorage.setItem('delayWaveSurferInit', 'true');
        sessionStorage.setItem('waveSurferInitDelay', '2000');
      } catch (e) {
        console.warn("Error storing step in session storage:", e);
      }
      
      // Setup audio transition element (will be used by ListenStep)
      const transitionAudio = new Audio();
      transitionAudio.crossOrigin = "anonymous";
      transitionAudio.preload = "auto";
      window.__transitionAudioElement = transitionAudio;
      
      // Determine the best audio URL to use with explicit priority
      let bestUrl = null;
      
      // Prioritize mixed audio over TTS
      if (mixedAudioInfo?.url && isValidUrl(mixedAudioInfo.url)) {
        bestUrl = mixedAudioInfo.url;
        console.log("Using mixed audio URL for transition:", bestUrl);
      } else if (sessionStorage.getItem('mixedAudioUrl')) {
        const sessionMixedUrl = sessionStorage.getItem('mixedAudioUrl');
        if (isValidUrl(sessionMixedUrl)) {
          bestUrl = sessionMixedUrl;
          console.log("Using session mixed URL for transition:", bestUrl);
        }
      } else if (window.__mixedAudioUrl && isValidUrl(window.__mixedAudioUrl)) {
        bestUrl = window.__mixedAudioUrl;
        console.log("Using window mixed URL for transition:", bestUrl);
      } else if (globalAudioState.state.mixedAudioUrl && isValidUrl(globalAudioState.state.mixedAudioUrl)) {
        bestUrl = globalAudioState.state.mixedAudioUrl;
        console.log("Using globalAudioState mixed URL for transition:", bestUrl);
      } else if (ttsKey) {
        // Fall back to TTS URL if no mixed URL is available
        bestUrl = `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, '')}`;
        console.log("Using TTS URL for transition:", bestUrl);
      } else {
        // Final fallback
        bestUrl = globalAudioState.getSilentAudioUrl();
        console.log("Using silent audio URL for transition (no valid URLs found)");
      }
      
      console.log("Final stable URL for transition:", bestUrl);
      
      // Set the URL in the transition audio element
      transitionAudio.src = bestUrl;
      transitionAudio.load();
      
      // IMPORTANT: Lock the URL in the global state with 'mixed' priority
      // This prevents other components from changing it during transition
      if (typeof globalAudioState.lockUrl === 'function') {
        globalAudioState.lockUrl(bestUrl, 'mixed');
      } else {
        // Fallback if lockUrl method not available
        globalAudioState.updateAllAudioUrls(bestUrl);
        try {
          sessionStorage.setItem('urlLocked', 'true');
          sessionStorage.setItem('urlLockTime', Date.now().toString());
          sessionStorage.setItem('urlPriority', 'mixed');
        } catch (e) {
          console.warn("Error setting URL lock in session storage:", e);
        }
      }
      
      // Set URL in component state
      setAudioUrl(bestUrl);
      
      // Wait briefly to ensure all URL updates have propagated
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Now change the step to 6
      setCurrentStep(toStep);
      
      // Set up cleanup of transition state with proper timing
      setTimeout(() => {
        setTransitionDirection(null);
        
        setTimeout(() => {
          if (mounted.current) {
            setIsTransitioning(false);
            
            // Unlock URL after transition is complete
            if (typeof globalAudioState.unlockUrl === 'function') {
              globalAudioState.unlockUrl();
            } else {
              try {
                sessionStorage.setItem('urlLocked', 'false');
              } catch (e) {
                console.warn("Error updating URL lock in session storage:", e);
              }
            }
          }
          
          // Clear transition flags after completion
          window.__transitioningToListen = false;
          window.__wavesurferTransitionInProgress = false;
          window.__delayWaveSurferInit = false;
          
          try {
            sessionStorage.setItem('isTransitioningToListen', 'false');
            sessionStorage.setItem('delayWaveSurferInit', 'false');
          } catch (e) {
            console.warn("Error clearing transition flags:", e);
          }
        }, 2000);
      }, 1000);
    } else {
      // For other step transitions
      markStepCompleted(fromStep, completedSteps, setCompletedSteps);
      
      // Store current step in session storage
      try {
        sessionStorage.setItem('currentStep', toStep.toString());
        sessionStorage.setItem('previousStep', fromStep.toString());
      } catch (e) {
        console.warn("Error storing step in session storage:", e);
      }
      
      setCurrentStep(toStep);
    }
  };

  // Complete implementation for handleAudioMixingFromListenStep
  const handleAudioMixingFromListenStep = useCallback(async (volumeOverride, filtersOverride) => {
    const toastId = toastInstance.loading('Applying your changes...');
    setIsMixingAudio(true);
    
    try {
      // Update global state to indicate mixing in progress
      globalAudioState.startAudioMixing();
      window.__mixingInProgress = true;
      window.__mixingStartTime = Date.now();
      
      try {
        sessionStorage.setItem('mixingInProgress', 'true');
        sessionStorage.setItem('mixingStartTime', Date.now().toString());
      } catch (e) {
        console.warn("Error setting mixing state in session storage:", e);
      }
      
      // Use provided values or fallback to component state
      const volumeToUse = volumeOverride || volume;
      const filtersToUse = filtersOverride || filters;
      
      // SIMPLE FIX: Transform filters to backend format
      const transformedFilters = {};
      
      // Handle stereoPan -> stereo_pan conversion
      if (filtersToUse.stereoPan?.enabled) {
        transformedFilters.stereo_pan = {
          enabled: true,
          ...filtersToUse.stereoPan,
          preset_key: filtersToUse.stereoPan.preset?.toLowerCase().replace(/\s+/g, '_')
        };
      }
      
      // Handle reverb conversion (keep same name but ensure properties)
      if (filtersToUse.reverb?.enabled) {
        transformedFilters.reverb = {
          enabled: true,
          ...filtersToUse.reverb,
          preset_key: filtersToUse.reverb.preset?.toLowerCase().replace(/\s+/g, '_')
        };
      }
      
      // Handle eq/equalizer conversion
      if (filtersToUse.eq?.enabled) {
        transformedFilters.eq = {
          enabled: true,
          ...filtersToUse.eq,
          preset_key: filtersToUse.eq.preset?.toLowerCase().replace(/\s+/g, '_')
        };
      } else if (filtersToUse.equalizer?.enabled) {
        transformedFilters.eq = {
          enabled: true,
          ...filtersToUse.equalizer,
          preset_key: filtersToUse.equalizer.preset?.toLowerCase().replace(/\s+/g, '_')
        };
      }
      
      // Store fallback URL
      let fallbackAudioUrl = '';
      if (ttsKey) {
        fallbackAudioUrl = `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, '')}`;
        try {
          sessionStorage.setItem('fallbackAudioUrl', fallbackAudioUrl);
        } catch (e) {
          console.warn("Error storing fallback URL:", e);
        }
      }
      
      // Prepare mixing payload with transformed filters
      const mixPayload = {
        tts_audio: ttsKey ? `TTS/${ttsKey.replace(/^TTS\//, '')}` : '',
        bg_audio: selectedMusic ? `Background_Music/${selectedMusic}.mp3` : '',
        bg_volume: volumeToUse.bg_volume,
        tts_volume: volumeToUse.tts_volume,
        overall_volume: volumeToUse.overall_volume,
        filters: transformedFilters // Use transformed filters here
      };
      
      console.log("Sending to /get-mixed-audio:", mixPayload);
      
      // Set up progress tracking
      let progress = 0;
      const progressInterval = setInterval(() => {
        progress = Math.min(95, progress + Math.random() * 2);
        globalAudioState.updateMixingProgress(progress);
        window.__mixingProgress = progress;
        try {
          sessionStorage.setItem('mixingProgress', progress.toString());
        } catch (e) {
          console.warn("Error storing mixing progress:", e);
        }
        
        // Dispatch event for progress
        window.dispatchEvent(new CustomEvent('mixing-progress', { 
          detail: { progress, timestamp: Date.now() } 
        }));
      }, 1000);
      
      // Send the mixing request
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(mixPayload),
        timeout: 30000
      });
      
      clearInterval(progressInterval);
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server error: ${response.status} - ${errorText}`);
      }
      
      const mixResult = await response.json();
      
      if (mixResult && mixResult.status === 'success' && mixResult.data && mixResult.data.file_path) {
        let mixedUrl = mixResult.data.file_path;
        console.log("Raw file_path received:", mixedUrl);
        
        const finalUrl = mixedUrl.startsWith('http')
          ? mixedUrl
          : `${baseUrl}${mixedUrl.startsWith('/') ? '' : '/'}${mixedUrl}`;
        
        console.log("Finalized mixed audio URL:", finalUrl);
        
        // Update global state
        globalAudioState.setCurrentAudioUrl(finalUrl);
        globalAudioState.completeAudioMixing(finalUrl);
        window.__mixingInProgress = false;
        window.__mixingCompleted = true;
        window.__mixedAudioUrl = finalUrl;
        
        // Update session storage
        try {
          sessionStorage.setItem('mixingInProgress', 'false');
          sessionStorage.setItem('mixingCompleted', 'true');
          sessionStorage.setItem('mixedAudioUrl', finalUrl);
          sessionStorage.setItem('currentAudioUrl', finalUrl);
          sessionStorage.setItem('urlPriority', 'mixed');
        } catch (e) {
          console.warn("Error updating session storage:", e);
        }
        
        // Dispatch event
        window.dispatchEvent(new CustomEvent('mixing-complete', { 
          detail: { 
            audioUrl: finalUrl,
            rawUrl: mixedUrl,
            timestamp: Date.now()
          } 
        }));
        
        toastInstance.update(toastId, { 
          render: 'Your changes have been applied!', 
          type: "success", 
          autoClose: 3000 
        });
        
        // Update component state
        setAudioUrl(finalUrl);
        
        return finalUrl;
      } else {
        throw new Error(mixResult?.message || "Invalid response from server");
      }
    } catch (error) {
      console.error("Audio mixing error:", error);
      
      // Update global state to indicate failure
      globalAudioState.failAudioMixing('server-error');
      window.__mixingInProgress = false;
      window.__mixingFailed = true;
      
      try {
        sessionStorage.setItem('mixingInProgress', 'false');
        sessionStorage.setItem('mixingFailed', 'true');
      } catch (e) {
        console.warn("Error updating session storage:", e);
      }
      
      // Use fallback audio if available
      if (ttsKey) {
        const fallbackUrl = getTtsUrl(ttsKey, baseUrl);
        console.log("Using fallback audio URL:", fallbackUrl);
        toastInstance.update(toastId, { 
          render: 'Audio processing encountered an issue. Using voice audio only.', 
          type: "warning", 
          autoClose: 5000 
        });
        
        // Update state and return fallback URL
        setAudioUrl(fallbackUrl);
        globalAudioState.setCurrentAudioUrl(fallbackUrl);
        
        return fallbackUrl;
      } else {
        toastInstance.update(toastId, { 
          render: 'Failed to apply changes. Please try again.', 
          type: "error", 
          autoClose: 5000 
        });
        
        throw error;
      }
    } finally {
      setIsMixingAudio(false);
    }
  }, [
    baseUrl, ttsKey, selectedMusic, volume, filters, 
    toastInstance, globalAudioState, setAudioUrl, setIsMixingAudio
  ]);

  const handleAudioErrorHandler = useCallback((error) => {
    console.error('Audio error occurred:', error);
    setAudioUrl(globalAudioState.getBestAudioUrl());
  }, []);

  const handleExportAudioHandler = useCallback(async () => {
    if (!audioUrlRef.current) {
      toastInstance.error('No audio available to export');
      return;
    }
    try {
      const audioBlob = await fetchWithFallback(audioUrlRef.current);
      if (!audioBlob) throw new Error("Failed to fetch audio for download");
      const a = document.createElement('a');
      const blobUrl = URL.createObjectURL(audioBlob);
      a.href = blobUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 200);
      toastInstance.success('Downloading your meditation audio');
    } catch (err) {
      console.warn("Error in blob download, falling back to direct URL:", err);
      const a = document.createElement('a');
      a.href = audioUrlRef.current;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 100);
      toastInstance.success('Downloading your meditation audio');
    }
  }, []);

  const handleResetSessionHandler = useCallback(() => {
    setCurrentStep(1);
    setCompletedSteps([]);
    setCustomPrompt('');
    setMeditationScript('');
    setSelectedVoice('onyx');
    setSelectedOptions([]);
    setAudioUrl('');
    setTTSKey('');
    setSelectedMusic('');
    setVolume({ overall_volume: 1, tts_volume: 1, bg_volume: 0.2 });
    setFilters({
      stereoPan: { enabled: false, value: 0.5, speed: 30, pattern: 'sine' },
      reverb: { enabled: false, wet: 0.5, dry: 1.0, preset: '' },
      eq: { enabled: false, low_cut: 80, mid_gain: 3.0, mid_freq: 1000, mid_Q: 1.0, high_cut: 12000, preset: '' }
    });
    setIsScriptGenerated(false);
    setLastMixedAudio(null);
    setIsBackgroundTTSProcessing(false);
    setBackgroundTTSProgress(0);
    setBackgroundTTSResult(null);
    setIsTransitioning(false);
    setTransitionMessage('');
    setTransitionSubtitle('');
    updateJobIdFromHook(null);
    clearLoading();
    globalAudioState.state.ttsKey = null;
    globalAudioState.state.ttsProgress = 0;
    globalAudioState.state.ttsProcessing = false;
    globalAudioState.state.mixedAudioUrl = null;
    globalAudioState.state.currentAudioUrl = null;
    globalAudioState.saveToSessionStorage();
    blobUrlsRef.current.forEach(url => {
      try { URL.revokeObjectURL(url); } catch (e) { console.warn("Error revoking blob URL:", e); }
    });
    blobUrlsRef.current = [];
  }, []);

  // ---------------------------
  // Render
  // ---------------------------
  return (
    <ThemeProvider>
      <div className="meditation-app-container">
        <div className="sr-only" aria-live="polite" role="status">
          {`Step ${currentStep} of 6: ${steps[currentStep - 1]?.name}`}
        </div>
        {(loaderIsLoading || isMixingAudio || isTransitioning) && (
          <LoadingOverlay
            message={isTransitioning ? (transitionMessage || "Preparing your meditation...") : loaderMessage}
            subtitle={isTransitioning ? (transitionSubtitle || "Finding your calm") : loaderSubtitle}
            progress={isTransitioning ? (loaderProgress < 20 ? 60 : loaderProgress) : loaderProgress}
            isVisible={true}
            isMobile={windowDimensions.width <= 768}
            jobId={currentJobId}
            currentPhase={currentPhase}
            baseUrl={baseUrl}
          />
        )}
        <ProgressBar steps={steps} currentStep={currentStep} />
        <div className="meditation-content">
          <div className={`step-content ${getStepContentClass(transitionDirection)}`}>
            {currentStep === 1 && (
              <CreateStep
                customPrompt={customPrompt}
                setCustomPrompt={setCustomPrompt}
                onGenerateScript={async (prompt) => {
                  try {
                    const script = await handleGenerateScript(prompt);
                    setMeditationScript(script);
                    markStepCompleted(1, completedSteps, setCompletedSteps);
                    setCurrentStep(2);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  } catch (err) {
                    setError(err.message);
                    toastInstance.error(err.message);
                  }
                }}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setInputMethod={setInputMethod}
                inputMethod={inputMethod}
                setError={setError}
                baseUrl={baseUrl}
                cleanText={cleanText}
                selectedVoice={selectedVoice}
                setMeditationScript={setMeditationScript}
                markStepCompleted={(step) => markStepCompleted(step, completedSteps, setCompletedSteps)}
                goToNextStep={() => setCurrentStep(2)}
                isLoading={loaderIsLoading}
                isScriptLoading={isScriptLoading}
                resetDecisionTree={() => { console.log("Resetting decision tree"); }}
                withSSELoading={withSSELoading}
                mounted={mounted}
                setIsScriptLoading={setIsScriptLoading}
                OPERATION_PHASES={OPERATION_PHASES}
              />
            )}
            {currentStep === 2 && (
              <ScriptStep
                meditationScript={meditationScript}
                setMeditationScript={setMeditationScript}
                onScriptReady={() => {
                  markStepCompleted(2, completedSteps, setCompletedSteps);
                  setCurrentStep(3);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                globalPauseFactor={globalPauseFactor}
                setGlobalPauseFactor={setGlobalPauseFactor}
              />
            )}
            {currentStep === 3 && (
              <VoiceStep
                selectedVoice={selectedVoice}
                setSelectedVoice={setSelectedVoice}
                onCreateTTS={handleCreateTTS}
              />
            )}
            {currentStep === 4 && (
              <MusicSelection
                musicLibrary={musicLibrary}
                selectedMusic={selectedMusic}
                setSelectedMusic={setSelectedMusic}
                isMusicLoading={isMusicLoading}
                markStepCompleted={(step) => markStepCompleted(step, completedSteps, setCompletedSteps)}
                goToNextStep={() => setCurrentStep(5)}
                isSessionCreated={true}
                baseUrl={baseUrl}
                isLoading={loaderIsLoading}
                isBackgroundTTSProcessing={isBackgroundTTSProcessing}
                backgroundTTSProgress={backgroundTTSProgress}
                ttsKey={ttsKey}
                setBackgroundTTSProgress={setBackgroundTTSProgress}
                setIsBackgroundTTSProcessing={setIsBackgroundTTSProcessing}
                completedSteps={completedSteps}
                onMusicLibraryLoaded={(loadedLibrary) =>
                  handleMusicLibraryLoaded(loadedLibrary, setMusicLibrary, setMusicSelectionComplete)
                }
              />
            )}
            {currentStep === 5 && (
              <AudioStep
                volume={volume}
                setVolume={(type, value) => handleVolumeChange(type, value, setVolume)}
                filters={filters}
                setFilters={setFilters}
                musicLibrary={musicLibrary}
                selectedMusic={selectedMusic}
                setSelectedMusic={setSelectedMusic}
                onApplyChanges={async () => {
                  try {
                    const mixedUrl = await handleAudioMixingHandler(null, null);
                    markStepCompleted(5, completedSteps, setCompletedSteps);
                    setCurrentStep(6);
                  } catch (err) {
                    toastInstance.error(err.message);
                  }
                }}
                ttsKey={ttsKey}
                isMixingAudio={isMixingAudio}
                isMusicLoading={isMusicLoading || (!musicSelectionComplete && currentStep >= 4)}
                isBackgroundTTSProcessing={isBackgroundTTSProcessing}
                backgroundTTSProgress={backgroundTTSProgress}
                markStepCompleted={(step) => markStepCompleted(step, completedSteps, setCompletedSteps)}
                goToNextStep={async () =>
                  await handleStepTransitionHandler(5, 6)
                }
                baseUrl={baseUrl}
                isLoading={loaderIsLoading || isTransitioning}
                setMixedAudioInfo={setMixedAudioInfo}
                clearLoading={clearLoading}
                setIsMixingAudio={setIsMixingAudio}
                setIsMusicLoading={setIsMusicLoading}
                setTransitionMessage={setTransitionMessage}
                setTransitionSubtitle={setTransitionSubtitle}
                setIsTransitioning={setIsTransitioning}
              />
            )}
            {currentStep === 6 && !isStepTransitioning && (
              <div className="stable-listen-container" style={{ 
                width: '100%',
                minHeight: '400px',
                display: 'block',
                visibility: 'visible'
              }}>
                <SafeListenStepWrapper 
                  audioUrl={audioUrl}
                  onReset={() => handleResetSessionHandler()}
                  onExportAudio={() => handleExportAudioHandler()}
                  toast={toastInstance}
                  // CRITICAL: Pass initialFullscreen prop explicitly as false
                  initialFullscreen={false}
                >
                  <ErrorBoundary>
                    <StableListenStep
                      audioUrl={audioUrl}
                      onExportAudio={() => handleExportAudioHandler()}
                      onReset={() => handleResetSessionHandler()}
                      onError={(error) => handleAudioErrorHandler(error)}
                      toast={toastInstance}
                      selectedMusic={selectedMusic}
                      musicLibrary={musicLibrary}
                      setSelectedMusic={setSelectedMusic}
                      mixedAudioInfo={mixedAudioInfo}
                      baseUrl={baseUrl}
                      isValidUrl={isValidUrl}
                      onApplyChanges={async (volOverride, filtersOverride) => 
                        await handleAudioMixingFromListenStep(volOverride, filtersOverride)
                      }
                      ttsKey={ttsKey}
                      windowDimensions={windowDimensions}
                      // CRITICAL: Setting to false explicitly
                      initialFullscreen={false}
                    />
                  </ErrorBoundary>
                </SafeListenStepWrapper>
              </div>
            )}
          </div>
        </div>
        <StepNavigation
          currentStep={currentStep}
          totalSteps={6}
          onNext={
            currentStep === 5
              ? async () => await handleStepTransitionHandler(5, 6)
              : () => setCurrentStep(currentStep + 1)
          }
          onPrevious={() => setCurrentStep(currentStep - 1)}
          canAdvance={canAdvance(currentStep, customPrompt, meditationScript, selectedVoice, selectedMusic, audioUrl)}
          isLastStep={currentStep === 6}
          isLoading={loaderIsLoading || isTransitioning || isMixingAudio}
          useSpecialTransition={useSpecialTransition}
          ttsKey={ttsKey}
          isBackgroundTTSProcessing={isBackgroundTTSProcessing}
          backgroundTTSProgress={backgroundTTSProgress}
        />
      </div>
      <CustomToastContainer />
    </ThemeProvider>
  );
};

export default MeditationApp;