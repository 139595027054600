/**
 * sseManager.js
 *
 * Central manager for Server-Sent Events (SSE) connections.
 * 
 * This utility manages SSE connections to track progress of long-running
 * operations (like TTS generation, audio mixing, script generation, etc.).
 * It prevents duplicate connections, limits concurrency, supports reconnection
 * with exponential backoff, and cleans up connections properly.
 */

const activeConnections = new Map();
const completedJobs = new Set();
const connectionQueue = [];
const MAX_CONCURRENT_CONNECTIONS = 8; // Limit concurrent SSE connections

let connectionCount = 0;
let isProcessingQueue = false;

/**
 * Create a new SSE connection or return an existing one.
 * @param {string} jobId - The job ID to monitor.
 * @param {string} baseUrl - The base API URL.
 * @param {Object} callbacks - Callback functions: { onMessage, onComplete, onError }.
 * @returns {Object|Promise} The SSE connection object or a promise that resolves to it.
 */
const createConnection = (jobId, baseUrl = process.env.REACT_APP_API_BASE_URL, callbacks = {}) => {
  if (!jobId) {
    console.error('No job ID provided for SSE connection');
    if (callbacks.onError) callbacks.onError(new Error('No job ID provided'));
    return { close: () => {} };
  }

  const normalizedJobId = jobId.trim();
  const apiUrl = baseUrl || process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';

  // If the job is already completed, trigger completion immediately.
  if (completedJobs.has(normalizedJobId)) {
    console.log(`Job ${normalizedJobId} is already completed, not creating new SSE connection`);
    if (callbacks.onComplete) {
      setTimeout(() => callbacks.onComplete({ complete: true, percentage: 100 }), 0);
    }
    return { close: () => {} };
  }

  // Reuse an active connection if available.
  if (activeConnections.has(normalizedJobId)) {
    console.log(`Reusing existing SSE connection for job: ${normalizedJobId}`);
    const existingConnection = activeConnections.get(normalizedJobId);
    if (callbacks.onMessage || callbacks.onComplete || callbacks.onError) {
      existingConnection.addCallbacks(callbacks);
    }
    return existingConnection;
  }

  // If maximum concurrent connections reached, queue the request.
  if (connectionCount >= MAX_CONCURRENT_CONNECTIONS) {
    console.log(`Max SSE connections reached (${connectionCount}/${MAX_CONCURRENT_CONNECTIONS}), queueing job ${normalizedJobId}`);
    return new Promise((resolve) => {
      connectionQueue.push({
        jobId: normalizedJobId,
        baseUrl: apiUrl,
        callbacks,
        resolve
      });
      if (!isProcessingQueue) processConnectionQueue();
    });
  }

  // Otherwise, create a new connection.
  return createRealConnection(normalizedJobId, apiUrl, callbacks);
};

/**
 * Process the connection queue.
 */
const processConnectionQueue = () => {
  isProcessingQueue = true;
  if (connectionQueue.length === 0) {
    isProcessingQueue = false;
    return;
  }
  if (connectionCount < MAX_CONCURRENT_CONNECTIONS) {
    const next = connectionQueue.shift();
    const connection = createRealConnection(next.jobId, next.baseUrl, next.callbacks);
    next.resolve(connection);
    setTimeout(processConnectionQueue, 50);
  } else {
    setTimeout(processConnectionQueue, 1000);
  }
};

/**
 * Actually create a new SSE connection.
 * @private
 */
const createRealConnection = (jobId, apiUrl, callbacks = {}) => {
  try {
    console.log(`Creating new SSE connection for job ${jobId} (${connectionCount + 1}/${MAX_CONCURRENT_CONNECTIONS})`);
    connectionCount++;
    const allCallbacks = {
      message: callbacks.onMessage ? [callbacks.onMessage] : [],
      complete: callbacks.onComplete ? [callbacks.onComplete] : [],
      error: callbacks.onError ? [callbacks.onError] : []
    };

    let eventSource = new EventSource(`${apiUrl}/progress-stream/${jobId}`);
    let errorCount = 0;
    let progressTimeout = null;
    let lastProgressData = null;
    let retryCount = 0;
    const maxRetries = 3;

    const getRetryDelay = () => Math.min(1000 * Math.pow(2, retryCount), 10000);

    const resetProgressTimeout = () => {
      if (progressTimeout) clearTimeout(progressTimeout);
      progressTimeout = setTimeout(() => {
        console.warn(`No updates for job ${jobId} after 60s, forcing completion`);
        notifyCallbacks('complete', { complete: true, percentage: 100, message: 'Auto-completed due to timeout' });
        close();
      }, 60000);
    };

    const notifyCallbacks = (type, data) => {
      allCallbacks[type].forEach(cb => {
        try {
          cb(data);
        } catch (err) {
          console.error(`Error in ${type} callback:`, err);
        }
      });
    };

    eventSource.onopen = () => {
      console.log(`SSE connection opened for job ${jobId}`);
      errorCount = 0;
      resetProgressTimeout();
    };

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        lastProgressData = data;
        errorCount = 0;
        retryCount = 0;
        resetProgressTimeout();
        notifyCallbacks('message', data);
        if (data.complete || (data.percentage && data.percentage >= 100)) {
          console.log(`Job ${jobId} complete, closing SSE connection`);
          notifyCallbacks('complete', data);
          markJobCompleted(jobId);
          close();
        }
      } catch (err) {
        console.error(`Error parsing SSE message for job ${jobId}:`, err);
        errorCount++;
        if (errorCount > 5) {
          console.warn('Too many parsing errors, closing connection');
          notifyCallbacks('error', new Error('Too many parsing errors'));
          notifyCallbacks('complete', { complete: true, percentage: 100, message: 'Auto-completed due to parsing errors' });
          close();
        }
      }
    };

    eventSource.onerror = (error) => {
      console.error(`SSE connection error for job ${jobId}:`, error);
      errorCount++;
      if (eventSource.readyState === EventSource.CLOSED) {
        retryCount++;
        if (retryCount <= maxRetries) {
          const delay = getRetryDelay();
          console.log(`Attempting to reconnect for job ${jobId} in ${delay}ms (retry ${retryCount}/${maxRetries})`);
          setTimeout(() => {
            try {
              eventSource.close();
              eventSource = new EventSource(`${apiUrl}/progress-stream/${jobId}`);
              eventSource.onopen = () => {
                console.log(`SSE connection reopened for job ${jobId}`);
                errorCount = 0;
                resetProgressTimeout();
              };
              eventSource.onmessage = (event) => {
                try {
                  const data = JSON.parse(event.data);
                  lastProgressData = data;
                  errorCount = 0;
                  retryCount = 0;
                  resetProgressTimeout();
                  notifyCallbacks('message', data);
                  if (data.complete || (data.percentage && data.percentage >= 100)) {
                    console.log(`Job ${jobId} complete, closing SSE connection`);
                    notifyCallbacks('complete', data);
                    markJobCompleted(jobId);
                    close();
                  }
                } catch (err) {
                  console.error(`Error parsing SSE message for job ${jobId}:`, err);
                  errorCount++;
                  if (errorCount > 5) {
                    console.warn('Too many parsing errors, closing connection');
                    notifyCallbacks('error', new Error('Too many parsing errors'));
                    notifyCallbacks('complete', { complete: true, percentage: 100, message: 'Auto-completed due to parsing errors' });
                    close();
                  }
                }
              };
              eventSource.onerror = (error) => {
                console.error(`SSE connection error for job ${jobId}:`, error);
                errorCount++;
                if (retryCount > maxRetries || errorCount > 5) {
                  console.warn(`Too many connection errors for job ${jobId}, closing`);
                  notifyCallbacks('error', new Error('Connection failed after multiple attempts'));
                  const compData = lastProgressData 
                    ? { complete: true, percentage: Math.max(lastProgressData.percentage || 0, 95), message: 'Auto-completed due to connection errors' }
                    : { complete: true, percentage: 100, message: 'Auto-completed due to connection errors' };
                  notifyCallbacks('complete', compData);
                  close();
                }
              };
            } catch (err) {
              console.error(`Failed to reconnect for job ${jobId}:`, err);
            }
          }, delay);
          return;
        }
      }
      if (errorCount > 5) {
        console.warn(`Too many connection errors for job ${jobId}, closing`);
        notifyCallbacks('error', new Error('Connection failed after multiple attempts'));
        const compData = lastProgressData 
          ? { complete: true, percentage: Math.max(lastProgressData.percentage || 0, 95), message: 'Auto-completed due to connection errors' }
          : { complete: true, percentage: 100, message: 'Auto-completed due to connection errors' };
        notifyCallbacks('complete', compData);
        close();
      }
    };

    const close = () => {
      console.log(`Closing SSE connection for job ${jobId}`);
      if (progressTimeout) clearTimeout(progressTimeout);
      eventSource.close();
      activeConnections.delete(jobId);
      connectionCount = Math.max(0, connectionCount - 1);
      if (connectionQueue.length > 0 && !isProcessingQueue) processConnectionQueue();
    };

    const addCallbacks = (newCallbacks = {}) => {
      if (newCallbacks.onMessage) allCallbacks.message.push(newCallbacks.onMessage);
      if (newCallbacks.onComplete) allCallbacks.complete.push(newCallbacks.onComplete);
      if (newCallbacks.onError) allCallbacks.error.push(newCallbacks.onError);
    };

    const connectionObj = { eventSource, close, addCallbacks, jobId };
    activeConnections.set(jobId, connectionObj);
    resetProgressTimeout();
    return connectionObj;
  } catch (error) {
    console.error(`Error creating SSE connection for job ${jobId}:`, error);
    connectionCount = Math.max(0, connectionCount - 1);
    if (callbacks.onError) callbacks.onError(error);
    return { close: () => {}, jobId };
  }
};

/**
 * Mark a job as completed.
 * @param {string} jobId - The job ID to mark as completed.
 */
const markJobCompleted = (jobId) => {
  if (!jobId) return;
  const normalizedJobId = jobId.trim();
  console.log(`Marking job ${normalizedJobId} as completed`);
  completedJobs.add(normalizedJobId);
  if (activeConnections.has(normalizedJobId)) {
    const connection = activeConnections.get(normalizedJobId);
    connection.close();
  }
};

/**
 * Close all active SSE connections.
 */
const closeAllConnections = () => {
  console.log(`Closing all SSE connections (${activeConnections.size} total)`);
  activeConnections.forEach((connection) => {
    try {
      connection.close();
    } catch (err) {
      console.error(`Error closing connection:`, err);
    }
  });
  activeConnections.clear();
  connectionCount = 0;
};

/**
 * Get connection status for a job.
 * @param {string} jobId - The job ID to check.
 * @returns {Object} Connection status information.
 */
const getConnectionStatus = (jobId) => {
  if (!jobId) return { active: false, completed: false };
  const normalizedJobId = jobId.trim();
  return {
    active: activeConnections.has(normalizedJobId),
    completed: completedJobs.has(normalizedJobId),
    pendingInQueue: connectionQueue.some(item => item.jobId === normalizedJobId)
  };
};

/**
 * Check if a job has been completed.
 * @param {string} jobId - The job ID to check.
 * @returns {boolean} Whether the job is completed.
 */
const isJobCompleted = (jobId) => {
  if (!jobId) return false;
  return completedJobs.has(jobId.trim());
};

/**
 * Clear completed jobs older than a certain time.
 * @param {number} maxAgeMs - Max age in milliseconds (default: 1 hour).
 */
const clearOldCompletedJobs = (maxAgeMs = 3600000) => {
  console.log(`Would clear completed jobs older than ${maxAgeMs}ms (${completedJobs.size} total)`);
};

/**
 * Get the current connection stats.
 * @returns {Object} Connection statistics.
 */
const getConnectionStats = () => {
  return {
    activeConnections: connectionCount,
    maxConnections: MAX_CONCURRENT_CONNECTIONS,
    queueLength: connectionQueue.length,
    completedJobs: completedJobs.size
  };
};

export default {
  createConnection,
  markJobCompleted,
  closeAllConnections,
  getConnectionStatus,
  isJobCompleted,
  clearOldCompletedJobs,
  getConnectionStats
};
