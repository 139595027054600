/**
 * GlobalAudioState.js
 *
 * Revised Global Audio State Management.
 * This singleton class manages audio state across components with:
 * - Robust error handling for sessionStorage and state updates.
 * - URL validation, fallback strategies, and debounced URL updates.
 * - Periodic state monitoring with safety timeouts.
 * - Centralized event publishing for state changes.
 * - Integration points for global audio analyzers and SSE.
 */

import { debounce } from '../../utils/debounce';

class GlobalAudioState {
  constructor() {
    // Define default constants
    this.DEFAULT_API_BASE_URL = 'https://api.pause.site';
    this.STATE_MONITOR_INTERVAL = 15000;
    this.TRANSITION_TIMEOUT = 10000;
    this.TTS_TIMEOUT = 30000;
    this.MIXING_TIMEOUT = 30000;
    this.URL_LOCK_TIMEOUT = 5000;
    this.SAFETY_MIXING_TIMEOUT = 45000;

    // Core state object with defaults
    this.state = {
      // TTS state
      ttsKey: null,
      ttsProgress: 0,
      ttsProcessing: false,
      ttsUrl: null,
      // Audio URLs and priority management
      mixedAudioUrl: null,
      currentAudioUrl: null,
      fallbackAudioUrl: null,
      stableUrlReference: null, // Used during transitions
      urlPriority: null, // 'mixed', 'tts', 'fallback', 'other'
      urlLocked: false,
      urlLockTime: null,
      urlPriorityOrder: ['mixed', 'tts', 'fallback', 'other'],
      // Mixing state
      mixingInProgress: false,
      mixingStartTime: null,
      mixingProgress: 0,
      mixingCompleted: false,
      mixingFailed: false,
      mixingErrorReason: null,
      // Transition state
      inTransition: false,
      transitionStartTime: null,
      transitionCompleted: false,
      transitionSource: null,
      transitionTarget: null,
      // Component state tracking
      audioInitialized: false,
      audioLoaded: false,
      audioPlaying: false,
      errorRecoveryMode: false,
      errorCount: 0,
      // System state
      initialized: false,
      lastUpdate: Date.now(),
      eventTarget: (typeof window !== 'undefined') ? new EventTarget() : null,
      // Fallback state
      silentAudioCreated: false,
      silentAudioUrl: null,
      // Resource tracking
      blobUrls: [],
      timeouts: [],
      activeListeners: [],
      // Debug information
      debug: {
        lastError: null,
        stateHistory: [],
        initializeAttempts: 0,
        extractionAttempts: []
      }
    };

    // Shared audio element (for global playback)
    this._audioElement = null;

    // Attempt to restore previous state (if any)
    this.restoreFromSessionStorage();

    // Register global references and auto‑save on unload
    if (typeof window !== 'undefined') {
      try {
        window.__audioState = this;
        window.__getAudioState = () => this.getDebugState();
        window.addEventListener('beforeunload', () => {
          this.saveToSessionStorage();
        });
        // Expose centralized URL methods globally for convenience
        window.centralizeAudioUrl = this.setCurrentAudioUrl.bind(this);
        window.updateAllAudioUrls = this.updateAllAudioUrls.bind(this);
        window.prepareTransitionToListenStep = this.prepareTransitionToListenStep.bind(this);
        window.extractMixedAudioUrl = this.extractMixedAudioUrl.bind(this);
        window.globalAudioState = this;
      } catch (e) {
        console.warn("Global registration failed:", e);
      }
    }

    // Pre-create silent audio URL for fallback speed
    this.ensureSilentAudioUrl();

    // Start periodic state monitoring
    this.setupStateMonitoring();

    // Create a debounced updater for URL changes (300ms delay)
    this._debouncedUpdate = debounce((url) => {
      let priority = 'other';
      if (url.includes('mixed_audio') || url.includes('mixed_')) {
        priority = 'mixed';
      } else if (url.includes('/TTS/') || url.includes('/tts/')) {
        priority = 'tts';
      } else if (url.includes('silent.mp3') || url.includes('data:audio')) {
        priority = 'fallback';
      }
      console.log(`Updating audio URLs with priority ${priority}: ${url.substring(0, 60)}...`);
      this.state.currentAudioUrl = url;
      this.state.stableUrlReference = url;
      this.state.urlPriority = priority;
      try {
        sessionStorage.setItem('currentAudioUrl', url);
        sessionStorage.setItem('urlPriority', priority);
      } catch (e) {
        console.warn("Session storage update error:", e);
      }
      if (this._audioElement) {
        try {
          this._audioElement.src = url;
          this._audioElement.load();
        } catch (e) {
          console.warn("Error updating audio element:", e);
        }
      }
      this.publishEvent('audio-url-updated', { url, priority });
      this.saveToSessionStorage();
    }, 300);
  }

  /* ===== Debug & Validation ===== */
  getDebugState() {
    return {
      tts: {
        key: this.state.ttsKey,
        progress: this.state.ttsProgress,
        processing: this.state.ttsProcessing,
        url: this.state.ttsUrl
      },
      audio: {
        currentUrl: this.state.currentAudioUrl,
        mixedUrl: this.state.mixedAudioUrl,
        fallbackUrl: this.state.fallbackAudioUrl,
        stableUrl: this.state.stableUrlReference,
        initialized: this.state.audioInitialized,
        loaded: this.state.audioLoaded
      },
      priority: {
        currentPriority: this.state.urlPriority,
        isLocked: this.state.urlLocked,
        lockTime: this.state.urlLockTime,
        priorityOrder: this.state.urlPriorityOrder
      },
      mixing: {
        inProgress: this.state.mixingInProgress,
        progress: this.state.mixingProgress,
        completed: this.state.mixingCompleted,
        failed: this.state.mixingFailed,
        errorReason: this.state.mixingErrorReason
      },
      transition: {
        inProgress: this.state.inTransition,
        startTime: this.state.transitionStartTime,
        completed: this.state.transitionCompleted,
        source: this.state.transitionSource,
        target: this.state.transitionTarget
      },
      errors: {
        count: this.state.errorCount,
        recoveryMode: this.state.errorRecoveryMode,
        lastError: this.state.debug.lastError,
        extractionAttempts: this.state.debug.extractionAttempts
      },
      fallback: {
        silentAudioCreated: this.state.silentAudioCreated,
        silentAudioUrl: this.state.silentAudioUrl
      },
      resources: {
        blobUrlCount: this.state.blobUrls.length,
        timeoutCount: this.state.timeouts.length,
        hasSharedAudioElement: !!this._audioElement
      },
      lastUpdate: new Date(this.state.lastUpdate).toISOString()
    };
  }

  /* ===== Periodic State Monitoring ===== */
  setupStateMonitoring() {
    if (typeof window === 'undefined') return;
    this.state.monitoringIntervalId = setInterval(() => {
      const now = Date.now();
      // Monitor transition timeouts
      if (
        this.state.inTransition &&
        this.state.transitionStartTime &&
        now - this.state.transitionStartTime > this.TRANSITION_TIMEOUT
      ) {
        console.warn("Stuck transition detected. Forcing completion.");
        this.state.inTransition = false;
        this.state.transitionCompleted = true;
        this.publishEvent('transition-completed', {
          forced: true,
          duration: now - this.state.transitionStartTime
        });
      }
      // Monitor TTS processing
      if (
        this.state.ttsProcessing &&
        this.state.ttsProgress < 100 &&
        now - this.state.lastUpdate > this.TTS_TIMEOUT
      ) {
        console.warn("Stuck TTS processing detected. Forcing completion.");
        this.completeTTSProcessing();
      }
      // Monitor mixing
      if (
        this.state.mixingInProgress &&
        this.state.mixingStartTime &&
        now - this.state.mixingStartTime > this.MIXING_TIMEOUT
      ) {
        console.warn("Stuck mixing detected. Forcing failure.");
        this.failAudioMixing('timeout');
      }
      // Monitor URL locks
      if (
        this.state.urlLocked &&
        this.state.urlLockTime &&
        now - this.state.urlLockTime > this.URL_LOCK_TIMEOUT
      ) {
        console.warn("Stuck URL lock detected. Releasing lock.");
        this.state.urlLocked = false;
        try {
          sessionStorage.setItem('urlLocked', 'false');
        } catch (e) {
          console.warn("Session storage URL lock update error:", e);
        }
      }
      // Cleanup expired timeouts and blob URLs
      this.clearExpiredTimeouts();
      this.cleanupUnusedBlobUrls();
    }, this.STATE_MONITOR_INTERVAL);
    if (typeof window !== 'undefined') {
      window.__stateMonitoringInterval = this.state.monitoringIntervalId;
    }
  }

  clearAllTimeouts() {
    this.state.timeouts.forEach(t => {
      if (t.id) clearTimeout(t.id);
    });
    this.state.timeouts = [];
  }

  clearExpiredTimeouts() {
    const now = Date.now();
    const expired = this.state.timeouts.filter(t => now - t.createdAt > 120000);
    expired.forEach(t => {
      if (t.id) clearTimeout(t.id);
    });
    this.state.timeouts = this.state.timeouts.filter(t => !expired.includes(t));
  }

  setTimeout(callback, delay) {
    const timeoutId = setTimeout(() => {
      this.state.timeouts = this.state.timeouts.filter(t => t.id !== timeoutId);
      callback();
    }, delay);
    this.state.timeouts.push({ id: timeoutId, createdAt: Date.now() });
    return timeoutId;
  }

  /* ===== URL Validation & Generation ===== */
  isValidUrl(url) {
    if (!url || typeof url !== 'string' || url === 'undefined' || url.includes('undefined/')) return false;
    const validPatterns = [
      /amazonaws\.com\/.*\.(mp3|wav|m4a|ogg)/i,
      /pausebeta20-meditation-assets.*\.(mp3|wav|m4a)/i,
      /s3\..*\.amazonaws\.com\/.*audio/i,
      /api\.pause\.site\/tts\//i,
      /api\.pause\.site\/resources\//i,
      /mixed_audio\/mixed_[0-9]+/i,
      /mixed_[0-9]+.*\.(mp3|wav)/i,
      /TTS\/tts_[a-z0-9]+/i,
      /\/tts\/tts_[a-z0-9]+/i
    ];
    if (url.startsWith('http') || url.startsWith('https')) {
      for (const pattern of validPatterns) {
        if (pattern.test(url)) return true;
      }
      if (/\.(mp3|wav|m4a|ogg|aac)(\?|$)/i.test(url)) return true;
      if (url.includes('?') && (url.includes('AWSAccessKeyId=') || url.includes('Signature=') || url.includes('Expires='))) return true;
      return true;
    }
    if (
      url.startsWith('blob:') ||
      url.startsWith('data:') ||
      url.startsWith('/resources/') ||
      url.startsWith('/tts/')
    ) {
      return true;
    }
    return false;
  }

  getApiBaseUrl() {
    if (typeof window !== 'undefined') {
      try {
        return (
          window.process?.env?.REACT_APP_API_BASE_URL ||
          process.env.REACT_APP_API_BASE_URL ||
          this.DEFAULT_API_BASE_URL
        );
      } catch (e) {
        return this.DEFAULT_API_BASE_URL;
      }
    }
    return this.DEFAULT_API_BASE_URL;
  }

  createStableAudioUrl(source) {
    if (this.isValidUrl(source)) return source;
    if (source && typeof source === 'string') {
      let cleanKey = source.startsWith('TTS/') ? source.substring(4) : source;
      const baseUrl = this.getApiBaseUrl();
      return `${baseUrl}/tts/${encodeURIComponent(cleanKey)}`;
    }
    return this.getSilentAudioUrl();
  }

  getTtsUrl(filename) {
    if (!filename) return null;
    let cleanFilename = filename.includes('/') ? filename.split('/').pop() : filename;
    cleanFilename = cleanFilename.startsWith("TTS/") ? cleanFilename.substring(4) : cleanFilename;
    return `${this.getApiBaseUrl()}/tts/${cleanFilename}`;
  }

  /* ===== Audio Element Management ===== */
  getSharedAudioElement() {
    if (this._audioElement) return this._audioElement;
    this._audioElement = new Audio();
    this._audioElement.preload = 'auto';
    this._audioElement.crossOrigin = 'anonymous';
    const bestUrl = this.getBestAudioUrl();
    if (bestUrl) {
      this._audioElement.src = bestUrl;
      this._audioElement.load();
    }
    return this._audioElement;
  }

  setSharedAudioElement(element) {
    if (element instanceof Audio) {
      this._audioElement = element;
      if (this.state.currentAudioUrl && !element.src) {
        element.src = this.state.currentAudioUrl;
        element.load();
      }
    }
  }

  /* ===== Fallback Audio ===== */
  getSilentAudioUrl() {
    if (this.state.silentAudioUrl) return this.state.silentAudioUrl;
    const url = `${this.getApiBaseUrl()}/resources/silent.mp3`;
    this.state.silentAudioUrl = url;
    this.state.silentAudioCreated = true;
    return url;
  }

  ensureSilentAudioUrl() {
    if (!this.state.silentAudioUrl) {
      this.state.silentAudioUrl = this.getSilentAudioUrl();
      this.state.silentAudioCreated = true;
    }
  }

  cleanupUnusedBlobUrls() {
    if (!this.state.blobUrls.length) return;
    const used = [
      this.state.currentAudioUrl,
      this.state.mixedAudioUrl,
      this.state.fallbackAudioUrl,
      this.state.stableUrlReference,
      this.state.ttsUrl
    ].filter(Boolean);
    const unused = this.state.blobUrls.filter(blobUrl => !used.includes(blobUrl.url));
    unused.forEach(blobUrl => {
      try {
        URL.revokeObjectURL(blobUrl.url);
      } catch (e) {
        console.warn("Error revoking blob URL:", e);
      }
    });
    this.state.blobUrls = this.state.blobUrls.filter(blobUrl => !unused.includes(blobUrl));
  }

  /* ===== URL Extraction ===== */
  extractMixedAudioUrl(mixResult) {
    const attempt = { timestamp: Date.now(), resultType: typeof mixResult, success: false };
    try {
      if (!mixResult) {
        attempt.reason = "Empty mix result";
        this.state.debug.extractionAttempts.push(attempt);
        return { success: false, reason: "Empty mix result" };
      }
      attempt.resultFormat = Array.isArray(mixResult)
        ? 'array'
        : (typeof mixResult === 'object' ? 'object' : typeof mixResult);
      // Case 1: Direct file_path property
      if (typeof mixResult === 'object' && mixResult.file_path) {
        attempt.foundProperty = 'file_path';
        attempt.propertyValue = mixResult.file_path;
        if (typeof mixResult.file_path === 'string' && this.isValidUrl(mixResult.file_path)) {
          attempt.success = true;
          attempt.url = mixResult.file_path;
          this.state.debug.extractionAttempts.push(attempt);
          return { success: true, url: mixResult.file_path, source: 'direct.file_path' };
        }
      }
      // Case 2: Nested data
      if (typeof mixResult === 'object' && mixResult.data) {
        if (mixResult.data.file_path && typeof mixResult.data.file_path === 'string' && this.isValidUrl(mixResult.data.file_path)) {
          attempt.foundProperty = 'data.file_path';
          attempt.success = true;
          attempt.url = mixResult.data.file_path;
          this.state.debug.extractionAttempts.push(attempt);
          return { success: true, url: mixResult.data.file_path, source: 'data.file_path' };
        }
        const possibleFields = ['url', 'audioUrl', 'audio_url', 'mixed_url', 'mixedUrl', 'mp3_url', 'mp3Url', 'filename', 'audio', 'filepath'];
        for (const field of possibleFields) {
          if (mixResult.data[field] && typeof mixResult.data[field] === 'string' && this.isValidUrl(mixResult.data[field])) {
            attempt.checkedField = field;
            attempt.success = true;
            attempt.url = mixResult.data[field];
            this.state.debug.extractionAttempts.push(attempt);
            return { success: true, url: mixResult.data[field], source: `data.${field}` };
          }
        }
      }
      // Case 3: Direct string (with JSON parse attempt)
      if (typeof mixResult === 'string') {
        attempt.checkedDirectString = true;
        if (this.isValidUrl(mixResult)) {
          attempt.success = true;
          attempt.url = mixResult;
          this.state.debug.extractionAttempts.push(attempt);
          return { success: true, url: mixResult, source: 'direct_string' };
        }
        if (mixResult.startsWith('{') || mixResult.startsWith('[')) {
          attempt.attemptedJsonParse = true;
          try {
            const parsed = JSON.parse(mixResult);
            const parsedExtraction = this.extractMixedAudioUrl(parsed);
            if (parsedExtraction.success) {
              attempt.success = true;
              attempt.url = parsedExtraction.url;
              attempt.source = `parsed_json.${parsedExtraction.source}`;
              this.state.debug.extractionAttempts.push(attempt);
              return parsedExtraction;
            }
          } catch (parseError) {
            attempt.parseError = parseError.message;
          }
        }
        const urlRegex = /(https?:\/\/[^\s"'<>]+\.(mp3|wav|m4a|ogg|aac)(\?[^\s"'<>]+)?)/i;
        const match = mixResult.match(urlRegex);
        if (match && match[0] && this.isValidUrl(match[0])) {
          attempt.extractedUrlFromText = true;
          attempt.success = true;
          attempt.url = match[0];
          this.state.debug.extractionAttempts.push(attempt);
          return { success: true, url: match[0], source: 'regex_extracted' };
        }
      }
      // Case 4: Format with status:'success'
      if (typeof mixResult === 'object' && mixResult.status === 'success') {
        if (mixResult.file_path && typeof mixResult.file_path === 'string' && this.isValidUrl(mixResult.file_path)) {
          attempt.success = true;
          attempt.url = mixResult.file_path;
          this.state.debug.extractionAttempts.push(attempt);
          return { success: true, url: mixResult.file_path, source: 'success_response.file_path' };
        }
        const rootFields = ['url', 'audioUrl', 'mp3Url', 'mixedAudioUrl', 'filepath'];
        for (const field of rootFields) {
          if (mixResult[field] && typeof mixResult[field] === 'string' && this.isValidUrl(mixResult[field])) {
            attempt.success = true;
            attempt.url = mixResult[field];
            attempt.sourceField = field;
            this.state.debug.extractionAttempts.push(attempt);
            return { success: true, url: mixResult[field], source: `success_response.${field}` };
          }
        }
      }
      attempt.reason = "No valid URL found in expected locations";
      this.state.debug.extractionAttempts.push(attempt);
      if (this.state.debug.extractionAttempts.length > 10) {
        this.state.debug.extractionAttempts = this.state.debug.extractionAttempts.slice(-10);
      }
      return { success: false, reason: "No valid URL found", attemptedFields: attempt };
    } catch (error) {
      attempt.error = error.message;
      attempt.stack = error.stack;
      this.state.debug.extractionAttempts.push(attempt);
      return { success: false, error: error.message };
    }
  }

  async verifyTtsFile(ttsKey) {
    if (!ttsKey) return { exists: false };
    try {
      let cleanKey = ttsKey.startsWith('TTS/') ? ttsKey.substring(4) : ttsKey;
      const verifyUrl = `${this.getApiBaseUrl()}/verify-tts/${cleanKey}`;
      const response = await fetch(verifyUrl, {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        cache: 'no-cache'
      });
      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          if (data.presigned_url && this.isValidUrl(data.presigned_url)) {
            this.state.fallbackAudioUrl = data.presigned_url;
            return { exists: true, url: data.presigned_url };
          } else {
            const regularUrl = this.getTtsUrl(cleanKey);
            this.state.fallbackAudioUrl = regularUrl;
            return { exists: true, url: regularUrl };
          }
        } else {
          return { exists: false, fallbackUrl: data.fallback_url || this.getSilentAudioUrl() };
        }
      }
      console.warn("TTS verification endpoint failed");
      const directUrl = this.getTtsUrl(cleanKey);
      try {
        const headResponse = await fetch(directUrl, { method: 'GET', cache: 'no-cache' });
        if (headResponse.ok) {
          return { exists: true, url: directUrl };
        }
      } catch (headError) {
        console.warn("GET request for TTS file failed:", headError);
      }
      return { exists: false, fallbackUrl: this.getSilentAudioUrl() };
    } catch (error) {
      console.error("Error verifying TTS file:", error);
      return { exists: false, error: error.message, fallbackUrl: this.getSilentAudioUrl() };
    }
  }

  /* ===== State Persistence ===== */
  restoreFromSessionStorage() {
    if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return;
    try {
      console.log("Restoring audio state from session storage");
      this.restoreTtsState();
      this.restoreAudioUrls();
      this.restoreMixingState();
      this.restoreTransitionState();
      this.restoreComponentState();
      this.restoreUrlPriorityState();
      this.state.initialized = true;
      console.log("Restored state:", {
        tts: { key: this.state.ttsKey, progress: this.state.ttsProgress },
        audio: {
          mixed: !!this.state.mixedAudioUrl,
          current: !!this.state.currentAudioUrl,
          stable: !!this.state.stableUrlReference
        },
        priority: this.state.urlPriority
      });
    } catch (e) {
      console.warn("Error restoring state:", e);
      this.state.debug.lastError = { time: Date.now(), context: "restoreFromSessionStorage", message: e.message };
    }
  }

  restoreTtsState() {
    try {
      const ttsKey = sessionStorage.getItem('ttsKey');
      if (ttsKey) {
        this.state.ttsKey = ttsKey;
        const ttsUrl = sessionStorage.getItem('ttsAudioUrl');
        if (ttsUrl && this.isValidUrl(ttsUrl)) {
          this.state.ttsUrl = ttsUrl;
          this.state.fallbackAudioUrl = ttsUrl;
        } else {
          const freshTtsUrl = this.getTtsUrl(ttsKey);
          if (freshTtsUrl) {
            this.state.ttsUrl = freshTtsUrl;
            this.state.fallbackAudioUrl = freshTtsUrl;
            try { sessionStorage.setItem('ttsAudioUrl', freshTtsUrl); } catch (e) { console.warn("Error storing ttsAudioUrl:", e); }
          }
        }
      }
      const ttsProgress = sessionStorage.getItem('ttsProgress');
      if (ttsProgress) this.state.ttsProgress = parseInt(ttsProgress, 10) || 0;
      const ttsProcessing = sessionStorage.getItem('ttsProcessing');
      if (ttsProcessing) this.state.ttsProcessing = ttsProcessing === 'true';
      const silentAudioUrl = sessionStorage.getItem('silentAudioUrl');
      if (silentAudioUrl && this.isValidUrl(silentAudioUrl)) {
        this.state.silentAudioUrl = silentAudioUrl;
        this.state.silentAudioCreated = true;
      } else {
        this.ensureSilentAudioUrl();
      }
    } catch (e) {
      console.warn("Error restoring TTS state:", e);
    }
  }

  restoreAudioUrls() {
    try {
      const mixedAudioUrl = sessionStorage.getItem('mixedAudioUrl');
      if (mixedAudioUrl && this.isValidUrl(mixedAudioUrl)) {
        this.state.mixedAudioUrl = mixedAudioUrl;
        this.state.currentAudioUrl = mixedAudioUrl;
        this.state.stableUrlReference = mixedAudioUrl;
      }
      if (!this.state.currentAudioUrl) {
        const currentAudioUrl = sessionStorage.getItem('currentAudioUrl');
        if (currentAudioUrl && this.isValidUrl(currentAudioUrl)) {
          this.state.currentAudioUrl = currentAudioUrl;
          this.state.stableUrlReference = currentAudioUrl;
        }
      }
      const stableUrl = sessionStorage.getItem('stableTransitionUrl');
      if (stableUrl && this.isValidUrl(stableUrl)) {
        this.state.stableUrlReference = stableUrl;
        if (!this.state.currentAudioUrl) this.state.currentAudioUrl = stableUrl;
      }
      const fallbackAudioUrl = sessionStorage.getItem('fallbackAudioUrl');
      if (fallbackAudioUrl && this.isValidUrl(fallbackAudioUrl)) {
        this.state.fallbackAudioUrl = fallbackAudioUrl;
      }
      if (this.state.ttsKey && !this.state.currentAudioUrl && !this.state.fallbackAudioUrl) {
        const generatedUrl = this.getTtsUrl(this.state.ttsKey);
        if (generatedUrl) {
          this.state.fallbackAudioUrl = generatedUrl;
          this.state.currentAudioUrl = generatedUrl;
          this.state.stableUrlReference = generatedUrl;
          try {
            sessionStorage.setItem('fallbackAudioUrl', generatedUrl);
            sessionStorage.setItem('currentAudioUrl', generatedUrl);
            sessionStorage.setItem('stableTransitionUrl', generatedUrl);
          } catch (e) { console.warn("Error storing fallback URL:", e); }
        }
      }
    } catch (e) {
      console.warn("Error restoring audio URLs:", e);
    }
  }

  restoreMixingState() {
    try {
      const mixingCompleted = sessionStorage.getItem('mixingCompleted');
      if (mixingCompleted) this.state.mixingCompleted = mixingCompleted === 'true';
      const mixingFailed = sessionStorage.getItem('mixingFailed');
      if (mixingFailed) this.state.mixingFailed = mixingFailed === 'true';
      const mixingErrorReason = sessionStorage.getItem('mixingErrorReason');
      if (mixingErrorReason) this.state.mixingErrorReason = mixingErrorReason;
      const mixingInProgress = sessionStorage.getItem('mixingInProgress');
      this.state.mixingInProgress = mixingInProgress === 'true';
      const mixingProgress = sessionStorage.getItem('mixingProgress');
      if (mixingProgress) this.state.mixingProgress = parseInt(mixingProgress, 10) || 0;
      const mixingStartTime = sessionStorage.getItem('mixingStartTime');
      if (mixingStartTime) this.state.mixingStartTime = parseInt(mixingStartTime, 10) || null;
    } catch (e) {
      console.warn("Error restoring mixing state:", e);
    }
  }

  restoreTransitionState() {
    try {
      const inTransition = sessionStorage.getItem('transitionInitiated');
      if (inTransition) this.state.inTransition = inTransition === 'true';
      const transitionStartTime = sessionStorage.getItem('transitionTime');
      if (transitionStartTime) this.state.transitionStartTime = parseInt(transitionStartTime, 10) || null;
      const transitionCompleted = sessionStorage.getItem('transitionCompleted');
      if (transitionCompleted) this.state.transitionCompleted = transitionCompleted === 'true';
      const transitionSource = sessionStorage.getItem('transitionSource');
      if (transitionSource) this.state.transitionSource = transitionSource;
      const transitionTarget = sessionStorage.getItem('transitionTarget');
      if (transitionTarget) this.state.transitionTarget = transitionTarget;
      if (
        this.state.inTransition &&
        this.state.transitionStartTime &&
        Date.now() - this.state.transitionStartTime > this.TRANSITION_TIMEOUT
      ) {
        this.state.inTransition = false;
        this.state.transitionCompleted = true;
        try {
          sessionStorage.setItem('transitionInitiated', 'false');
          sessionStorage.setItem('transitionCompleted', 'true');
        } catch (e) { console.warn("Error updating transition state:", e); }
      }
    } catch (e) {
      console.warn("Error restoring transition state:", e);
    }
  }

  restoreComponentState() {
    try {
      const audioInitialized = sessionStorage.getItem('audioInitialized');
      if (audioInitialized) this.state.audioInitialized = audioInitialized === 'true';
      const audioLoaded = sessionStorage.getItem('audioLoaded');
      if (audioLoaded) this.state.audioLoaded = audioLoaded === 'true';
      const audioPlaying = sessionStorage.getItem('audioPlaying');
      if (audioPlaying) this.state.audioPlaying = audioPlaying === 'true';
      const errorRecoveryMode = sessionStorage.getItem('errorRecoveryMode');
      if (errorRecoveryMode) this.state.errorRecoveryMode = errorRecoveryMode === 'true';
      const errorCount = sessionStorage.getItem('errorCount');
      if (errorCount) this.state.errorCount = parseInt(errorCount, 10) || 0;
    } catch (e) {
      console.warn("Error restoring component state:", e);
    }
  }

  restoreUrlPriorityState() {
    try {
      const urlPriority = sessionStorage.getItem('urlPriority');
      if (urlPriority) this.state.urlPriority = urlPriority;
      const urlLocked = sessionStorage.getItem('urlLocked');
      if (urlLocked) this.state.urlLocked = urlLocked === 'true';
      const urlLockTime = sessionStorage.getItem('urlLockTime');
      if (urlLockTime) {
        this.state.urlLockTime = parseInt(urlLockTime, 10) || null;
        if (this.state.urlLockTime && Date.now() - this.state.urlLockTime > this.URL_LOCK_TIMEOUT) {
          this.state.urlLocked = false;
        }
      }
    } catch (e) {
      console.warn("Error restoring URL priority state:", e);
    }
  }

  saveToSessionStorage() {
    if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return;
    try {
      this.state.lastUpdate = Date.now();
      // TTS state
      if (this.state.ttsKey) sessionStorage.setItem('ttsKey', this.state.ttsKey);
      if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) sessionStorage.setItem('ttsAudioUrl', this.state.ttsUrl);
      sessionStorage.setItem('ttsProgress', this.state.ttsProgress.toString());
      sessionStorage.setItem('ttsProcessing', this.state.ttsProcessing.toString());
      // Audio URLs
      if (this.state.mixedAudioUrl && this.isValidUrl(this.state.mixedAudioUrl)) sessionStorage.setItem('mixedAudioUrl', this.state.mixedAudioUrl);
      if (this.state.currentAudioUrl && this.isValidUrl(this.state.currentAudioUrl)) sessionStorage.setItem('currentAudioUrl', this.state.currentAudioUrl);
      if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) sessionStorage.setItem('fallbackAudioUrl', this.state.fallbackAudioUrl);
      if (this.state.stableUrlReference && this.isValidUrl(this.state.stableUrlReference)) sessionStorage.setItem('stableTransitionUrl', this.state.stableUrlReference);
      // URL priority state
      if (this.state.urlPriority) sessionStorage.setItem('urlPriority', this.state.urlPriority);
      sessionStorage.setItem('urlLocked', this.state.urlLocked.toString());
      if (this.state.urlLockTime) sessionStorage.setItem('urlLockTime', this.state.urlLockTime.toString());
      // Mixing state
      sessionStorage.setItem('mixingCompleted', this.state.mixingCompleted.toString());
      sessionStorage.setItem('mixingFailed', this.state.mixingFailed.toString());
      if (this.state.mixingErrorReason) sessionStorage.setItem('mixingErrorReason', this.state.mixingErrorReason);
      sessionStorage.setItem('mixingInProgress', this.state.mixingInProgress.toString());
      sessionStorage.setItem('mixingProgress', this.state.mixingProgress.toString());
      if (this.state.mixingStartTime) sessionStorage.setItem('mixingStartTime', this.state.mixingStartTime.toString());
      // Transition state
      sessionStorage.setItem('transitionInitiated', this.state.inTransition.toString());
      sessionStorage.setItem('transitionCompleted', this.state.transitionCompleted.toString());
      if (this.state.transitionStartTime) sessionStorage.setItem('transitionTime', this.state.transitionStartTime.toString());
      if (this.state.transitionSource) sessionStorage.setItem('transitionSource', this.state.transitionSource);
      if (this.state.transitionTarget) sessionStorage.setItem('transitionTarget', this.state.transitionTarget);
      // Component state
      sessionStorage.setItem('audioInitialized', this.state.audioInitialized.toString());
      sessionStorage.setItem('audioLoaded', this.state.audioLoaded.toString());
      sessionStorage.setItem('audioPlaying', this.state.audioPlaying.toString());
      sessionStorage.setItem('errorRecoveryMode', this.state.errorRecoveryMode.toString());
      sessionStorage.setItem('errorCount', this.state.errorCount.toString());
      // Fallback state
      if (this.state.silentAudioUrl && this.isValidUrl(this.state.silentAudioUrl)) sessionStorage.setItem('silentAudioUrl', this.state.silentAudioUrl);
      sessionStorage.setItem('silentAudioCreated', this.state.silentAudioCreated.toString());
    } catch (e) {
      console.warn("Error saving state to sessionStorage:", e);
      this.state.debug.lastError = { time: Date.now(), context: "saveToSessionStorage", message: e.message };
    }
  }

  /* ===== Global State Updaters ===== */
  updateAllAudioUrls(url) {
    try {
      if (!this.isValidUrl(url)) {
        console.warn("Invalid URL in updateAllAudioUrls:", url);
        url = this.getBestAudioUrl();
        if (!this.isValidUrl(url)) {
          console.error("No valid URL available, using silent audio");
          url = this.getSilentAudioUrl();
        }
      }
      let priority = 'other';
      if (url.includes('mixed_audio') || url.includes('mixed_')) {
        priority = 'mixed';
      } else if (url.includes('/TTS/') || url.includes('/tts/')) {
        priority = 'tts';
      } else if (url.includes('silent.mp3') || url.includes('data:audio')) {
        priority = 'fallback';
      }
      console.log(`Updating all audio URLs (priority ${priority}): ${url.substring(0, 60)}...`);
      this.state.currentAudioUrl = url;
      this.state.stableUrlReference = url;
      this.state.urlPriority = priority;
      if (priority === 'mixed') this.state.mixedAudioUrl = url;
      else if (priority === 'tts') this.state.ttsUrl = url;
      else if (priority === 'fallback') this.state.fallbackAudioUrl = url;
      try {
        sessionStorage.setItem('currentAudioUrl', url);
        sessionStorage.setItem('stableTransitionUrl', url);
        sessionStorage.setItem('urlPriority', priority);
        sessionStorage.setItem('lastUpdateTime', Date.now().toString());
        if (priority === 'mixed') {
          sessionStorage.setItem('mixedAudioUrl', url);
          sessionStorage.setItem('urlLocked', 'true');
          sessionStorage.setItem('urlLockTime', Date.now().toString());
        } else if (priority === 'tts') {
          sessionStorage.setItem('ttsAudioUrl', url);
        }
      } catch (e) {
        console.warn("Error updating sessionStorage:", e);
      }
      if (this._audioElement) {
        try {
          this._audioElement.src = url;
          this._audioElement.load();
        } catch (e) {
          console.warn("Error updating audio element:", e);
        }
      }
      if (typeof window !== 'undefined') {
        window.__centralizedAudioUrl = url;
        window.__currentAudioUrl = url;
        window.__stableTransitionUrl = url;
        window.__lastUrlUpdateTime = Date.now();
        window.__urlPriority = priority;
        if (priority === 'mixed') window.__mixedAudioUrl = url;
      }
      this.publishEvent('audio-url-updated', { url, priority });
      this.saveToSessionStorage();
      return true;
    } catch (error) {
      console.error("Error in updateAllAudioUrls:", error);
      return false;
    }
  }

  setCurrentAudioUrl(url) {
    try {
      if (!this.isValidUrl(url)) {
        console.warn("Invalid URL in setCurrentAudioUrl:", url);
        return false;
      }
      let newPriority = 'other';
      if (url.includes('mixed_audio') || url.includes('mixed_')) newPriority = 'mixed';
      else if (url.includes('/TTS/') || url.includes('/tts/')) newPriority = 'tts';
      else if (url.includes('silent.mp3') || url.includes('data:audio')) newPriority = 'fallback';
      if (this.state.urlLocked) {
        const lockAge = Date.now() - (this.state.urlLockTime || 0);
        if (newPriority === 'mixed') {
          console.log("Mixed URL update allowed despite lock.");
          this.state.currentAudioUrl = url;
          this.state.mixedAudioUrl = url;
          this.state.urlPriority = 'mixed';
          if (this._audioElement) {
            try {
              this._audioElement.src = url;
              this._audioElement.load();
            } catch (e) {
              console.warn("Error updating audio element:", e);
            }
          }
          this.publishEvent('audio-url-updated', { url, priority: 'mixed' });
          return true;
        }
        if (lockAge > this.URL_LOCK_TIMEOUT) this.state.urlLocked = false;
        else {
          if (url !== this.state.currentAudioUrl && url.startsWith('http')) {
            console.log("URL update locked due to high-priority lock.");
          }
          return false;
        }
      }
      const currentStep = parseInt(sessionStorage.getItem('currentStep') || '0', 10);
      if (currentStep === 6 && this.state.urlPriority === 'mixed' && newPriority !== 'mixed') {
        const mixedUrl = this.state.mixedAudioUrl || sessionStorage.getItem('mixedAudioUrl');
        if (mixedUrl && this.isValidUrl(mixedUrl)) {
          console.log("Prevented URL priority downgrade in ListenStep");
          return false;
        }
      }
      console.log(`Setting audio URL (priority ${newPriority}): ${url.substring(0, 60)}...`);
      this.state.currentAudioUrl = url;
      this.state.lastUpdate = Date.now();
      this.state.urlPriority = newPriority;
      if (newPriority === 'mixed') this.state.mixedAudioUrl = url;
      else if (newPriority === 'tts') this.state.ttsUrl = url;
      else if (newPriority === 'fallback') this.state.fallbackAudioUrl = url;
      if (!this.state.stableUrlReference) this.state.stableUrlReference = url;
      if (this._audioElement) {
        try {
          this._audioElement.src = url;
          this._audioElement.load();
        } catch (e) { console.warn("Error updating shared audio element:", e); }
      }
      if (typeof window !== 'undefined') {
        window.__centralizedAudioUrl = url;
        window.__currentAudioUrl = url;
        window.__lastUrlUpdateTime = Date.now();
        window.__urlPriority = newPriority;
        if (newPriority === 'mixed') window.__mixedAudioUrl = url;
      }
      try {
        sessionStorage.setItem('currentAudioUrl', url);
        sessionStorage.setItem('urlPriority', newPriority);
        if (newPriority === 'mixed') sessionStorage.setItem('mixedAudioUrl', url);
        else if (newPriority === 'tts') sessionStorage.setItem('ttsAudioUrl', url);
      } catch (e) { console.warn("Error updating sessionStorage:", e); }
      this.publishEvent('audio-url-updated', { url, priority: newPriority });
      this.saveToSessionStorage();
      return true;
    } catch (error) {
      console.error("Error in setCurrentAudioUrl:", error);
      return false;
    }
  }

  lockUrl(url, priority = null) {
    if (!this.isValidUrl(url)) {
      console.warn("Attempted to lock invalid URL:", url);
      return false;
    }
    console.log(`Locking URL (priority ${priority || 'unset'}): ${url.substring(0, 60)}...`);
    this.state.urlLocked = true;
    this.state.urlLockTime = Date.now();
    if (priority) this.state.urlPriority = priority;
    this.state.currentAudioUrl = url;
    this.state.stableUrlReference = url;
    if (priority === 'mixed') this.state.mixedAudioUrl = url;
    else if (priority === 'tts') this.state.ttsUrl = url;
    else if (priority === 'fallback') this.state.fallbackAudioUrl = url;
    try {
      sessionStorage.setItem('urlLocked', 'true');
      sessionStorage.setItem('urlLockTime', Date.now().toString());
      sessionStorage.setItem('currentAudioUrl', url);
      sessionStorage.setItem('stableTransitionUrl', url);
      if (priority) sessionStorage.setItem('urlPriority', priority);
      if (priority === 'mixed') sessionStorage.setItem('mixedAudioUrl', url);
      else if (priority === 'tts') sessionStorage.setItem('ttsAudioUrl', url);
    } catch (e) { console.warn("Error updating sessionStorage during lockUrl:", e); }
    if (typeof window !== 'undefined') {
      window.__centralizedAudioUrl = url;
      window.__currentAudioUrl = url;
      window.__stableTransitionUrl = url;
      window.__lastUrlUpdateTime = Date.now();
      window.__urlLocked = true;
      if (priority) window.__urlPriority = priority;
      if (priority === 'mixed') window.__mixedAudioUrl = url;
    }
    if (this._audioElement) {
      try {
        this._audioElement.src = url;
        this._audioElement.load();
      } catch (e) { console.warn("Error updating audio element during lockUrl:", e); }
    }
    this.publishEvent('audio-url-locked', { url, priority });
    return true;
  }

  unlockUrl() {
    this.state.urlLocked = false;
    try { sessionStorage.setItem('urlLocked', 'false'); } catch (e) { console.warn("Error updating sessionStorage in unlockUrl:", e); }
    if (typeof window !== 'undefined') window.__urlLocked = false;
    this.publishEvent('audio-url-unlocked', {});
  }

  async updateTTSKey(key, url = null) {
    if (!key) return;
    this.state.ttsKey = key;
    if (url && this.isValidUrl(url)) {
      this.state.ttsUrl = url;
      this.state.fallbackAudioUrl = url;
      if (!this.state.currentAudioUrl) this.setCurrentAudioUrl(url);
    } else {
      try {
        const verification = await this.verifyTtsFile(key);
        if (verification.exists && verification.url) {
          this.state.ttsUrl = verification.url;
          this.state.fallbackAudioUrl = verification.url;
          if (!this.state.currentAudioUrl) this.setCurrentAudioUrl(verification.url);
        } else {
          const fallbackUrl = verification.fallbackUrl || this.getSilentAudioUrl();
          this.state.ttsUrl = fallbackUrl;
          this.state.fallbackAudioUrl = fallbackUrl;
          if (!this.state.currentAudioUrl) this.setCurrentAudioUrl(fallbackUrl);
          console.warn(`TTS file ${key} not found, using fallback URL: ${fallbackUrl}`);
        }
      } catch (error) {
        console.error("Error verifying TTS file:", error);
        const generatedUrl = this.getTtsUrl(key);
        if (generatedUrl) {
          this.state.ttsUrl = generatedUrl;
          this.state.fallbackAudioUrl = generatedUrl;
          if (!this.state.currentAudioUrl) this.setCurrentAudioUrl(generatedUrl);
        }
      }
    }
    this.publishEvent('tts-key-update', { key, url: this.state.ttsUrl });
    this.saveToSessionStorage();
    if (typeof window !== 'undefined') {
      window.__ttsKey = key;
      window.__ttsUrl = this.state.ttsUrl;
    }
  }

  startTTSProcessing() {
    this.state.ttsProcessing = true;
    this.state.ttsProgress = 0;
    this.state.lastUpdate = Date.now();
    this.publishEvent('tts-processing-start', {});
    this.saveToSessionStorage();
    if (typeof window !== 'undefined') {
      window.__ttsProcessing = true;
      window.__ttsProgress = 0;
      window.__ttsProcessingStartTime = Date.now();
    }
  }

  completeTTSProcessing() {
    this.state.ttsProcessing = false;
    this.state.ttsProgress = 100;
    this.state.lastUpdate = Date.now();
    this.publishEvent('tts-processing-complete', {});
    this.saveToSessionStorage();
    if (typeof window !== 'undefined') {
      window.__ttsProcessing = false;
      window.__ttsProgress = 100;
      window.__ttsComplete = true;
      window.__ttsCompletionTime = Date.now();
      try {
        sessionStorage.setItem('ttsComplete', 'true');
        sessionStorage.setItem('ttsCompletionTime', Date.now().toString());
      } catch (e) { console.warn("Error storing TTS completion state:", e); }
    }
  }

  updateTTSProgress(progress) {
    if (typeof progress !== 'number' || isNaN(progress)) return;
    progress = Math.max(0, Math.min(100, progress));
    this.state.ttsProgress = progress;
    this.state.lastUpdate = Date.now();
    this.publishEvent('tts-progress-update', { progress });
    if (typeof window !== 'undefined') window.__ttsProgress = progress;
    if (progress % 10 === 0 || progress >= 100) this.saveToSessionStorage();
  }

  startAudioMixing() {
    this.state.mixingInProgress = true;
    this.state.mixingStartTime = Date.now();
    this.state.mixingProgress = 0;
    this.state.mixingCompleted = false;
    this.state.mixingFailed = false;
    this.state.mixingErrorReason = null;
    this.state.lastUpdate = Date.now();
    this.publishEvent('mixing-start', {});
    this.saveToSessionStorage();
    if (typeof window !== 'undefined') {
      window.__mixingInProgress = true;
      window.__mixingStartTime = Date.now();
      window.__mixingProgress = 0;
      window.__mixingCompleted = false;
      window.__mixingFailed = false;
    }
    this.setTimeout(() => {
      if (this.state.mixingInProgress && !this.state.mixingCompleted && !this.state.mixingFailed) {
        console.warn("Mixing safety timeout triggered - forcing completion");
        if (this.state.currentAudioUrl && this.isValidUrl(this.state.currentAudioUrl)) {
          this.completeAudioMixing(this.state.currentAudioUrl);
        } else if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) {
          this.completeAudioMixing(this.state.fallbackAudioUrl);
        } else {
          this.failAudioMixing('timeout');
        }
      }
    }, this.SAFETY_MIXING_TIMEOUT);
  }

  updateMixingProgress(progress) {
    if (typeof progress !== 'number' || isNaN(progress)) return;
    progress = Math.max(0, Math.min(100, progress));
    this.state.mixingProgress = progress;
    this.state.lastUpdate = Date.now();
    this.publishEvent('mixing-progress-update', { progress });
    if (typeof window !== 'undefined') window.__mixingProgress = progress;
    if (progress % 10 === 0 || progress >= 100) this.saveToSessionStorage();
  }

  failAudioMixing(reason = 'unknown') {
    this.state.mixingInProgress = false;
    this.state.mixingFailed = true;
    this.state.mixingCompleted = true;
    this.state.mixingProgress = 100;
    this.state.mixingErrorReason = reason;
    this.state.lastUpdate = Date.now();
    let fallbackUrl = null;
    if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) {
      fallbackUrl = this.state.ttsUrl;
      this.state.urlPriority = 'tts';
    } else if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) {
      fallbackUrl = this.state.fallbackAudioUrl;
      this.state.urlPriority = 'fallback';
    } else if (this.state.ttsKey) {
      fallbackUrl = this.getTtsUrl(this.state.ttsKey);
      this.state.urlPriority = 'tts';
    } else {
      fallbackUrl = this.getSilentAudioUrl();
      this.state.urlPriority = 'fallback';
    }
    this.updateAllAudioUrls(fallbackUrl);
    try {
      sessionStorage.setItem('mixingFailed', 'true');
      sessionStorage.setItem('mixingCompleted', 'true');
      sessionStorage.setItem('mixingInProgress', 'false');
      sessionStorage.setItem('mixingErrorReason', reason);
    } catch (e) { console.warn("Error updating mixing state in storage:", e); }
    if (typeof window !== 'undefined') {
      window.__mixingFailed = true;
      window.__mixingFailedReason = reason;
      window.__mixingCompleted = true;
      window.__mixingInProgress = false;
      window.__mixingCompletionTime = Date.now();
    }
    this.publishEvent('mixing-failed', { reason, fallbackUrl, timestamp: Date.now() });
    this.saveToSessionStorage();
  }

  completeAudioMixing(audioUrl) {
    try {
      this.state.mixingInProgress = false;
      this.state.mixingCompleted = true;
      this.state.mixingFailed = false;
      this.state.mixingProgress = 100;
      this.state.lastUpdate = Date.now();
      this.state.urlPriority = 'mixed';
      this.state.urlLocked = true;
      this.state.urlLockTime = Date.now();
      this.setTimeout(() => { this.state.urlLocked = false; }, 2000);
      if (!audioUrl || !this.isValidUrl(audioUrl)) {
        console.error("Invalid URL in completeAudioMixing:", audioUrl);
        let fallbackUrl = null;
        if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) {
          fallbackUrl = this.state.ttsUrl;
          this.state.urlPriority = 'tts';
        } else if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) {
          fallbackUrl = this.state.fallbackAudioUrl;
          this.state.urlPriority = 'fallback';
        } else if (this.state.ttsKey) {
          fallbackUrl = this.getTtsUrl(this.state.ttsKey);
          this.state.urlPriority = 'tts';
        } else {
          fallbackUrl = this.getSilentAudioUrl();
          this.state.urlPriority = 'other';
        }
        if (!fallbackUrl || !this.isValidUrl(fallbackUrl)) {
          console.error("No valid fallback URL available");
          fallbackUrl = this.getSilentAudioUrl();
          this.state.urlPriority = 'other';
        }
        audioUrl = fallbackUrl;
      }
      try {
        sessionStorage.setItem('urlPriority', this.state.urlPriority);
        sessionStorage.setItem('urlLocked', 'true');
        sessionStorage.setItem('urlLockTime', this.state.urlLockTime.toString());
      } catch (e) { console.warn("Error storing URL priority:", e); }
      this.updateAllAudioState({
        mixedAudioUrl: audioUrl,
        currentAudioUrl: audioUrl,
        stableUrlReference: audioUrl
      });
      this.publishEvent('mixing-complete', { audioUrl, priority: this.state.urlPriority, timestamp: Date.now() });
      return true;
    } catch (error) {
      console.error("Error in completeAudioMixing:", error);
      try {
        const fallbackUrl = this.getSilentAudioUrl();
        this.updateAllAudioUrls(fallbackUrl);
        this.state.mixingInProgress = false;
        this.state.mixingCompleted = true;
        this.state.mixingFailed = true;
        this.state.mixingErrorReason = error.message;
        this.state.urlPriority = 'other';
        this.saveToSessionStorage();
        return false;
      } catch (recoveryError) {
        console.error("Error in recovery from completeAudioMixing:", recoveryError);
        return false;
      }
    }
  }

  updateAllAudioState(newState) {
    try {
      Object.keys(newState).forEach(key => {
        if (this.state.hasOwnProperty(key)) this.state[key] = newState[key];
      });
      if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        try {
          if (newState.currentAudioUrl) {
            sessionStorage.setItem('currentAudioUrl', newState.currentAudioUrl);
            window.__currentAudioUrl = newState.currentAudioUrl;
            if (this._audioElement) {
              this._audioElement.src = newState.currentAudioUrl;
              this._audioElement.load();
            }
          }
          if (newState.mixedAudioUrl) {
            sessionStorage.setItem('mixedAudioUrl', newState.mixedAudioUrl);
            window.__mixedAudioUrl = newState.mixedAudioUrl;
          }
          if (newState.stableUrlReference) {
            sessionStorage.setItem('stableTransitionUrl', newState.stableUrlReference);
            window.__stableTransitionUrl = newState.stableUrlReference;
          }
          if (newState.mixingCompleted !== undefined) {
            sessionStorage.setItem('mixingCompleted', newState.mixingCompleted.toString());
            window.__mixingCompleted = newState.mixingCompleted;
          }
          if (newState.mixingFailed !== undefined) {
            sessionStorage.setItem('mixingFailed', newState.mixingFailed.toString());
            window.__mixingFailed = newState.mixingFailed;
          }
          if (newState.mixingInProgress !== undefined) {
            sessionStorage.setItem('mixingInProgress', newState.mixingInProgress.toString());
            window.__mixingInProgress = newState.mixingInProgress;
          }
          if (newState.lastUpdate) window.__lastUrlUpdateTime = newState.lastUpdate;
        } catch (e) { console.warn("Error updating sessionStorage in updateAllAudioState:", e); }
      }
      if (newState.currentAudioUrl) this.publishEvent('audio-url-updated', { url: newState.currentAudioUrl });
      return true;
    } catch (error) {
      console.error("Error in updateAllAudioState:", error);
      return false;
    }
  }

  async prepareTransitionToListenStep(options = {}) {
    console.log("Preparing transition to ListenStep");
    try {
      this.state.inTransition = true;
      this.state.transitionStartTime = Date.now();
      this.state.transitionSource = options.source || 'unknown';
      this.state.transitionTarget = 'listen';
      this.state.transitionCompleted = false;
      try {
        sessionStorage.setItem('transitionInitiated', 'true');
        sessionStorage.setItem('transitionTime', Date.now().toString());
        sessionStorage.setItem('transitionSource', this.state.transitionSource);
        sessionStorage.setItem('transitionTarget', 'listen');
        sessionStorage.setItem('transitionCompleted', 'false');
      } catch (e) { console.warn("Error updating transition state in sessionStorage:", e); }
      let bestUrl;
      if (this.state.mixedAudioUrl && this.isValidUrl(this.state.mixedAudioUrl)) {
        console.log("Using mixedAudioUrl for transition");
        bestUrl = this.state.mixedAudioUrl;
      } else if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) {
        console.log("Using ttsUrl for transition");
        bestUrl = this.state.ttsUrl;
      } else if (this.state.ttsKey) {
        const generated = this.getTtsUrl(this.state.ttsKey);
        if (this.isValidUrl(generated)) {
          console.log("Using generated TTS URL for transition");
          bestUrl = generated;
        }
      } else if (this.state.currentAudioUrl && this.isValidUrl(this.state.currentAudioUrl)) {
        console.log("Using currentAudioUrl for transition");
        bestUrl = this.state.currentAudioUrl;
      } else if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) {
        console.log("Using fallbackAudioUrl for transition");
        bestUrl = this.state.fallbackAudioUrl;
      } else {
        console.warn("No valid URL found; using silent audio");
        bestUrl = this.getSilentAudioUrl();
      }
      let urlVerified = false;
      try {
        const headResp = await fetch(bestUrl, {
          method: 'GET',
          cache: 'no-cache',
          headers: { 'Accept': 'audio/mpeg, audio/*, */*' },
          mode: 'cors'
        }).catch(err => ({ ok: false, status: 0 }));
        urlVerified = headResp.ok;
        console.log(`URL verification: ${urlVerified ? 'Successful' : 'Failed'} - Status: ${headResp.status}`);
      } catch (verifyErr) { console.warn("Error verifying URL, continuing:", verifyErr); }
      this.state.stableUrlReference = bestUrl;
      this.updateAllAudioUrls(bestUrl);
      if (this._audioElement) {
        try {
          this._audioElement.src = bestUrl;
          this._audioElement.load();
          console.log("Preloaded audio for transition");
        } catch (preloadErr) { console.warn("Error preloading audio:", preloadErr); }
      }
      const preloadAudio = new Audio();
      preloadAudio.volume = 0;
      preloadAudio.src = bestUrl;
      preloadAudio.load();
      const safetyId = this.setTimeout(() => {
        if (this.state.inTransition && !this.state.transitionCompleted) {
          console.log("Transition safety timeout, forcing completion");
          this.completeTransition(bestUrl);
        }
      }, 5000);
      this.state.timeouts.push({ id: safetyId, createdAt: Date.now(), purpose: 'transition-safety' });
      this.publishEvent('transition-initiated', { source: this.state.transitionSource, target: 'listen', url: bestUrl, verified: urlVerified });
      return { success: true, url: bestUrl, isTransitioning: true, verified: urlVerified };
    } catch (error) {
      console.error("Error in prepareTransitionToListenStep:", error);
      let fallbackUrl;
      try {
        if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) fallbackUrl = this.state.ttsUrl;
        else if (this.state.ttsKey) fallbackUrl = this.getTtsUrl(this.state.ttsKey);
        else fallbackUrl = this.getSilentAudioUrl();
      } catch (fallbackErr) {
        console.error("Fallback URL retrieval failed:", fallbackErr);
        fallbackUrl = this.getSilentAudioUrl();
      }
      this.state.inTransition = true;
      this.state.transitionStartTime = Date.now();
      setTimeout(() => { this.completeTransition(fallbackUrl); }, 2000);
      return { success: false, url: fallbackUrl, error: error.message, isTransitioning: true };
    }
  }

  completeTransition(finalUrl) {
    this.state.inTransition = false;
    this.state.transitionCompleted = true;
    this.state.lastUpdate = Date.now();
    try {
      sessionStorage.setItem('transitionInitiated', 'false');
      sessionStorage.setItem('transitionCompleted', 'true');
      sessionStorage.setItem('transitionCompletionTime', Date.now().toString());
    } catch (e) { console.warn("Error updating transition completion in sessionStorage:", e); }
    if (finalUrl && this.isValidUrl(finalUrl) && this.state.currentAudioUrl !== finalUrl) {
      this.updateAllAudioUrls(finalUrl);
    }
    this.publishEvent('transition-completed', {
      source: this.state.transitionSource,
      target: this.state.transitionTarget,
      duration: this.state.transitionStartTime ? Date.now() - this.state.transitionStartTime : 0
    });
    if (typeof window !== 'undefined') {
      window.__transitionCompleted = true;
      window.__transitionCompletionTime = Date.now();
    }
  }

  getBestAudioUrl() {
    try {
      if (this.state.mixedAudioUrl && this.isValidUrl(this.state.mixedAudioUrl)) return this.state.mixedAudioUrl;
      const currentPriority = this.state.urlPriority || 'other';
      switch (currentPriority) {
        case 'tts':
          if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) return this.state.ttsUrl;
          break;
        case 'fallback':
          if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) return this.state.fallbackAudioUrl;
          break;
      }
      if (this.state.stableUrlReference && this.isValidUrl(this.state.stableUrlReference)) return this.state.stableUrlReference;
      if (this.state.currentAudioUrl && this.isValidUrl(this.state.currentAudioUrl)) return this.state.currentAudioUrl;
      if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) return this.state.ttsUrl;
      if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) return this.state.fallbackAudioUrl;
      if (this.state.ttsKey) return this.getTtsUrl(this.state.ttsKey);
      if (typeof window !== 'undefined') {
        const globals = ['__stableTransitionUrl', '__currentAudioUrl', '__mixedAudioUrl'];
        for (const key of globals) {
          if (window[key] && this.isValidUrl(window[key])) return window[key];
        }
      }
      return this.getSilentAudioUrl();
    } catch (error) {
      console.error("Error in getBestAudioUrl:", error);
      return this.getSilentAudioUrl();
    }
  }

  /* ===== Error Handling ===== */
  recordError(context, message) {
    this.state.errorCount++;
    this.state.debug.lastError = { time: Date.now(), context, message };
    if (this.state.errorCount > 5) {
      this.state.errorRecoveryMode = true;
      this.publishEvent('error-recovery-mode', { active: true });
    }
    this.saveToSessionStorage();
    if (typeof window !== 'undefined') {
      window.__errorCount = this.state.errorCount;
      window.__errorRecoveryMode = this.state.errorRecoveryMode;
      window.__lastError = { time: Date.now(), context, message };
    }
    return this.state.errorCount;
  }

  resetErrors() {
    this.state.errorCount = 0;
    this.state.errorRecoveryMode = false;
    this.state.debug.lastError = null;
    this.publishEvent('error-recovery-mode', { active: false });
    this.saveToSessionStorage();
    if (typeof window !== 'undefined') {
      window.__errorCount = 0;
      window.__errorRecoveryMode = false;
      window.__lastError = null;
    }
  }

  /* ===== Event Pub/Sub ===== */
  publishEvent(eventName, data) {
    if (typeof window === 'undefined') return;
    try {
      const event = new CustomEvent(eventName, { detail: { ...data, timestamp: Date.now(), source: 'GlobalAudioState' } });
      if (this.state.eventTarget) this.state.eventTarget.dispatchEvent(event);
      window.dispatchEvent(event);
    } catch (e) { console.warn(`Error publishing event ${eventName}:`, e); }
  }

  subscribe(eventName, callback) {
    if (typeof window === 'undefined') return () => {};
    const wrappedCallback = (event) => { if (typeof callback === 'function') callback(event.detail || event); };
    if (this.state.eventTarget) this.state.eventTarget.addEventListener(eventName, wrappedCallback);
    window.addEventListener(eventName, wrappedCallback);
    const listenerId = Date.now() + Math.random().toString(36).substring(2, 15);
    this.state.activeListeners.push({ id: listenerId, eventName, callback: wrappedCallback });
    return () => {
      if (this.state.eventTarget) this.state.eventTarget.removeEventListener(eventName, wrappedCallback);
      window.removeEventListener(eventName, wrappedCallback);
      this.state.activeListeners = this.state.activeListeners.filter(l => l.id !== listenerId);
    };
  }

  resetAllState() {
    this.clearAllTimeouts();
    if (this.state.blobUrls.length > 0) {
      this.state.blobUrls.forEach(blobUrl => {
        try { URL.revokeObjectURL(blobUrl.url); } catch (e) { console.warn("Error revoking blob URL:", e); }
      });
      this.state.blobUrls = [];
    }
    this.state = {
      ttsKey: null,
      ttsProgress: 0,
      ttsProcessing: false,
      ttsUrl: null,
      mixedAudioUrl: null,
      currentAudioUrl: null,
      fallbackAudioUrl: null,
      stableUrlReference: null,
      urlPriority: null,
      urlLocked: false,
      urlLockTime: null,
      urlPriorityOrder: ['mixed', 'tts', 'fallback', 'other'],
      mixingInProgress: false,
      mixingStartTime: null,
      mixingProgress: 0,
      mixingCompleted: false,
      mixingFailed: false,
      mixingErrorReason: null,
      inTransition: false,
      transitionStartTime: null,
      transitionCompleted: false,
      transitionSource: null,
      transitionTarget: null,
      audioInitialized: false,
      audioLoaded: false,
      audioPlaying: false,
      errorRecoveryMode: false,
      errorCount: 0,
      initialized: true,
      lastUpdate: Date.now(),
      eventTarget: (typeof window !== 'undefined') ? new EventTarget() : null,
      silentAudioCreated: false,
      silentAudioUrl: null,
      blobUrls: [],
      timeouts: [],
      activeListeners: [],
      debug: { lastError: null, stateHistory: [], initializeAttempts: 0, extractionAttempts: [] }
    };
    if (this._audioElement) {
      try {
        this._audioElement.pause();
        this._audioElement.src = '';
        this._audioElement.load();
      } catch (e) { console.warn("Error cleaning up audio element:", e); }
      this._audioElement = null;
    }
    this.ensureSilentAudioUrl();
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
      const keys = [
        'ttsKey', 'ttsAudioUrl', 'ttsProgress', 'ttsProcessing',
        'mixedAudioUrl', 'currentAudioUrl', 'fallbackAudioUrl', 'stableTransitionUrl',
        'mixingCompleted', 'mixingFailed', 'mixingInProgress', 'mixingProgress',
        'transitionInitiated', 'transitionCompleted', 'transitionTime',
        'audioInitialized', 'audioLoaded', 'audioPlaying',
        'errorRecoveryMode', 'errorCount',
        'urlPriority', 'urlLocked', 'urlLockTime'
      ];
      keys.forEach(key => {
        try { sessionStorage.removeItem(key); } catch (e) { console.warn(`Error removing ${key} from sessionStorage:`, e); }
      });
    }
    if (typeof window !== 'undefined') {
      const globals = [
        '__ttsKey', '__ttsUrl', '__ttsProgress', '__ttsProcessing',
        '__mixedAudioUrl', '__currentAudioUrl', '__stableTransitionUrl',
        '__mixingInProgress', '__mixingCompleted', '__mixingFailed',
        '__transitionInitiated', '__transitionCompleted',
        '__errorCount', '__errorRecoveryMode', '__lastError',
        '__urlPriority', '__urlLocked', '__urlLockTime'
      ];
      globals.forEach(key => {
        try { delete window[key]; } catch (e) { console.warn(`Error deleting ${key} from window:`, e); }
      });
    }
    this.publishEvent('audio-state-reset', {});
  }
}

const globalAudioState = new GlobalAudioState();
export default globalAudioState;
