// audio-sync-fix.js
// Comprehensive audio synchronization utilities to fix playback and transition issues



/**
 * Initializes global audio state if it doesn't exist
 * Creates a shared state for audio components to reference
 */
export function initializeGlobalAudioState() {
  if (typeof window === 'undefined') return null;
  
  if (!window.globalAudioState) {
    window.globalAudioState = {
      // Shared audio instances
      _audioElement: null,
      _audioContext: null,
      _analyzer: null,
      _waveSurfer: null,
      
      // State flags
      isTransitioning: false,
      audioPlaying: false,
      fullscreenActive: false,
      
      // URLs and sources
      currentAudioUrl: '',
      fallbackAudioUrl: '',
      mixedAudioUrl: '',
      
      // State tracking
      state: {
        currentTime: 0,
        duration: 0,
        volume: 1,
        muted: false,
        audioReady: false,
        lastUpdate: Date.now()
      },
      
      // Event subscribers
      subscribers: {},
      
      // Methods
      publishEvent: function(eventName, data) {
        if (!this.subscribers[eventName]) return;
        
        this.subscribers[eventName].forEach(callback => {
          try {
            callback(data);
          } catch (err) {
            console.warn(`Error in event subscriber for ${eventName}:`, err);
          }
        });
      },
      
      subscribe: function(eventName, callback) {
        if (!this.subscribers[eventName]) {
          this.subscribers[eventName] = [];
        }
        
        this.subscribers[eventName].push(callback);
        
        // Return unsubscribe function
        return () => {
          this.subscribers[eventName] = this.subscribers[eventName].filter(cb => cb !== callback);
        };
      },
      
      getBestAudioUrl: function() {
        return this.mixedAudioUrl || this.currentAudioUrl || this.fallbackAudioUrl || '';
      },
      
      resetAllState: function() {
        this.audioPlaying = false;
        this.isTransitioning = false;
        this.fullscreenActive = false;
        this.currentAudioUrl = '';
        this.fallbackAudioUrl = '';
        this.mixedAudioUrl = '';
        
        this.state = {
          currentTime: 0,
          duration: 0,
          volume: 1,
          muted: false,
          audioReady: false,
          lastUpdate: Date.now()
        };
        
        // Don't clear subscribers
        this.publishEvent('state-reset', { timestamp: Date.now() });
      }
    };
  }
  
  return window.globalAudioState;
}

/**
 * Synchronizes playback state between audio element and WaveSurfer
 * Ensures both are playing or paused together
 */
export function syncPlayback({ isPlaying, audioElement, waveSurfer, globalAudioState }) {
  if (!audioElement && !waveSurfer) {
    console.warn("syncPlayback: No audio sources provided");
    return false;
  }

  try {
    // Flag to track if we're handling media
    let mediaHandled = false;
    
    // Ensure globalAudioState is defined
    if (!globalAudioState) {
      globalAudioState = window.globalAudioState || initializeGlobalAudioState();
    }

    // Update global state and publish events
    globalAudioState.audioPlaying = isPlaying;
    globalAudioState.publishEvent(isPlaying ? 'play' : 'pause', {
      source: 'syncPlayback',
      timestamp: Date.now()
    });

    // Handle WaveSurfer if available and not destroyed
    if (waveSurfer && !waveSurfer._destroyed) {
      try {
        const wsPlaying = waveSurfer.isPlaying();
        
        // First make sure WaveSurfer and the media element are disconnected
        // to prevent doubled audio
        if (waveSurfer.backend && waveSurfer.backend.media) {
          // Store current state
          const currentTime = waveSurfer.getCurrentTime();
          
          // Disconnect media element from WaveSurfer to prevent it from controlling playback
          if (isPlaying !== wsPlaying) {
            if (waveSurfer.backend.disconnectSource) {
              try {
                // Some WaveSurfer versions support disconnectSource
                waveSurfer.backend.disconnectSource();
              } catch (disconnectErr) {
                console.warn("Error disconnecting source:", disconnectErr);
              }
            }
          }
          
          // Update WaveSurfer's state
          if (isPlaying && !wsPlaying) {
            waveSurfer.play();
            mediaHandled = true;
          } else if (!isPlaying && wsPlaying) {
            waveSurfer.pause();
            mediaHandled = true;
          }
        } else {
          // Standard WaveSurfer playback without media element
          if (isPlaying && !wsPlaying) {
            waveSurfer.play();
          } else if (!isPlaying && wsPlaying) {
            waveSurfer.pause();
          }
        }
      } catch (wsErr) {
        console.warn("syncPlayback: WaveSurfer error:", wsErr);
      }
    }

    // Handle audio element if available and not already handled by WaveSurfer
    if (audioElement && !mediaHandled) {
      try {
        if (isPlaying && audioElement.paused) {
          const playPromise = audioElement.play();
          if (playPromise !== undefined) {
            playPromise.catch((err) => {
              console.warn("syncPlayback: Audio play error:", err);
              
              // If user interaction is required, add one-time listeners
              if (err.name === 'NotAllowedError') {
                const handleInteraction = () => {
                  audioElement.play().catch(e => console.warn("syncPlayback: Second play attempt failed:", e));
                  document.removeEventListener('click', handleInteraction);
                  document.removeEventListener('touchstart', handleInteraction);
                  document.removeEventListener('keydown', handleInteraction);
                };
                document.addEventListener('click', handleInteraction, { once: true });
                document.addEventListener('touchstart', handleInteraction, { once: true });
                document.addEventListener('keydown', handleInteraction, { once: true });
              }
            });
          }
        } else if (!isPlaying && !audioElement.paused) {
          audioElement.pause();
        }
      } catch (audioErr) {
        console.warn("syncPlayback: Audio element error:", audioErr);
      }
    }

    return true;
  } catch (error) {
    console.error("syncPlayback: Unexpected error:", error);
    return false;
  }
}

/**
 * Synchronizes time position between audio element and WaveSurfer
 * Ensures both are at the same position
 */
export function syncTimePosition({ currentTime, audioElement, waveSurfer }) {
  try {
    // Handle WaveSurfer
    if (waveSurfer && !waveSurfer._destroyed) {
      try {
        const duration = waveSurfer.getDuration() || 0;
        
        if (duration > 0) {
          waveSurfer.seekTo(currentTime / duration);
        }
      } catch (err) {
        console.warn("syncTimePosition: Error with WaveSurfer:", err);
      }
    }
    
    // Handle Audio Element
    if (audioElement) {
      try {
        audioElement.currentTime = currentTime;
      } catch (err) {
        console.warn("syncTimePosition: Error with audio element:", err);
      }
    }
    
    // Update global state
    if (window.globalAudioState) {
      window.globalAudioState.state.currentTime = currentTime;
      window.globalAudioState.state.lastUpdate = Date.now();
      window.globalAudioState.publishEvent('timeupdate', { 
        currentTime,
        source: 'syncTimePosition',
        timestamp: Date.now()
      });
    }
    
    return true;
  } catch (error) {
    console.error("syncTimePosition: Unexpected error:", error);
    return false;
  }
}

/**
 * Safely destroys a WaveSurfer instance to prevent memory leaks
 */
export function safeDestroyWaveSurfer(waveSurferInstance) {
  if (!waveSurferInstance) return false;
  
  try {
    // Mark as being destroyed to prevent concurrent operations
    waveSurferInstance._isBeingDestroyed = true;
    
    // Clean up event listeners first
    if (typeof waveSurferInstance._cleanupHandlers === 'function') {
      waveSurferInstance._cleanupHandlers();
    }
    
    // Cancel any pending operations
    if (waveSurferInstance._abortController && typeof waveSurferInstance._abortController.abort === 'function') {
      waveSurferInstance._abortController.abort();
    }
    
    // Mark container as having an instance being destroyed
    if (waveSurferInstance.container) {
      waveSurferInstance.container._wavesurferIsBeingDestroyed = true;
    }
    
    // Prepare for graceful destruction
    const destroyPromise = new Promise((resolve) => {
      // Safely destroy
      if (typeof waveSurferInstance.destroy === 'function' && !waveSurferInstance._destroyed) {
        // Add a small delay to ensure any pending operations complete
        setTimeout(() => {
          try {
            // Unregister all event handlers first
            if (typeof waveSurferInstance.unAll === 'function') {
              waveSurferInstance.unAll();
            }
            
            // Actually destroy the instance
            waveSurferInstance.destroy();
            
            // Update flags
            waveSurferInstance._destroyed = true;
            waveSurferInstance._isBeingDestroyed = false;
            
            // Clear from container if it was stored there
            if (waveSurferInstance.container) {
              waveSurferInstance.container._wavesurfer = null;
              waveSurferInstance.container._wavesurferIsBeingDestroyed = false;
            }
            
            // Clear from global state if it matches
            if (window.globalAudioState && window.globalAudioState._waveSurfer === waveSurferInstance) {
              window.globalAudioState._waveSurfer = null;
            }
            
            resolve(true);
          } catch (err) {
            console.warn("Error during delayed WaveSurfer destruction:", err);
            
            // Force cleanup even in case of errors
            waveSurferInstance._destroyed = true;
            waveSurferInstance._isBeingDestroyed = false;
            
            if (waveSurferInstance.container) {
              waveSurferInstance.container._wavesurfer = null;
              waveSurferInstance.container._wavesurferIsBeingDestroyed = false;
            }
            
            resolve(false);
          }
        }, 100);
      } else {
        // Already destroyed or no destroy method
        waveSurferInstance._destroyed = true;
        waveSurferInstance._isBeingDestroyed = false;
        
        if (waveSurferInstance.container) {
          waveSurferInstance.container._wavesurferIsBeingDestroyed = false;
        }
        
        resolve(true);
      }
    });
    
    // Add a timeout to ensure cleanup even if destroy hangs
    setTimeout(() => {
      if (waveSurferInstance._isBeingDestroyed) {
        console.warn("Forced cleanup of WaveSurfer due to timeout");
        waveSurferInstance._destroyed = true;
        waveSurferInstance._isBeingDestroyed = false;
        
        if (waveSurferInstance.container) {
          waveSurferInstance.container._wavesurferIsBeingDestroyed = false;
        }
      }
    }, 5000);
    
    return true;
  } catch (error) {
    console.error("safeDestroyWaveSurfer: Error destroying WaveSurfer:", error);
    
    // Clean up even in case of errors
    if (waveSurferInstance) {
      waveSurferInstance._destroyed = true;
      waveSurferInstance._isBeingDestroyed = false;
      
      if (waveSurferInstance.container) {
        waveSurferInstance.container._wavesurferIsBeingDestroyed = false;
      }
    }
    
    return false;
  }
}

/**
 * Safely creates a WaveSurfer instance with proper error handling
 */
export function safeCreateWaveSurfer({
  container,
  WaveSurfer,
  audioElement,
  isMobile = false,
  waveColor = 'rgba(255, 255, 255, 0.6)',
  progressColor = '#7d12ff',
  plugins = [],
  minPxPerSec = 100,
  scrollParent = true,
  fillParent = false,
  autoScroll = true,
  autoCenter = true,
  hideScrollbar = false,
  normalize = true,
  onReady = () => {},
  onError = () => {}
}) {
  if (!container || !WaveSurfer) {
    console.warn("safeCreateWaveSurfer: Missing required parameters");
    return null;
  }

  try {
    // Add a flag to indicate this instance is being created
    container._wavesurferInitializing = true;
    
    // Reuse existing instance if available and not in the process of being destroyed
    if (container._wavesurfer && 
        !container._wavesurfer._destroyed && 
        !container._wavesurfer._isBeingDestroyed && 
        typeof container._wavesurfer.destroy === 'function') {
      console.log("Using existing WaveSurfer instance from container");
      container._wavesurferInitializing = false;
      return container._wavesurfer;
    }

    // Extra safety check - if a previous instance is being destroyed, wait
    if (container._wavesurferIsBeingDestroyed) {
      console.warn("Cannot create WaveSurfer - previous instance is still being destroyed");
      container._wavesurferInitializing = false;
      return null;
    }

    // Ensure container has proper dimensions and visibility
    container.style.display = 'block';
    container.style.visibility = 'visible';
    container.style.width = '100%';
    container.style.minWidth = '300px';
    container.style.height = isMobile ? '60px' : '80px';
    container.style.minHeight = isMobile ? '60px' : '80px';
    void container.offsetHeight;
    void container.offsetWidth;

    // Configure WaveSurfer options with better defaults
    const wsOptions = {
      container,
      waveColor,
      progressColor,
      responsive: true,
      height: isMobile ? 60 : 80,
      barWidth: isMobile ? 2 : 3,
      barGap: isMobile ? 1 : 2,
      barRadius: 2,
      cursorWidth: 2,
      cursorColor: progressColor,
      backend: audioElement ? 'MediaElement' : 'WebAudio',
      plugins: plugins || [],
      // Scrolling options
      minPxPerSec: minPxPerSec,
      scrollParent: scrollParent,
      fillParent: fillParent,
      autoScroll: autoScroll,
      autoCenter: autoCenter,
      hideScrollbar: hideScrollbar,
      normalize: normalize,
      // XHR options for better compatibility
      xhr: {
        cache: 'default',
        mode: 'cors',
        method: 'GET',
        credentials: 'same-origin',
        redirect: 'follow',
        timeout: 30000, // Longer timeout (30 seconds)
        headers: [
          { key: 'Cache-Control', value: 'no-cache' }
        ]
      }
    };

    if (audioElement) {
      wsOptions.media = audioElement;
    }

    // Create the WaveSurfer instance
    const wsInstance = WaveSurfer.create(wsOptions);
    wsInstance._destroyed = false;
    wsInstance._isBeingDestroyed = false;
    wsInstance._isInitialized = false;
    wsInstance._instanceId = `wavesurfer-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    
    // Store in container
    container._wavesurfer = wsInstance;
    container._wavesurferInitializing = false;
    
    if (window.globalAudioState) {
      window.globalAudioState._waveSurfer = wsInstance;
    }

    // Set up event handlers with better cleanup
    const readyHandler = () => {
      console.log("WaveSurfer is ready");
      wsInstance._isReady = true;
      wsInstance._isInitialized = true;
      onReady(wsInstance);
      if (window.globalAudioState) {
        window.globalAudioState.state.audioReady = true;
        window.globalAudioState.state.duration = wsInstance.getDuration() || 0;
        window.globalAudioState.publishEvent('wavesurfer-ready', { timestamp: Date.now() });
      }
    };
    
    const errorHandler = (err) => {
      // Don't treat abort errors as real errors
      if (err.name === 'AbortError' || (err.message && err.message.includes('abort'))) {
        console.warn("WaveSurfer load aborted (expected during rapid changes):", err.message);
        return;
      }
      
      console.error("WaveSurfer error:", err);
      onError(err);
      
      if (window.globalAudioState) {
        window.globalAudioState.publishEvent('wavesurfer-error', { error: err, timestamp: Date.now() });
      }
    };
    
    wsInstance.on('ready', readyHandler);
    wsInstance.on('error', errorHandler);

    // Add cleanup method to the instance
    wsInstance._cleanupHandlers = () => {
      wsInstance.un('ready', readyHandler);
      wsInstance.un('error', errorHandler);
    };

    return wsInstance;
  } catch (error) {
    console.error("safeCreateWaveSurfer: Error creating WaveSurfer:", error);
    if (container) container._wavesurferInitializing = false;
    onError(error);
    return null;
  }
}

export function initializeAudio({
  audioUrl,
  globalAudioState,
  audioElementRef,
  waveSurferRef,
  setAudioElement,
  createFallbackElement,
  onAudioReady
}) {
  try {
    // Initialize or retrieve global state
    if (!globalAudioState) {
      globalAudioState = window.globalAudioState || initializeGlobalAudioState();
    }

    // Use existing audio element from global state if available
    if (globalAudioState && globalAudioState._audioElement) {
      console.log("Using audio element from global state");
      const globalAudio = globalAudioState._audioElement;
      
      // Only update the URL if it's different and valid
      if (audioUrl && globalAudio.src !== audioUrl && isValidUrl(audioUrl)) {
        console.log("Updating audio URL in existing element:", audioUrl);
        globalAudio.src = audioUrl;
        globalAudio.load();
      }
      
      if (setAudioElement) {
        setAudioElement(globalAudio);
      } else if (audioElementRef) {
        audioElementRef.current = globalAudio;
      }
      
      // Make sure we have necessary event listeners
      if (!globalAudio._listenersInitialized) {
        setupAudioElementListeners(globalAudio, globalAudioState, onAudioReady);
      }
      
      return { success: true, audioElement: globalAudio };
    }

    // If already created locally, update the URL if necessary
    if (audioElementRef && audioElementRef.current) {
      const existingAudio = audioElementRef.current;
      if (audioUrl && existingAudio.src !== audioUrl && isValidUrl(audioUrl)) {
        existingAudio.src = audioUrl;
        existingAudio.load();
      }
      
      // Set in global state if not already set
      if (globalAudioState && !globalAudioState._audioElement) {
        globalAudioState._audioElement = existingAudio;
      }
      
      // Make sure we have necessary event listeners
      if (!existingAudio._listenersInitialized) {
        setupAudioElementListeners(existingAudio, globalAudioState, onAudioReady);
      }
      
      return { success: true, audioElement: existingAudio };
    }

    // Create a new audio element using a fallback function if provided
    console.log("Creating new audio element with URL:", audioUrl);
    let newAudio;
    
    if (createFallbackElement && typeof createFallbackElement === 'function') {
      newAudio = createFallbackElement(audioUrl);
    } else {
      newAudio = new Audio();
      newAudio.crossOrigin = "anonymous";
      newAudio.preload = "auto";
      
      // Set up proper event listeners
      setupAudioElementListeners(newAudio, globalAudioState, onAudioReady);
      
      // Set source after adding listeners to catch loading events
      if (audioUrl && isValidUrl(audioUrl)) {
        newAudio.src = audioUrl;
        newAudio.load();
      }
    }

    if (setAudioElement) {
      setAudioElement(newAudio);
    } else if (audioElementRef) {
      audioElementRef.current = newAudio;
    }
    
    if (globalAudioState) {
      globalAudioState._audioElement = newAudio;
      globalAudioState.currentAudioUrl = audioUrl || '';
    }

    // Optionally store the URL for recovery in session storage
    try {
      if (audioUrl && typeof sessionStorage !== 'undefined' && isValidUrl(audioUrl)) {
        sessionStorage.setItem('currentAudioUrl', audioUrl);
      }
    } catch (err) {
      console.warn("Error storing URL in session storage:", err);
    }

    return { success: true, audioElement: newAudio };
  } catch (error) {
    console.error("initializeAudio: Error initializing audio:", error);
    return { success: false, error };
  }
}

// Helper function to set up consistent audio element listeners
function setupAudioElementListeners(audioElement, globalAudioState, onAudioReady) {
  if (audioElement._listenersInitialized) return;
  
  audioElement.addEventListener('loadedmetadata', () => {
    console.log("Audio metadata loaded, duration:", audioElement.duration);
    
    if (globalAudioState) {
      globalAudioState.state.duration = audioElement.duration || 0;
      globalAudioState.state.audioReady = true;
      globalAudioState.publishEvent('audio-ready', { 
        duration: audioElement.duration, 
        timestamp: Date.now() 
      });
    }
    
    if (onAudioReady) {
      onAudioReady({ duration: audioElement.duration });
    }
  });
  
  audioElement.addEventListener('timeupdate', () => {
    if (globalAudioState) {
      globalAudioState.state.currentTime = audioElement.currentTime;
      globalAudioState.publishEvent('timeupdate', { 
        currentTime: audioElement.currentTime, 
        timestamp: Date.now() 
      });
    }
  });
  
  audioElement.addEventListener('play', () => {
    console.log("Audio element play event");
    if (globalAudioState) {
      globalAudioState.audioPlaying = true;
      globalAudioState.publishEvent('play', { timestamp: Date.now() });
    }
  });
  
  audioElement.addEventListener('pause', () => {
    console.log("Audio element pause event");
    if (globalAudioState) {
      globalAudioState.audioPlaying = false;
      globalAudioState.publishEvent('pause', { timestamp: Date.now() });
    }
  });
  
  audioElement.addEventListener('ended', () => {
    console.log("Audio playback ended");
    if (globalAudioState) {
      globalAudioState.audioPlaying = false;
      globalAudioState.publishEvent('ended', { timestamp: Date.now() });
    }
  });
  
  audioElement.addEventListener('error', (error) => {
    console.error("Audio element error:", error);
    if (globalAudioState) {
      globalAudioState.publishEvent('error', { 
        error: error, 
        timestamp: Date.now() 
      });
    }
  });
  
  audioElement._listenersInitialized = true;
}

// Helper function to validate URLs
function isValidUrl(url) {
  if (!url || typeof url !== 'string') return false;
  
  return url.startsWith('http://') || 
         url.startsWith('https://') || 
         url.startsWith('blob:') || 
         url.startsWith('data:');
}


/**
 * Prepares for fullscreen transition to ensure audio continuity
 */
export function prepareFullscreenTransition({
  direction = 'enter', // 'enter' or 'exit'
  audioElement,
  waveSurfer,
  currentUrl,
  isPlaying = false,
  currentTime = 0,
  duration = 0
}) {
  try {
    // Get global state or initialize it
    const globalState = window.globalAudioState || initializeGlobalAudioState();
    
    // Set transition flag
    globalState.isTransitioning = true;
    
    // Store current state
    const audioPlaying = isPlaying || (audioElement ? !audioElement.paused : false);
    const audioTime = currentTime || (audioElement ? audioElement.currentTime : 0);
    const audioDuration = duration || (audioElement ? audioElement.duration : 0);
    const volume = audioElement ? audioElement.volume : 1;
    const muted = audioElement ? audioElement.muted : false;
    
    // Update state
    globalState.state.currentTime = audioTime;
    globalState.state.duration = audioDuration;
    globalState.state.volume = volume;
    globalState.state.muted = muted;
    globalState.audioPlaying = audioPlaying;
    globalState.fullscreenActive = direction === 'enter';
    
    // Update window state variables for transition
    if (typeof window !== 'undefined') {
      window.__fsTransitionTime = audioTime;
      window.__fsTransitionPlaying = audioPlaying;
      window.__fsTransitionMuted = muted;
      window.__fsTransitionVolume = volume;
    }
    
    // Store URL
    if (currentUrl) {
      globalState.currentAudioUrl = currentUrl;
      window.__stableTransitionUrl = currentUrl;
    }
    
    // Store audio element for reuse
    if (audioElement) {
      globalState._audioElement = audioElement;
      window.__transitionAudioElement = audioElement;
      
      // Ensure it has the correct time position
      try {
        audioElement.currentTime = audioTime;
      } catch(e) {
        console.warn("Couldn't set time on transition:", e);
      }
    }
    
    // Publish event
    globalState.publishEvent('transition-prepare', {
      direction,
      currentTime: audioTime,
      isPlaying: audioPlaying,
      volume,
      muted,
      url: currentUrl,
      timestamp: Date.now()
    });
    
    return {
      success: true,
      state: {
        direction,
        currentTime: audioTime,
        isPlaying: audioPlaying,
        volume,
        muted,
        url: currentUrl
      }
    };
  } catch (error) {
    console.error("prepareFullscreenTransition: Error:", error);
    return { success: false, error };
  }
}

/**
 * Checks if we're in a transition state
 */
export function checkTransitionState() {
  try {
    // Check global state
    if (window.globalAudioState && window.globalAudioState.isTransitioning) {
      return true;
    }
    
    // Check transition audio element
    if (window.__transitionAudioElement) {
      return true;
    }
    
    // Check transition URL
    if (window.__stableTransitionUrl) {
      return true;
    }
    
    return false;
  } catch (error) {
    console.error("checkTransitionState: Error:", error);
    return false;
  }
}

/**
 * Creates a visualization processor for audio analysis
 */
export function createVisualizationProcessor(analyzerNode) {
  if (!analyzerNode) {
    console.warn("createVisualizationProcessor: No analyzer node provided");
    
    // Return a simulation processor
    return {
      getAudioLevels: ({ sensitivity = 1.5, voiceFocus = true, isPlaying = false }) => {
        const time = Date.now() / 1000;
        
        // Simulate audio levels with sin waves
        const bass = isPlaying ? 50 + Math.sin(time * 0.6) * 45 : 30 + Math.sin(time * 0.3) * 20;
        const mid = isPlaying ? 60 + Math.sin(time * 0.8 + 0.5) * 50 : 25 + Math.sin(time * 0.4 + 0.3) * 15;
        const treble = isPlaying ? 55 + Math.sin(time * 0.7 + 0.9) * 40 : 20 + Math.sin(time * 0.35 + 0.7) * 15;
        
        return {
          bass,
          mid,
          treble,
          isSimulated: true
        };
      }
    };
  }
  
  let dataArray;
  
  return {
    getAudioLevels: ({ sensitivity = 1.5, voiceFocus = true }) => {
      try {
        // Initialize data array if needed
        if (!dataArray) {
          try {
            dataArray = new Uint8Array(analyzerNode.frequencyBinCount || 1024);
          } catch (err) {
            console.warn("Error creating data array:", err);
            dataArray = new Uint8Array(1024);
          }
        }
        
        // Get frequency data
        try {
          analyzerNode.getByteFrequencyData(dataArray);
        } catch (err) {
          console.warn("Error getting frequency data:", err);
          return null;
        }
        
        // Process frequency bands
        let bassSum = 0, bassCount = 0;
        for (let i = 1; i < 15 && i < dataArray.length; i++) {
          bassSum += dataArray[i];
          bassCount++;
        }
        
        let midSum = 0, midCount = 0;
        for (let i = 15; i < 80 && i < dataArray.length; i++) {
          midSum += dataArray[i];
          midCount++;
        }
        
        let trebleSum = 0, trebleCount = 0;
        for (let i = 80; i < 200 && i < dataArray.length; i++) {
          trebleSum += dataArray[i];
          trebleCount++;
        }
        
        // Calculate averages
        const bass = bassCount ? bassSum / bassCount : 0;
        const mid = midCount ? midSum / midCount : 0;
        const treble = trebleCount ? trebleSum / trebleCount : 0;
        
        // Apply voice focus if requested
        if (voiceFocus) {
          return {
            bass: bass * 0.8,
            mid: mid * 1.2,
            treble: treble * 0.7,
            isSimulated: false
          };
        }
        
        return {
          bass,
          mid,
          treble,
          isSimulated: false
        };
      } catch (error) {
        console.error("getAudioLevels: Error processing audio:", error);
        
        // Return fallback values
        return {
          bass: 50,
          mid: 60,
          treble: 55,
          isSimulated: true
        };
      }
    }
  };
}

// Global registry to track connections and prevent duplicates
if (typeof window !== 'undefined' && !window._audioConnectionRegistry) {
  window._audioConnectionRegistry = {
    connectedElements: new WeakMap(),
    activeAnalyzers: [],
    lastError: null,
    connectionAttempts: 0
  };
}

/**
 * Creates a robust analyzer node with fallback options
 * @param {Object} options Configuration options
 * @returns {AudioAnalyser|Object} An analyzer node or fallback object
 */
export function createRobustAnalyzer(options = {}) {
  const {
    audioElement = null,
    fallbackOnly = false,
    useGlobalAudioState = true,
    sensitivity = 1.5,
    onReady = null
  } = options;

  // Log the attempt
  console.log("Creating robust analyzer for audio visualization");
  
  // Use provided audio element or get from global state
  const targetAudioElement = audioElement || 
                           (window.globalAudioState && window.globalAudioState._audioElement);
  
  if (!targetAudioElement) {
    console.warn("No audio element available for analyzer");
    return createFallbackAnalyzer(sensitivity);
  }
  
  // Check for existing global analyzer
  if (useGlobalAudioState && !fallbackOnly) {
    if (window.globalAudioState && window.globalAudioState._analyzer) {
      try {
        const existingAnalyzer = window.globalAudioState._analyzer;
        if (typeof existingAnalyzer.getByteFrequencyData === 'function') {
          console.log("Using existing global analyzer");
          if (onReady) onReady(existingAnalyzer);
          return existingAnalyzer;
        }
      } catch (err) {
        console.warn("Existing global analyzer check failed:", err);
      }
    }
  }
  
  // Don't create a real analyzer if fallback is requested
  if (fallbackOnly) {
    return createFallbackAnalyzer(sensitivity);
  }
  
  try {
    // Check for AudioContext support
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      console.warn("AudioContext not supported in this browser");
      return createFallbackAnalyzer(sensitivity);
    }
    
    // Try to reuse existing AudioContext
    let audioCtx;
    
    if (window.globalAudioState && window.globalAudioState._audioContext) {
      audioCtx = window.globalAudioState._audioContext;
      if (audioCtx.state === 'closed') {
        console.log("Existing AudioContext is closed, creating new one");
        audioCtx = new AudioContext();
        window.globalAudioState._audioContext = audioCtx;
      } else {
        console.log("Using existing AudioContext from global state");
      }
    } else {
      audioCtx = new AudioContext();
      console.log("Created new AudioContext");
      
      // Store in global state if available
      if (window.globalAudioState) {
        window.globalAudioState._audioContext = audioCtx;
      }
    }
    
    // Create analyzer node
    const analyzer = audioCtx.createAnalyser();
    analyzer.fftSize = 2048;
    analyzer.smoothingTimeConstant = 0.85;
    
    // Pre-create data arrays
    analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
    analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
    
    // Track connection attempt
    window._audioConnectionRegistry.connectionAttempts++;
    
    // Connect to audio element
    try {
      // Check if already connected
      let source;
      if (window._audioConnectionRegistry.connectedElements.has(targetAudioElement)) {
        source = window._audioConnectionRegistry.connectedElements.get(targetAudioElement);
        console.log("Using existing MediaElementSource connection");
      } else {
        // Create new connection
        source = audioCtx.createMediaElementSource(targetAudioElement);
        window._audioConnectionRegistry.connectedElements.set(targetAudioElement, source);
        console.log("Created new MediaElementSource connection");
      }
      
      // Connect analyzer
      source.connect(analyzer);
      analyzer.connect(audioCtx.destination);
      
      // Make sure audio still plays through
      source.connect(audioCtx.destination);
      
      // Store in registry
      window._audioConnectionRegistry.activeAnalyzers.push(analyzer);
      
      // Store in global state
      if (window.globalAudioState) {
        window.globalAudioState._analyzer = analyzer;
      }
      
      // Resume context if needed
      if (audioCtx.state === 'suspended') {
        audioCtx.resume().catch(err => console.warn("Error resuming AudioContext:", err));
      }
      
      // Add cleanup method
      analyzer._cleanup = () => {
        try {
          analyzer.disconnect();
          window._audioConnectionRegistry.activeAnalyzers = 
            window._audioConnectionRegistry.activeAnalyzers.filter(a => a !== analyzer);
        } catch (e) {
          // Ignore cleanup errors
        }
      };
      
      console.log("Successfully created and connected analyzer");
      if (onReady) onReady(analyzer);
      return analyzer;
    } catch (err) {
      // Handle "already connected" errors
      console.warn("Error connecting analyzer:", err);
      window._audioConnectionRegistry.lastError = {
        time: Date.now(),
        message: err.message,
        context: 'connection'
      };
      
      // If the error is because it's already connected, try a different approach
      if (err.name === 'InvalidAccessError' || 
          (err.message && err.message.includes('already connected'))) {
        return createStandaloneAnalyzer(audioCtx, sensitivity);
      }
      
      // Other errors, fall back to simulated analyzer
      return createFallbackAnalyzer(sensitivity);
    }
  } catch (err) {
    console.error("Error creating analyzer:", err);
    window._audioConnectionRegistry.lastError = {
      time: Date.now(),
      message: err.message,
      context: 'creation'
    };
    return createFallbackAnalyzer(sensitivity);
  }
}

/**
 * Creates a standalone analyzer that doesn't connect to the audio element
 * but still produces visualization data
 */
function createStandaloneAnalyzer(audioContext, sensitivity = 1.5) {
  console.log("Creating standalone analyzer (oscillator-based)");
  
  try {
    // Create analyzer
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 2048;
    analyzer.smoothingTimeConstant = 0.85;
    
    // Create silent oscillator as source
    const oscillator = audioContext.createOscillator();
    oscillator.frequency.value = 0; // Silent
    
    // Create gain node to control volume
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 0; // Silent
    
    // Connect oscillator -> gain -> analyzer
    oscillator.connect(gainNode);
    gainNode.connect(analyzer);
    analyzer.connect(audioContext.destination);
    
    // Start oscillator
    oscillator.start();
    
    // Pre-create data arrays
    analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
    analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
    
    // Add simulation capability
    addFallbackDataGeneration(analyzer, sensitivity);
    
    // Add cleanup method
    analyzer._cleanup = () => {
      try {
        oscillator.stop();
        oscillator.disconnect();
        gainNode.disconnect();
        analyzer.disconnect();
      } catch (e) {
        // Ignore cleanup errors
      }
    };
    
    // Store in global state
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = analyzer;
    }
    
    // Track in registry
    window._audioConnectionRegistry.activeAnalyzers.push(analyzer);
    
    return analyzer;
  } catch (err) {
    console.error("Error creating standalone analyzer:", err);
    return createFallbackAnalyzer(sensitivity);
  }
}

/**
 * Creates a purely simulated analyzer when no audio connection is possible
 */
function createFallbackAnalyzer(sensitivity = 1.5) {
  console.log("Creating fallback analyzer (simulation only)");
  
  // Create mock analyzer object
  const mockAnalyzer = {
    fftSize: 2048,
    frequencyBinCount: 1024,
    smoothingTimeConstant: 0.8,
    sensitivity: sensitivity,
    
    // Pre-allocate data arrays
    frequencyDataArray: new Uint8Array(1024),
    timeDataArray: new Uint8Array(1024),
    
    // Time tracking for simulation
    _lastTime: Date.now(),
    _currentTime: 0,
    
    // Main frequency data method
    getByteFrequencyData: function(dataArray) {
      const now = Date.now();
      const deltaTime = Math.min(100, now - this._lastTime) / 1000;
      this._lastTime = now;
      this._currentTime += deltaTime;
      
      const t = this._currentTime;
      const isPlaying = window.globalAudioState ? 
                       window.globalAudioState.audioPlaying : 
                       false;
      
      // Simulate frequency data with time-based variations
      for (let i = 0; i < dataArray.length; i++) {
        // Create frequency distribution (higher values for lower frequencies)
        const normalizedIndex = i / dataArray.length;
        const baseCurve = isPlaying ? 
          120 * Math.pow(1 - normalizedIndex, 1.4) : 
          60 * Math.pow(1 - normalizedIndex, 1.2);
        
        // Add time-based modulation
        const mod1 = isPlaying ? 
          30 * Math.sin(t * 2 + i * 0.05) :
          10 * Math.sin(t * 0.5 + i * 0.05);
          
        const mod2 = isPlaying ? 
          15 * Math.sin(t * 0.7 + i * 0.15) :
          5 * Math.sin(t * 0.3 + i * 0.15);
          
        const mod3 = isPlaying ? 
          10 * Math.sin(t * 0.5 + i * 0.3) :
          3 * Math.sin(t * 0.2 + i * 0.1);
        
        // Add some randomness
        const noise = Math.random() * (isPlaying ? 5 : 2);
        
        // Combine all factors and apply sensitivity
        const value = (baseCurve + mod1 + mod2 + mod3 + noise) * sensitivity;
        
        // Ensure value is within 0-255 range
        dataArray[i] = Math.max(0, Math.min(255, value));
      }
    },
    
    // Time domain data method for waveform visualization
    getByteTimeDomainData: function(dataArray) {
      const now = Date.now();
      const deltaTime = Math.min(100, now - this._lastTime) / 1000;
      this._lastTime = now;
      this._currentTime += deltaTime;
      
      const t = this._currentTime;
      const isPlaying = window.globalAudioState ? 
                       window.globalAudioState.audioPlaying : 
                       false;
      
      // Simulate waveform
      for (let i = 0; i < dataArray.length; i++) {
        // Center point
        const base = 128;
        
        // Phase for wave calculation
        const phase = (i / dataArray.length) * Math.PI * 2;
        
        // Primary amplitude based on playback state
        const primaryAmp = isPlaying ? 40 : 15;
        const primaryWave = primaryAmp * Math.sin(t * 2 + phase);
        
        // Secondary waves for complexity
        const secAmp = isPlaying ? 20 : 8;
        const secondaryWave = secAmp * Math.sin(t * 4 + phase * 3);
        
        const tertAmp = isPlaying ? 10 : 5;
        const tertiaryWave = tertAmp * Math.sin(t * 7 + phase * 7);
        
        // Combine waves and ensure value is within 0-255 range
        const value = base + primaryWave + secondaryWave + tertiaryWave;
        dataArray[i] = Math.max(0, Math.min(255, value));
      }
    },
    
    // Cleanup stub (not needed for simulation)
    _cleanup: function() {}
  };
  
  // Store in global state for reuse
  if (window.globalAudioState) {
    window.globalAudioState._analyzer = mockAnalyzer;
  }
  
  return mockAnalyzer;
}

/**
 * Adds fallback data generation to a real analyzer node
 * This ensures visualization continues even when audio data is absent
 */
function addFallbackDataGeneration(analyzer, sensitivity = 1.5) {
  if (!analyzer) return;
  
  // Store original methods
  const originalGetByteFrequencyData = analyzer.getByteFrequencyData;
  const originalGetByteTimeDomainData = analyzer.getByteTimeDomainData;
  
  // Create state for simulation
  analyzer._simulationTime = 0;
  analyzer._lastSimulationTime = Date.now();
  
  // Override methods with hybrid versions
  analyzer.getByteFrequencyData = function(dataArray) {
    // First try the original method
    originalGetByteFrequencyData.call(this, dataArray);
    
    // Check if we got valid data
    let hasSignificantData = false;
    for (let i = 0; i < dataArray.length; i += 10) {
      if (dataArray[i] > 5) {
        hasSignificantData = true;
        break;
      }
    }
    
    // If no significant data, generate simulation data
    if (!hasSignificantData) {
      // Update simulation time
      const now = Date.now();
      const deltaTime = Math.min(100, now - this._lastSimulationTime) / 1000;
      this._lastSimulationTime = now;
      this._simulationTime += deltaTime;
      
      const t = this._simulationTime;
      const isPlaying = window.globalAudioState ? 
                       window.globalAudioState.audioPlaying : 
                       false;
      
      // Generate simulated frequency data
      for (let i = 0; i < dataArray.length; i++) {
        // Create frequency distribution (higher values for lower frequencies)
        const normalizedIndex = i / dataArray.length;
        const baseCurve = isPlaying ? 
          120 * Math.pow(1 - normalizedIndex, 1.4) : 
          60 * Math.pow(1 - normalizedIndex, 1.2);
        
        // Add time-based modulation
        const mod1 = isPlaying ? 
          30 * Math.sin(t * 2 + i * 0.05) :
          10 * Math.sin(t * 0.5 + i * 0.05);
          
        const mod2 = isPlaying ? 
          15 * Math.sin(t * 0.7 + i * 0.15) :
          5 * Math.sin(t * 0.3 + i * 0.15);
          
        const mod3 = isPlaying ? 
          10 * Math.sin(t * 0.5 + i * 0.3) :
          3 * Math.sin(t * 0.2 + i * 0.1);
        
        // Add some randomness
        const noise = Math.random() * (isPlaying ? 5 : 2);
        
        // Combine all factors and apply sensitivity
        const value = (baseCurve + mod1 + mod2 + mod3 + noise) * sensitivity;
        
        // Ensure value is within 0-255 range
        dataArray[i] = Math.max(0, Math.min(255, value));
      }
    }
  };
  
  // Override time domain data method
  analyzer.getByteTimeDomainData = function(dataArray) {
    // First try the original method
    originalGetByteTimeDomainData.call(this, dataArray);
    
    // Check if we got varying data (not just a flat line)
    let hasVariation = false;
    for (let i = 0; i < dataArray.length; i += 10) {
      if (Math.abs(dataArray[i] - 128) > 5) {
        hasVariation = true;
        break;
      }
    }
    
    // If no variation, generate waveform data
    if (!hasVariation) {
      // Update simulation time
      const now = Date.now();
      const deltaTime = Math.min(100, now - this._lastSimulationTime) / 1000;
      this._lastSimulationTime = now;
      this._simulationTime += deltaTime;
      
      const t = this._simulationTime;
      const isPlaying = window.globalAudioState ? 
                       window.globalAudioState.audioPlaying : 
                       false;
      
      // Generate waveform
      for (let i = 0; i < dataArray.length; i++) {
        // Create phase for sine wave
        const phase = (i / dataArray.length) * Math.PI * 2;
        
        // Center point
        const base = 128;
        
        // Primary wave with amplitude based on playback state
        const primaryAmp = isPlaying ? 40 : 15;
        const primaryWave = primaryAmp * Math.sin(t * 2 + phase);
        
        // Secondary waves for complexity
        const secAmp = isPlaying ? 20 : 8;
        const secondaryWave = secAmp * Math.sin(t * 4 + phase * 3);
        
        const tertAmp = isPlaying ? 10 : 5;
        const tertiaryWave = tertAmp * Math.sin(t * 7 + phase * 7);
        
        // Combine waves and ensure value is within 0-255 range
        const value = base + primaryWave + secondaryWave + tertiaryWave;
        dataArray[i] = Math.max(0, Math.min(255, value));
      }
    }
  };
}