import React, { useEffect, useState } from 'react';

/**
 * SafeListenStepWrapper
 * 
 * A wrapper component that provides error handling for the ListenStep component.
 * This prevents the app from crashing when there's an error in the ListenStep.
 */
const SafeListenStepWrapper = ({ children, audioUrl, onReset, onExportAudio, toast, ...props }) => {
  const [hasError, setHasError] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  
  // Reset error state if audioUrl changes
  useEffect(() => {
    if (hasError && audioUrl) {
      setHasError(false);
      setErrorDetails(null);
    }
  }, [audioUrl, hasError]);
  
  // Error handler that will be called by the ErrorBoundary
  const handleError = (error) => {
    console.error("SafeListenStepWrapper caught error:", error);
    setHasError(true);
    setErrorDetails(error);
    
    // Show toast notification
    if (toast && typeof toast.error === 'function') {
      toast.error("There was an issue with the audio player. Using simplified version.");
    }
    
    // Attempt to recover session storage state
    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('listenStepError', 'true');
        sessionStorage.setItem('errorTime', Date.now().toString());
      }
    } catch (e) {
      console.warn("Error updating session storage:", e);
    }
    
    return true; // Indicates we've handled the error
  };
  
  // If there's an error, show fallback UI
  if (hasError) {
    return (
      <div className="error-fallback" style={{
        padding: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        marginTop: '20px'
      }}>
        <h3 style={{ marginTop: 0 }}>Simplified Audio Player</h3>
        <p>We've encountered an issue with the advanced audio player and switched to a basic version.</p>
        
        <div style={{
          marginTop: '20px',
          backgroundColor: 'rgba(20, 20, 40, 0.3)',
          padding: '20px',
          borderRadius: '8px'
        }}>
          <audio 
            src={audioUrl} 
            controls 
            style={{ width: '100%', marginBottom: '20px' }}
            onError={() => {
              if (toast) toast.error("Audio playback error. The file might be unavailable.");
            }}
          />
          
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            {onExportAudio && (
              <button 
                onClick={onExportAudio}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#7d12ff',
                  color: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
              >
                Download Audio
              </button>
            )}
            
            {onReset && (
              <button 
                onClick={onReset}
                style={{
                  padding: '10px 20px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: 'white',
                  border: '1px solid rgba(255, 255, 255, 0.2)',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
              >
                New Meditation
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  
  // No error, render children with error handler
  return (
    <ErrorCatcher onError={handleError}>
      {children}
    </ErrorCatcher>
  );
};

/**
 * ErrorCatcher
 * 
 * A functional component that acts like an error boundary
 */
class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorCatcher:", error);
    console.error("Component stack:", errorInfo.componentStack);
    
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // Return null since the parent will handle the fallback UI
      return null;
    }

    return this.props.children;
  }
}

export default SafeListenStepWrapper;