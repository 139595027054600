// Visualizers.js - Fixed version with ESLint errors resolved
import React, { useState, useEffect, useRef, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import debounce from 'lodash.debounce';

import {
  syncPlayback,
  syncTimePosition,
  createVisualizationProcessor,
  prepareFullscreenTransition,
  safeDestroyWaveSurfer, 
  safeCreateWaveSurfer,
  checkTransitionState,
  initializeAudio
} from '../audio/audio-sync-fix';

// Import the ThirdEyeVisualizer component
import ThirdEyeVisualizer from './ThirdEyeVisualizer';

// Import all required icons
import {
  IoPlayCircle,
  IoPauseCircle,
  IoPlaySkipBack,
  IoPlaySkipForward,
  IoVolumeHigh,
  IoVolumeOff,
  IoClose,
  IoList,
  IoExpand,
  IoContract,
  IoArrowBack,
  IoHeart,
  IoHeartOutline,
  IoShuffle
} from 'react-icons/io5';

/* =============================================================================
   1. EnhancedFallbackVisualizer
============================================================================= */
export const EnhancedFallbackVisualizer = ({
  isPlaying,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  backgroundColor = '#02073c',
  size = 300
}) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const [visualStyle, setVisualStyle] = useState(Math.floor(Math.random() * 3)); // 0, 1, or 2

  // Timing refs
  const timeRef = useRef(0);
  const lastTimeRef = useRef(0);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setupCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      const width = Math.max(rect.width, 100);
      const height = Math.max(rect.height, 100);
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      ctx.scale(dpr, dpr);
    };
    setupCanvas();

    // ----- Define animation styles -----
    const drawStyle0 = (time, playing) => {
      const { width, height } = canvas.getBoundingClientRect();
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, width, height);
      if (playing) {
        const glowSize = radius * (0.8 + Math.sin(time * 2) * 0.1);
        const glow = ctx.createRadialGradient(centerX, centerY, radius * 0.3, centerX, centerY, glowSize);
        glow.addColorStop(0, `${primaryColor}33`);
        glow.addColorStop(0.5, `${primaryColor}11`);
        glow.addColorStop(1, `${primaryColor}00`);
        ctx.fillStyle = glow;
        ctx.fillRect(0, 0, width, height);
      }
      const rayCount = playing ? 12 : 8;
      const rayLength = playing ? radius * 0.7 : radius * 0.5;
      for (let i = 0; i < rayCount; i++) {
        const angle = (i / rayCount) * Math.PI * 2 + time;
        const rayRadius = rayLength * (0.7 + Math.sin(time * 3 + i) * 0.3);
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(centerX + Math.cos(angle) * rayRadius, centerY + Math.sin(angle) * rayRadius);
        ctx.lineWidth = playing ? 2 : 1;
        ctx.strokeStyle = primaryColor;
        ctx.globalAlpha = 0.5 + Math.sin(time * 2 + i) * 0.3;
        ctx.stroke();
        ctx.globalAlpha = 1;
      }
      const pulseSize = playing ? 0.4 + Math.sin(time * 4) * 0.1 : 0.3 + Math.sin(time * 2) * 0.05;
      const circleGrad = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius * pulseSize);
      circleGrad.addColorStop(0, primaryColor);
      circleGrad.addColorStop(0.7, secondaryColor);
      circleGrad.addColorStop(1, backgroundColor);
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius * pulseSize, 0, Math.PI * 2);
      ctx.fillStyle = circleGrad;
      ctx.fill();
    };

    const drawStyle1 = (time, playing) => {
      const { width, height } = canvas.getBoundingClientRect();
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, width, height);
      const drawPolygon = (x, y, rad, sides, rotation = 0) => {
        const angle = (Math.PI * 2) / sides;
        ctx.beginPath();
        for (let a = rotation; a < Math.PI * 2 + rotation; a += angle) {
          const sx = x + Math.cos(a) * rad;
          const sy = y + Math.sin(a) * rad;
          if (a === rotation) ctx.moveTo(sx, sy);
          else ctx.lineTo(sx, sy);
        }
        ctx.closePath();
      };
      const triangleCount = playing ? 8 : 5;
      const rotationSpeed = playing ? time * 0.5 : time * 0.2;
      const scale = playing ? 0.9 : 0.7;
      for (let i = 0; i < triangleCount; i++) {
        ctx.save();
        ctx.translate(centerX, centerY);
        ctx.rotate((Math.PI * 2 / triangleCount) * i + rotationSpeed);
        const distance = radius * scale * (0.6 + Math.sin(time * 2 + i) * 0.2);
        drawPolygon(0, 0, distance, 3, time);
        ctx.lineWidth = playing ? 2 : 1;
        ctx.strokeStyle = i % 2 === 0 ? primaryColor : secondaryColor;
        ctx.globalAlpha = 0.7 - (i / triangleCount) * 0.3;
        ctx.stroke();
        ctx.globalAlpha = 1;
        ctx.restore();
        if (i % 1 === 0) {
          ctx.save();
          ctx.strokeStyle = secondaryColor;
          ctx.lineWidth = playing ? 0.3 : 0.2;
          drawPolygon(centerX, centerY, radius * scale * 0.8, 7, -rotationSpeed * 0.5);
          ctx.stroke();
          ctx.restore();
        }
      }
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius * 0.2, 0, Math.PI * 2);
      const circleGrad = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius * 0.2);
      circleGrad.addColorStop(0, primaryColor);
      circleGrad.addColorStop(1, secondaryColor);
      ctx.fillStyle = circleGrad;
      ctx.fill();
    };

    const drawStyle2 = (time, playing) => {
      const { width, height } = canvas.getBoundingClientRect();
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, width, height);
      const barCount = 32;
      const barWidth = (Math.PI * 2) / barCount;
      const maxBarHeight = radius * 0.4;
      for (let i = 0; i < barCount; i++) {
        const angle = i * barWidth;
        let barHeight = playing
          ? maxBarHeight * (0.3 + Math.abs(Math.sin(time * 3 + i * 0.2)) * 0.7)
          : maxBarHeight * (0.2 + Math.abs(Math.sin(time + i * 0.2)) * 0.2);
        const innerRadius = radius * 0.3;
        const outerRadius = innerRadius + barHeight;
        ctx.beginPath();
        ctx.arc(centerX, centerY, outerRadius, angle, angle + barWidth * 0.8);
        ctx.arc(centerX, centerY, innerRadius, angle + barWidth * 0.8, angle, true);
        const gradient = ctx.createLinearGradient(
          centerX + Math.cos(angle) * innerRadius,
          centerY + Math.sin(angle) * innerRadius,
          centerX + Math.cos(angle) * outerRadius,
          centerY + Math.sin(angle) * outerRadius
        );
        gradient.addColorStop(0, secondaryColor);
        gradient.addColorStop(1, primaryColor);
        ctx.fillStyle = gradient;
        ctx.fill();
      }
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius * 0.25, 0, Math.PI * 2);
      const circleGrad = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius * 0.25);
      circleGrad.addColorStop(0, backgroundColor);
      circleGrad.addColorStop(0.7, secondaryColor);
      circleGrad.addColorStop(1, primaryColor);
      ctx.fillStyle = circleGrad;
      ctx.fill();
    };

    const animate = (timestamp) => {
      const deltaTime = timestamp - (lastTimeRef.current || timestamp);
      lastTimeRef.current = timestamp;
      const cappedDelta = Math.min(deltaTime, 100) / 1000;
      timeRef.current += cappedDelta;
      switch (visualStyle) {
        case 0:
          drawStyle0(timeRef.current, isPlaying);
          break;
        case 1:
          drawStyle1(timeRef.current, isPlaying);
          break;
        case 2:
        default:
          drawStyle2(timeRef.current, isPlaying);
          break;
      }
      animationRef.current = requestAnimationFrame(animate);
    };
    animationRef.current = requestAnimationFrame(animate);

    const handleResize = () => setupCanvas();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, [isPlaying, primaryColor, secondaryColor, backgroundColor, visualStyle]);

  return (
    <canvas
      ref={canvasRef}
      style={{ width: '100%', height: '100%', borderRadius: '50%' }}
      onClick={() => setVisualStyle((prev) => (prev + 1) % 3)}
    />
  );
};

/* =============================================================================
   2. SimpleInlineVisualizer
============================================================================= */
export const SimpleInlineVisualizer = (props) => {
  const { analyzerNode, isPlaying, primaryColor = '#7d12ff', secondaryColor = '#5636f3' } = props;
  const canvasRef = useRef(null);
  const rafRef = useRef(null);
  const dataArrayRef = useRef(null);
  const timeRef = useRef(0);
  const lastTimeRef = useRef(0);
  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    return () => { mountedRef.current = false; };
  }, []);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    const dpr = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * dpr;
    canvas.height = canvas.clientHeight * dpr;
    ctx.scale(dpr, dpr);

    if (analyzerNode && !dataArrayRef.current) {
      try {
        dataArrayRef.current = new Uint8Array(analyzerNode.frequencyBinCount || 128);
      } catch (err) {
        console.warn("SimpleInlineVisualizer: Error creating data array:", err);
        dataArrayRef.current = new Uint8Array(128);
      }
    }

    const animate = () => {
      if (!canvasRef.current || !mountedRef.current) return;
      const { width, height } = canvas.getBoundingClientRect();
      ctx.fillStyle = '#02073c';
      ctx.fillRect(0, 0, width, height);
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2;
      const time = Date.now() / 1000;
      timeRef.current = time;

      const pulseSize = 0.3 + (isPlaying ? 0.1 : 0.05) * Math.sin(time * 3);
      const centerGradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius * pulseSize);
      centerGradient.addColorStop(0, primaryColor);
      centerGradient.addColorStop(0.7, secondaryColor);
      centerGradient.addColorStop(1, '#02073c');
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius * pulseSize, 0, Math.PI * 2);
      ctx.fillStyle = centerGradient;
      ctx.fill();

      let visualData = new Uint8Array(32).fill(0);
      if (analyzerNode && dataArrayRef.current) {
        try {
          analyzerNode.getByteFrequencyData(dataArrayRef.current);
          const srcLength = dataArrayRef.current.length;
          for (let i = 0; i < 32; i++) {
            const dataIndex = Math.floor(i * (srcLength / 32));
            visualData[i] = dataArrayRef.current[dataIndex] || 0;
          }
        } catch (err) {
          for (let i = 0; i < 32; i++) {
            visualData[i] = isPlaying 
              ? 128 + 127 * Math.sin(time * 2 + i * 0.2) 
              : 64 + 32 * Math.sin(time + i * 0.1);
          }
        }
      } else {
        for (let i = 0; i < 32; i++) {
          visualData[i] = isPlaying 
            ? 128 + 127 * Math.sin(time * 2 + i * 0.2) 
            : 64 + 32 * Math.sin(time + i * 0.1);
        }
      }

      const barCount = 32;
      const angleStep = (Math.PI * 2) / barCount;
      for (let i = 0; i < barCount; i++) {
        const value = visualData[i];
        const normalizedValue = value / 255;
        const barHeight = radius * 0.3 * normalizedValue;
        const angle = i * angleStep + time * (isPlaying ? 0.1 : 0.05);
        const x1 = centerX + Math.cos(angle) * radius * 0.4;
        const y1 = centerY + Math.sin(angle) * radius * 0.4;
        const x2 = centerX + Math.cos(angle) * (radius * 0.4 + barHeight);
        const y2 = centerY + Math.sin(angle) * (radius * 0.4 + barHeight);
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.lineWidth = isPlaying ? 3 : 2;
        ctx.strokeStyle = i % 2 === 0 ? primaryColor : secondaryColor;
        ctx.stroke();
      }
      rafRef.current = requestAnimationFrame(animate);
    };
    rafRef.current = requestAnimationFrame(animate);
    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
    };
  }, [analyzerNode, isPlaying, primaryColor, secondaryColor]);

  return (
    <div style={{ width: '100%', height: '100%', borderRadius: '50%', overflow: 'hidden' }}>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%', display: 'block' }} />
    </div>
  );
};

/* =============================================================================
   3. VisualizerLoader
============================================================================= */
export const VisualizerLoader = ({
  analyzerNode,
  isPlaying = false,
  size = 300,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  tertiaryColor = '#9e65ff',
  sensitivity = 1.5,
  voiceFocus = true,
  fallbackOnly = false,
  id = 'visualizer'
}) => {
  const [Visualizer, setVisualizer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Skip loading if fallbackOnly is true
    if (fallbackOnly) {
      setLoading(false);
      return;
    }

    // Simply load from the same directory
    import('./ThirdEyeVisualizer')
      .then((module) => {
        if (module && module.default) {
          console.log("ThirdEyeVisualizer loaded successfully");
          setVisualizer(() => module.default);
        } else {
          console.warn("Module loaded but default export not found");
          setError(true);
        }
      })
      .catch((err) => {
        console.error("Failed to load ThirdEyeVisualizer, using fallback:", err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });

    // Set a timeout to ensure we don't hang in loading state
    const timeoutId = setTimeout(() => {
      if (loading) {
        console.warn("Loading timed out, using fallback visualizer");
        setLoading(false);
        setError(true);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [fallbackOnly, loading]);

  // Show the fallback visualizer during loading or if there's an error
  if (loading || error || !Visualizer) {
    return (
      <EnhancedFallbackVisualizer
        isPlaying={isPlaying}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    );
  }

  // Render the actual visualizer if it loaded successfully
  return (
    <Visualizer
      analyzerNode={analyzerNode}
      isPlaying={isPlaying}
      size={size}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      tertiaryColor={tertiaryColor}
      sensitivity={sensitivity}
      voiceFocus={voiceFocus}
      id={id}
    />
  );
};

/* =============================================================================
   4. FullScreenVisualizer - Modern design with reliable controls and synchronization
============================================================================= */
export const FullScreenVisualizer = ({
  audioElement, // audio element passed in as a prop
  isPlaying = false,
  onPlayPause = () => {},
  onToggleFullScreen = () => {},
  colorPrimary = '#7d12ff',
  colorSecondary = '#5636f3',
  backgroundColor = '#1a1a1a',
  sensitivity = 1.2,
  externalAnalyzer = null,
  externalAudioContext = null,
  currentTime: initialCurrentTime = 0,
  duration: initialDuration = 0,
  musicLibrary = [],
  selectedMusic = "",
  onMusicSelect = () => {},
  onSkip = () => {},
  onSeek = () => {},
  allowPlaylistAccess = true,
  onVolumeChange = () => {},
  onMuteToggle = () => {},
  initialFullscreen = false,
  waveformData = [],
  volume = 1.0,
  isMuted = false,
  suppressToasts = false,
  waveformColor = 'rgba(255, 255, 255, 0.6)',
  progressColor = '#7d12ff',
  useExistingAudioElement = true
}) => {
  // Local state declarations
  const [showControls, setShowControls] = useState(true);
  const [audioCtx, setAudioCtx] = useState(externalAudioContext);
  const [analyzerNode, setAnalyzerNode] = useState(externalAnalyzer);
  const [audioConnected, setAudioConnected] = useState(!!externalAnalyzer);
  const [visualizerStyle, setVisualizerStyle] = useState(0); // 0, 1, or 2 for different styles
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [volumeState, setVolumeState] = useState({ value: volume });
  const [isMutedState, setIsMutedState] = useState(isMuted);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [currentTime, setCurrentTime] = useState(initialCurrentTime);
  const [duration, setDuration] = useState(initialDuration);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [wavesurferInitialized, setWavesurferInitialized] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(initialFullscreen);
  const [isFavorite, setIsFavorite] = useState(false);

  // Define refs
  const waveSurferRef = useRef(null);
  const audioElementRef = useRef(audioElement);
  const waveformContainerRef = useRef(null);
  const mobileWaveformContainerRef = useRef(null);
  const progressBarRef = useRef(null);
  const controlsTimeoutRef = useRef(null);
  const animationFrameRef = useRef(null);
  const mountedRef = useRef(true);
  const analyzerRef = useRef(externalAnalyzer);
  const actionsBlockedRef = useRef(false);
  const pendingTimeoutsRef = useRef([]);
  const audioUrlRef = useRef("");
  const autoHideControlsTimeoutRef = useRef(null);
  const analyzerInitializationInProgress = useRef(false);
  const safeToastRef = useRef(null);

  const isMobile = dimensions.width <= 768;
  const isTablet = dimensions.width > 768 && dimensions.width < 1024;
  const albumCoverSize = isMobile ? 280 : isTablet ? 320 : 380;
  
  // Track info calculation
  const getTrackInfo = useCallback(() => {
    try {
      if (!selectedMusic || !Array.isArray(musicLibrary) || musicLibrary.length === 0) {
        return { title: 'Your Meditation', artist: 'Pause' };
      }
      
      const track = musicLibrary.find(t => t && typeof t === 'object' && t.name === selectedMusic);
      
      if (track) {
        return {
          title: track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          artist: track.artist || 'Pause Music'
        };
      } else {
        return {
          title: selectedMusic ? selectedMusic.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Your Meditation',
          artist: 'Pause'
        };
      }
    } catch (err) {
      console.error("Error in getTrackInfo:", err);
      return { title: 'Meditation', artist: 'Pause' };
    }
  }, [selectedMusic, musicLibrary]);
  
  const trackInfo = getTrackInfo();

  // Utility functions
  const isValidUrl = useCallback((url) => {
    if (!url || typeof url !== 'string') return false;
    return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('blob:') || url.startsWith('data:');
  }, []);

  const formatTime = useCallback((seconds) => {
    if (isNaN(seconds) || seconds < 0) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  }, []);
  
  // Show toast message if function is available
  const safeToast = useCallback((type, message, options = {}) => {
    if (!mountedRef.current) return null;
    try {
      if (suppressToasts) {
        console.log(`Toast (${type}): ${message}`);
        return null;
      }
      if (window.globalAudioState && typeof window.globalAudioState.publishEvent === 'function') {
        window.globalAudioState.publishEvent('toast', { 
          type, 
          message, 
          options,
          timestamp: Date.now() 
        });
      }
      // Check if toast object or function is available in parent components
      if (window.toast) {
        if (typeof window.toast[type] === 'function') {
          return window.toast[type](message, options);
        } else if (typeof window.toast === 'function') {
          return window.toast(message, { ...options, type });
        }
      }
    } catch (err) {
      console.log(`Error showing toast: ${err.message}`);
    }
    console.log(`Toast (${type}): ${message}`);
    return null;
  }, [suppressToasts]);

  // Store safeToast in ref for access in callbacks
  useEffect(() => {
    safeToastRef.current = safeToast;
  }, [safeToast]);

  // Define the missing function for analyzer creation
  const createRobustAnalyzer = useCallback((options) => {
    const { audioElement, useGlobalAudioState = false, sensitivity = 1.5, onReady } = options || {};
    
    // Create a simple analyzer that works with or without a real audio context
    try {
      // Check if we can use the Web Audio API
      if (window.AudioContext || window.webkitAudioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioCtx = new AudioContext();
        const analyzerNode = audioCtx.createAnalyser();
        
        analyzerNode.fftSize = 2048;
        analyzerNode.smoothingTimeConstant = 0.85;
        
        // If we have an audio element, connect it
        if (audioElement) {
          try {
            const source = audioCtx.createMediaElementSource(audioElement);
            source.connect(analyzerNode);
            analyzerNode.connect(audioCtx.destination);
          } catch (err) {
            console.warn("Error connecting audio element:", err);
          }
        }
        
        // Create utility methods for the analyzer
        analyzerNode.getAudioLevels = (options = {}) => {
          const { sensitivity: localSensitivity = sensitivity, voiceFocus = true, isPlaying = false } = options;
          
          try {
            if (!analyzerNode.frequencyDataArray) {
              analyzerNode.frequencyDataArray = new Uint8Array(analyzerNode.frequencyBinCount);
            }
            
            analyzerNode.getByteFrequencyData(analyzerNode.frequencyDataArray);
            
            // Calculate frequency bands
            let bass = 0, mid = 0, treble = 0;
            let bassCount = 0, midCount = 0, trebleCount = 0;
            
            // Low frequencies (bass)
            for (let i = 1; i < 10; i++) {
              bass += analyzerNode.frequencyDataArray[i] || 0;
              bassCount++;
            }
            
            // Mid frequencies
            for (let i = 10; i < 100; i++) {
              mid += analyzerNode.frequencyDataArray[i] || 0;
              midCount++;
            }
            
            // High frequencies (treble)
            for (let i = 100; i < 200; i++) {
              treble += analyzerNode.frequencyDataArray[i] || 0;
              trebleCount++;
            }
            
            // Normalize
            bass = bassCount > 0 ? (bass / bassCount) * localSensitivity / 255 : 0;
            mid = midCount > 0 ? (mid / midCount) * localSensitivity / 255 : 0;
            treble = trebleCount > 0 ? (treble / trebleCount) * localSensitivity / 255 : 0;
            
            // Apply voice focus if requested
            if (voiceFocus) {
              mid *= 1.2;
              bass *= 0.8;
              treble *= 0.7;
            }
            
            return { bass, mid, treble, isSimulated: false };
          } catch (err) {
            // Fall back to simulated data
            console.warn("Error getting audio levels, using simulated data:", err);
            const t = Date.now() / 1000;
            
            const bass = isPlaying ? 
              (0.2 + Math.sin(t * 0.6) * 0.15) * localSensitivity : 
              (0.1 + Math.sin(t * 0.3) * 0.08) * localSensitivity;
            
            const mid = isPlaying ? 
              (0.25 + Math.sin(t * 0.8 + 0.5) * 0.2) * localSensitivity : 
              (0.1 + Math.sin(t * 0.4 + 0.3) * 0.05) * localSensitivity;
            
            const treble = isPlaying ? 
              (0.2 + Math.sin(t * 0.7 + 0.9) * 0.15) * localSensitivity : 
              (0.08 + Math.sin(t * 0.35 + 0.7) * 0.05) * localSensitivity;
            
            return { bass, mid, treble, isSimulated: true };
          }
        };
        
        // Add cleanup method
        analyzerNode._cleanup = () => {
          try {
            analyzerNode.disconnect();
            audioCtx.close();
          } catch (e) {
            // Ignore cleanup errors
          }
        };
        
        // Store in global state if requested
        if (useGlobalAudioState && window.globalAudioState) {
          window.globalAudioState._analyzer = analyzerNode;
          window.globalAudioState._audioContext = audioCtx;
        }
        
        // Call ready callback if provided
        if (typeof onReady === 'function') {
          onReady(analyzerNode);
        }
        
        return analyzerNode;
      }
    } catch (err) {
      console.warn("Error creating robust analyzer:", err);
    }
    
    // Fallback to simulated analyzer
    return createSimulatedAnalyzer(options);
  }, []);
  
  // Create a simulated analyzer for fallback
  const createSimulatedAnalyzer = useCallback((options) => {
    const { sensitivity = 1.5, onReady } = options || {};
    
    const simulatedAnalyzer = {
      frequencyBinCount: 1024,
      smoothingTimeConstant: 0.85,
      
      getByteFrequencyData: function(dataArray) {
        const t = Date.now() / 1000;
        for (let i = 0; i < dataArray.length; i++) {
          const base = 80 * Math.pow(1 - (i / dataArray.length), 1.3);
          const mod = isPlaying ? 
            30 * Math.sin(t * 2 + i * 0.05) + 15 * Math.sin(t * 0.7 + i * 0.15) :
            10 * Math.sin(t * 0.5 + i * 0.05) + 5 * Math.sin(t * 0.3 + i * 0.15);
          dataArray[i] = Math.max(0, Math.min(255, base + mod));
        }
      },
      
      getByteTimeDomainData: function(dataArray) {
        const t = Date.now() / 1000;
        for (let i = 0; i < dataArray.length; i++) {
          const phase = (i / dataArray.length) * Math.PI * 2;
          const amp = isPlaying ? 40 : 10;
          dataArray[i] = 128 + amp * Math.sin(t * 2 + phase);
        }
      },
      
      getAudioLevels: function(options = {}) {
        const { sensitivity: localSensitivity = sensitivity, voiceFocus = true, isPlaying = false } = options;
        const t = Date.now() / 1000;
        
        const bass = isPlaying ? 
          (0.2 + Math.sin(t * 0.6) * 0.15) * localSensitivity : 
          (0.1 + Math.sin(t * 0.3) * 0.08) * localSensitivity;
        
        const mid = isPlaying ? 
          (0.25 + Math.sin(t * 0.8 + 0.5) * 0.2) * localSensitivity : 
          (0.1 + Math.sin(t * 0.4 + 0.3) * 0.05) * localSensitivity;
        
        const treble = isPlaying ? 
          (0.2 + Math.sin(t * 0.7 + 0.9) * 0.15) * localSensitivity : 
          (0.08 + Math.sin(t * 0.35 + 0.7) * 0.05) * localSensitivity;
        
        return { bass, mid, treble, isSimulated: true };
      },
      
      _cleanup: function() {}
    };
    
    // Call ready callback if provided
    if (typeof onReady === 'function') {
      onReady(simulatedAnalyzer);
    }
    
    return simulatedAnalyzer;
  }, [isPlaying]);

  // Define the missing function for WaveSurfer initialization
  const initializeWaveSurfer = useCallback(() => {
    // Don't initialize if already in progress or being destroyed
    if (analyzerInitializationInProgress.current) {
      return null;
    }
    
    console.log("FullScreenVisualizer: Initializing WaveSurfer");
    
    // First validate that we have an audio element
    if (!audioElementRef.current) {
      console.warn("FullScreenVisualizer: No audio element available for WaveSurfer");
      return null;
    }
    
    // Get the appropriate container
    const container = isFullscreen ? 
      mobileWaveformContainerRef.current : 
      waveformContainerRef.current;
    
    if (!container) {
      console.warn("FullScreenVisualizer: No container found for WaveSurfer initialization");
      return null;
    }
    
    // Create WaveSurfer instance
    const wsInstance = safeCreateWaveSurfer({
      container,
      WaveSurfer,
      audioElement: audioElementRef.current,
      isMobile,
      waveColor: waveformColor,
      progressColor: progressColor,
      barWidth: isMobile ? 2 : 3,
      barGap: isMobile ? 1 : 2,
      barRadius: 2,
      cursorWidth: 2,
      cursorColor: progressColor,
      normalize: true,
      responsive: true,
      interact: true,
      hideScrollbar: true,
      height: isMobile ? 60 : 80,
      
      onReady: () => {
        if (!mountedRef.current) return;
        
        console.log("FullScreenVisualizer: WaveSurfer ready event fired");
        setAudioLoaded(true);
        setIsLoadingAudio(false);
        setWavesurferInitialized(true);
        
        try {
          const dur = wsInstance.getDuration();
          if (dur && !isNaN(dur) && isFinite(dur)) {
            setDuration(dur);
          }
        } catch (err) {
          console.warn("FullScreenVisualizer: Error getting duration:", err);
        }
      },
      
      onError: (err) => {
        if (!mountedRef.current) return;
        
        console.error("FullScreenVisualizer: WaveSurfer error:", err);
        setIsLoadingAudio(false);
      }
    });
    
    return wsInstance;
  }, [
    isFullscreen,
    isMobile,
    waveformColor,
    progressColor,
    safeCreateWaveSurfer
  ]);

  // Setup functions
  const createFallbackAudioElement = useCallback((url) => {
    if (!mountedRef.current) return null;
    
    console.log("FullScreenVisualizer: Creating fallback audio element with URL:", url);
    try {
      const audioEl = new Audio();
      audioEl.crossOrigin = "anonymous";
      audioEl.preload = "auto";
      audioEl.autoplay = false;
      audioEl.onerror = (error) => {
        console.error("FullScreenVisualizer: Fallback audio element error:", error);
        safeToastRef.current?.('error', 'Audio playback error. Please try again.');
      };
      
      // Setup event listeners
      audioEl.addEventListener('loadedmetadata', () => {
        if (!mountedRef.current) return;
        console.log("FullScreenVisualizer: Fallback audio metadata loaded, duration:", audioEl.duration);
        setDuration(audioEl.duration || 0);
        setAudioLoaded(true);
        setIsLoadingAudio(false);
      });
      
      audioEl.addEventListener('timeupdate', () => {
        if (!mountedRef.current) return;
        setCurrentTime(audioEl.currentTime || 0);
      });
      
      audioEl.addEventListener('play', () => {
        if (!mountedRef.current) return;
        if (window.globalAudioState) {
          window.globalAudioState.audioPlaying = true;
        }
      });
      
      audioEl.addEventListener('pause', () => {
        if (!mountedRef.current) return;
        if (window.globalAudioState) {
          window.globalAudioState.audioPlaying = false;
        }
      });
      
      audioEl.addEventListener('ended', () => {
        if (!mountedRef.current) return;
        setCurrentTime(0);
        if (window.globalAudioState) {
          window.globalAudioState.audioPlaying = false;
        }
      });
      
      // Mark listeners as initialized to prevent duplication
      audioEl._listenersInitialized = true;
      
      // Set source if URL is valid
      if (url && isValidUrl(url)) {
        audioEl.src = url;
        audioEl.load();
      } else {
        console.warn("FullScreenVisualizer: Invalid URL provided to fallback audio element");
        const silentUrl = createFallbackAudioBlob();
        audioEl.src = silentUrl;
        audioEl.load();
      }
      
      audioElementRef.current = audioEl;
      if (window.globalAudioState && !window.globalAudioState._audioElement) {
        window.globalAudioState._audioElement = audioEl;
      }
      
      return audioEl;
    } catch (error) {
      console.error("FullScreenVisualizer: Error creating fallback audio element:", error);
      setIsLoadingAudio(false);
      safeToastRef.current?.('error', 'Unable to initialize audio player. Please refresh and try again.');
      return null;
    }
  }, [isValidUrl]);

  // Create a fallback audio blob when needed
  const createFallbackAudioBlob = useCallback(() => {
    return 'data:audio/mp3;base64,SUQzAwAAAAACHlRJVDIAAAAZAAAAaHR0cDovL3d3dy5mcmVlc2Z4Lm';
  }, []);

  // Load audio into WaveSurfer
  const loadAudioIntoWaveSurfer = useCallback((url, wsInstance) => {
    if (!url || !wsInstance || wsInstance._destroyed || wsInstance._isBeingDestroyed) {
      console.warn("FullScreenVisualizer: Cannot load audio - invalid URL or WaveSurfer instance");
      return false;
    }
    
    console.log("FullScreenVisualizer: Loading audio into WaveSurfer:", url);
    try {
      // Add a flag to track the current loading operation
      wsInstance._currentLoadingUrl = url;
      
      // Cancel any previous load operation
      if (wsInstance._abortController && typeof wsInstance._abortController.abort === 'function') {
        wsInstance._abortController.abort();
      }
      
      // Create new abort controller
      const controller = new AbortController();
      wsInstance._abortController = controller;
      
      // Track retry attempts
      let retryCount = 0;
      const maxRetries = 3;
      
      const attemptLoad = () => {
        // Don't proceed if component is unmounted or the instance is being destroyed
        if (!mountedRef.current || wsInstance._destroyed || wsInstance._isBeingDestroyed) {
          console.warn("FullScreenVisualizer: WaveSurfer instance is destroyed or component unmounted, cannot load audio");
          return false;
        }
        
        // Check if backend is ready, if not retry
        if (wsInstance.backend && wsInstance.backend.isPaused === null) {
          if (retryCount < maxRetries) {
            retryCount++;
            console.log(`FullScreenVisualizer: Retrying load (${retryCount}/${maxRetries}) due to backend not ready...`);
            const timeoutId = setTimeout(attemptLoad, 800);
            pendingTimeoutsRef.current.push(timeoutId);
            return false;
          }
        }
        
        try {
          // Try to load with media if backend is MediaElement
          if (wsInstance.backend && wsInstance.backend.name === 'MediaElement' && wsInstance.backend.media) {
            try {
              wsInstance.backend.media.src = url;
              wsInstance.backend.media.load();
            } catch (mediaErr) {
              console.warn("FullScreenVisualizer: Error loading via media element:", mediaErr);
            }
          }
          
          // Load the audio
          wsInstance.load(url);
          return true;
        } catch (innerErr) {
          console.warn("FullScreenVisualizer: Error loading audio:", innerErr);
          
          // Retry if the error is related to isPaused
          if (innerErr?.toString().includes("isPaused") && retryCount < maxRetries) {
            retryCount++;
            const timeoutId = setTimeout(attemptLoad, 800);
            pendingTimeoutsRef.current.push(timeoutId);
            return false;
          }
          
          return false;
        }
      };
      
      return attemptLoad();
    } catch (error) {
      console.error("FullScreenVisualizer: Error in loadAudioIntoWaveSurfer:", error);
      return false;
    }
  }, []);

  // Audio analyzer initialization (with fallback)
  const initializeAudioAnalysis = useCallback(() => {
    // Don't initialize if already in progress or being destroyed
    if (analyzerInitializationInProgress.current || !mountedRef.current) {
      return false;
    }
    
    analyzerInitializationInProgress.current = true;
    console.log("FullScreenVisualizer: Initializing audio analysis for visualizer");
    
    // Check if we should use existing audio element
    if (useExistingAudioElement && externalAnalyzer) {
      console.log("FullScreenVisualizer: Using provided external analyzer");
      setAnalyzerNode(externalAnalyzer);
      analyzerRef.current = externalAnalyzer;
      setAudioConnected(true);
      analyzerInitializationInProgress.current = false;
      return true;
    }
    
    // Try to use analyzer from global state if available
    if (window.globalAudioState && window.globalAudioState._analyzer && 
        typeof window.globalAudioState._analyzer.getByteFrequencyData === 'function') {
      console.log("FullScreenVisualizer: Using analyzer from global state");
      setAnalyzerNode(window.globalAudioState._analyzer);
      analyzerRef.current = window.globalAudioState._analyzer;
      setAudioConnected(true);
      analyzerInitializationInProgress.current = false;
      return true;
    }
    
    // Use createRobustAnalyzer
    try {
      const analyzer = createRobustAnalyzer({
        audioElement: audioElementRef.current,
        useGlobalAudioState: true,
        sensitivity: sensitivity || 1.5,
        onReady: (analyzer) => {
          if (!mountedRef.current) return;
          console.log("FullScreenVisualizer: Analyzer initialization complete");
          setAnalyzerNode(analyzer);
          analyzerRef.current = analyzer;
          setAudioConnected(true);
        }
      });
      
      if (analyzer) {
        analyzerRef.current = analyzer;
        setAnalyzerNode(analyzer);
        setAudioConnected(true);
        analyzerInitializationInProgress.current = false;
        return true;
      }
    } catch (error) {
      console.error("FullScreenVisualizer: Error with robust analyzer creation:", error);
    }
    
    // Fallback to simulated analyzer if all else fails
    const fallbackAnalyzer = createSimulatedAnalyzer({
      sensitivity: sensitivity || 1.5
    });
    
    analyzerRef.current = fallbackAnalyzer;
    setAnalyzerNode(fallbackAnalyzer);
    setAudioConnected(true);
    
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = fallbackAnalyzer;
    }
    
    analyzerInitializationInProgress.current = false;
    return true;
  }, [
    externalAnalyzer, 
    sensitivity, 
    useExistingAudioElement,
    createRobustAnalyzer,
    createSimulatedAnalyzer
  ]);

  // Toggle fullscreen mode
  const toggleFullscreen = useCallback(() => {
    console.log("FullScreenVisualizer: Toggle fullscreen");
    
    try {
      // Save detailed state to window globals for transition
      window.__fsTransitionTime = currentTime || 0;
      window.__fsTransitionPlaying = isPlaying;
      window.__fsTransitionVolume = volumeState.value;
      window.__fsTransitionMuted = isMuted;
      window.__fsLastPosition = duration > 0 ? currentTime / duration : 0;
      
      // Use the transition preparation function if available
      if (typeof prepareFullscreenTransition === 'function') {
        prepareFullscreenTransition({
          direction: initialFullscreen ? 'exit' : 'enter',
          audioElement: audioElementRef.current,
          waveSurfer: waveSurferRef.current,
          currentUrl: audioElementRef.current?.src,
          isPlaying,
          currentTime: currentTime || 0,
          duration: duration || 0
        });
      }
      
      // Ensure audio time position is maintained after transition
      if (audioElementRef.current) {
        try {
          audioElementRef.current.currentTime = currentTime;
          
          // Make sure playback state is maintained after transition
          if (isPlaying) {
            setTimeout(() => {
              if (audioElementRef.current && audioElementRef.current.paused) {
                audioElementRef.current.play().catch(err => {
                  console.warn("Fullscreen transition play error:", err);
                });
              }
            }, 150);
          }
        } catch (err) {
          console.warn("Error maintaining time/playback during transition:", err);
        }
      }
      
      // Call the callback to trigger actual fullscreen toggle in parent
      onToggleFullScreen();
    } catch (error) {
      console.error("FullScreenVisualizer: Error toggling fullscreen:", error);
      // Still try to call the callback even if other operations failed
      onToggleFullScreen();
    }
  }, [initialFullscreen, currentTime, isPlaying, volumeState.value, isMuted, duration, onToggleFullScreen, prepareFullscreenTransition]);

  // Handle seeking (clicking on progress bar)
  const handleSeek = useCallback((e) => {
    if (!progressBarRef.current || !duration) return;
    
    const rect = progressBarRef.current.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    const newTime = position * duration;
    
    // Use syncTimePosition if available
    if (typeof syncTimePosition === 'function') {
      syncTimePosition({
        currentTime: newTime,
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current
      });
    } else if (audioElementRef.current) {
      audioElementRef.current.currentTime = newTime;
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        waveSurferRef.current.seekTo(position);
      }
    }
    
    setCurrentTime(newTime);
    onSeek(newTime);
  }, [duration, onSeek, syncTimePosition]);

  // Toggle mute state
  const toggleMute = useCallback(() => {
    setIsMutedState(prev => {
      const newMuted = !prev;
      
      try {
        // Update audio element mute state
        if (audioElementRef.current) {
          audioElementRef.current.muted = newMuted;
        }
        
        // Update WaveSurfer mute state
        if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
          if (typeof waveSurferRef.current.setMute === 'function') {
            waveSurferRef.current.setMute(newMuted);
          } else {
            waveSurferRef.current.setVolume(newMuted ? 0 : volumeState.value);
          }
        }
        
        // Update global state if available
        if (window.globalAudioState) {
          window.globalAudioState.state = {
            ...window.globalAudioState.state,
            muted: newMuted
          };
        }
        
        onMuteToggle(newMuted);
      } catch (error) {
        console.error("FullScreenVisualizer: Error toggling mute:", error);
      }
      
      return newMuted;
    });
  }, [onMuteToggle, volumeState.value]);

  // Handle skipping forward/backward
  const handleSkip = useCallback((seconds) => {
    try {
      // Use syncTimePosition if available
      if (typeof syncTimePosition === 'function') {
        const newTime = Math.max(0, currentTime + seconds);
        syncTimePosition({
          currentTime: newTime,
          audioElement: audioElementRef.current,
          waveSurfer: waveSurferRef.current
        });
        setCurrentTime(newTime);
        onSkip(seconds);
        return;
      }
      
      // Fallback implementation
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        let cur = waveSurferRef.current.getCurrentTime() || 0;
        let newTime = Math.max(0, cur + seconds);
        let dur = waveSurferRef.current.getDuration() || 0;
        if (newTime > dur) newTime = dur;
        waveSurferRef.current.seekTo(dur ? newTime / dur : 0);
        if (audioElementRef.current) audioElementRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      } else if (audioElementRef.current) {
        let cur = audioElementRef.current.currentTime || 0;
        let newTime = Math.max(0, cur + seconds);
        let dur = audioElementRef.current.duration || 0;
        if (newTime > dur) newTime = dur;
        audioElementRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
      
      onSkip(seconds);
    } catch (error) {
      console.error("FullScreenVisualizer: Error in handleSkip:", error);
      safeToastRef.current?.('error', 'Error skipping audio. Please try again.');
    }
  }, [currentTime, onSkip, syncTimePosition]);

  // Toggle play/pause
  const togglePlayback = useCallback(() => {
    if (actionsBlockedRef.current) return;
    actionsBlockedRef.current = true;
    
    try {
      // Use syncPlayback if available
      if (typeof syncPlayback === 'function') {
        syncPlayback({
          isPlaying: !isPlaying,
          audioElement: audioElementRef.current,
          waveSurfer: waveSurferRef.current,
          globalAudioState: window.globalAudioState
        });
      } else if (audioElementRef.current) {
        // Direct control
        if (audioElementRef.current.paused) {
          audioElementRef.current.play().catch(err => {
            console.error("Play error:", err);
            
            // Handle autoplay restrictions
            if (err.name === 'NotAllowedError') {
              safeToastRef.current?.('info', 'Audio playback requires user interaction first.');
              
              // Add interaction handlers
              const handleUserInteraction = () => {
                if (audioElementRef.current) {
                  audioElementRef.current.play().catch(e => console.warn("Second play attempt failed:", e));
                }
                // Remove listeners after first interaction
                document.removeEventListener('click', handleUserInteraction);
                document.removeEventListener('touchstart', handleUserInteraction);
                document.removeEventListener('keydown', handleUserInteraction);
              };
              
              document.addEventListener('click', handleUserInteraction, { once: true });
              document.addEventListener('touchstart', handleUserInteraction, { once: true });
              document.addEventListener('keydown', handleUserInteraction, { once: true });
            }
          });
        } else {
          audioElementRef.current.pause();
        }
      }
      
      // Notify parent
      onPlayPause();
    } catch (error) {
      console.error("Error toggling playback:", error);
      safeToastRef.current?.('error', 'Audio player not responding. Please try again.');
    }
    
    setTimeout(() => { actionsBlockedRef.current = false; }, 200);
  }, [isPlaying, onPlayPause, syncPlayback]);


  // Create a fallback analyzer when audio connection fails
  const createFallbackAnalyzer = useCallback(() => {
    console.log("FullScreenVisualizer: Creating fallback analyzer");
    
    // Use createVisualizationProcessor if available
    if (typeof createVisualizationProcessor === 'function') {
      try {
        const fallbackAnalyzer = createVisualizationProcessor(null);
        analyzerRef.current = fallbackAnalyzer;
        setAnalyzerNode(fallbackAnalyzer);
        setAudioConnected(true);
        
        if (window.globalAudioState) {
          window.globalAudioState._analyzer = fallbackAnalyzer;
        }
        
        analyzerInitializationInProgress.current = false;
        return true;
      } catch (error) {
        console.warn("FullScreenVisualizer: Error creating fallback with visualization processor:", error);
      }
    }
    
    // Create a simple fallback if createVisualizationProcessor isn't available
    const simpleAnalyzer = {
      frequencyBinCount: 1024,
      smoothingTimeConstant: 0.85,
      getByteFrequencyData: function(dataArray) {
        const t = Date.now() / 1000;
        for (let i = 0; i < dataArray.length; i++) {
          const base = 80 * Math.pow(1 - (i / dataArray.length), 1.3);
          const mod = isPlaying 
            ? 30 * Math.sin(t * 2 + i * 0.05) + 15 * Math.sin(t * 0.7 + i * 0.15)
            : 10 * Math.sin(t * 0.5 + i * 0.05) + 5 * Math.sin(t * 0.3 + i * 0.15);
          dataArray[i] = Math.max(0, Math.min(255, base + mod));
        }
      },
      getByteTimeDomainData: function(dataArray) {
        const t = Date.now() / 1000;
        for (let i = 0; i < dataArray.length; i++) {
          const phase = (i / dataArray.length) * Math.PI * 2;
          const amp = isPlaying ? 40 : 10;
          dataArray[i] = 128 + amp * Math.sin(t * 2 + phase);
        }
      },
      // Add cleanup method for consistency
      _cleanup: function() {}
    };
    
    analyzerRef.current = simpleAnalyzer;
    setAnalyzerNode(simpleAnalyzer);
    setAudioConnected(true);
    
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = simpleAnalyzer;
    }
    
    analyzerInitializationInProgress.current = false;
    return true;
  }, [isPlaying]);
  
  // Toggle favorite
  const toggleFavorite = useCallback(() => {
    setIsFavorite(prev => !prev);
  }, []);

  // Toggle playlist
  const togglePlaylist = useCallback(() => {
    setShowPlaylist(prev => !prev);
  }, []);

  // Toggle visualizer style by clicking on it
  const cycleVisualizerStyle = useCallback(() => {
    setVisualizerStyle(prev => (prev + 1) % 3);
  }, []);

  // Update audio URL when it changes
  useEffect(() => {
    if (!audioUrl || (audioElementRef.current && audioElementRef.current.src === audioUrl)) return;
    
    if (audioElementRef.current && audioElementRef.current.src !== audioUrl) {
      audioElementRef.current.src = audioUrl;
      audioElementRef.current.load();
    }
    
    if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
      loadAudioIntoWaveSurfer(audioUrl, waveSurferRef.current);
    }
  }, [audioUrl, loadAudioIntoWaveSurfer]);

  // FullScreenVisualizer component initialization and cleanup
  useEffect(() => {
    console.log("FullScreenVisualizer: Component mounted, initializing...");
    mountedRef.current = true;
    
    const setupAudioPlayer = () => {
      try {
        // Check if we're in a transition state
        if (typeof checkTransitionState === 'function' && checkTransitionState() && !window.__transitionHandled) {
          console.log("FullScreenVisualizer: Handling transition state");
          
          // Find the best audio URL from various sources
          let bestUrl = window.__mixedAudioUrl || window.__currentAudioUrl ||
                        sessionStorage.getItem('mixedAudioUrl') ||
                        sessionStorage.getItem('currentAudioUrl') ||
                        (audioElementRef.current && audioElementRef.current.src) ||
                        window.__stableTransitionUrl;
          
          // Set the URL if valid
          if (bestUrl && isValidUrl(bestUrl)) {
            setAudioUrl(bestUrl);
            
            // Update audio element if needed
            if (audioElementRef.current && audioElementRef.current.src !== bestUrl) {
              audioElementRef.current.src = bestUrl;
              audioElementRef.current.load();
            }
          }
          
          // Mark transition as handled
          window.__transitionHandled = true;
          
          // Initialize components with a delay to ensure stability
          setTimeout(() => {
            if (!mountedRef.current) return;
            
            // Initialize analyzer for visualization
            if (audioElementRef.current && !analyzerRef.current) {
              initializeAudioAnalysis();
            }
            
            setInitComplete(true);
            
            // Initialize WaveSurfer after a delay
            const wsTimeout = setTimeout(() => {
              if (!mountedRef.current) return;
              
              try {
                const wsInstance = initializeWaveSurfer();
                
                if (wsInstance && audioElementRef.current && isValidUrl(audioElementRef.current.src)) {
                  loadAudioIntoWaveSurfer(audioElementRef.current.src, wsInstance);
                }
                
                waveSurferRef.current = wsInstance;
                setWavesurferInitialized(true);
              } catch (err) {
                console.warn("FullScreenVisualizer: Error in delayed WaveSurfer init:", err);
              }
            }, 1000);
            
            pendingTimeoutsRef.current.push(wsTimeout);
          }, 100);
        } else {
          // Standard initialization (non-transition)
          // Use window.initAudioFromUtils if available
          if (audioElementRef.current && typeof window.initAudioFromUtils === 'function') {
            const result = window.initAudioFromUtils({
              audioUrl: audioUrl || '',
              globalAudioState: window.globalAudioState,
              audioElementRef,
              waveSurferRef,
              setAudioElement: (el) => { audioElementRef.current = el; },
              createFallbackElement: createFallbackAudioElement,
              onAudioLoaded: (event) => {
                console.log("FullScreenVisualizer: Audio ready", event);
                if (event.duration) {
                  setDuration(event.duration);
                }
                setAudioLoaded(true);
                setIsLoadingAudio(false);
              }
            });
            
            if (result && result.success) {
              // Update URL if audio element has one
              if (result.audioElement && result.audioElement.src) {
                setAudioUrl(result.audioElement.src);
              }
              
              // Initialize WaveSurfer with a delay
              const wsTimeout = setTimeout(() => {
                if (!mountedRef.current) return;
                
                try {
                  const wsInstance = initializeWaveSurfer();
                  
                  if (wsInstance && audioUrlRef.current) {
                    loadAudioIntoWaveSurfer(audioUrlRef.current, wsInstance);
                  }
                  
                  waveSurferRef.current = wsInstance;
                  setWavesurferInitialized(true);
                } catch (err) {
                  console.error("FullScreenVisualizer: Error initializing WaveSurfer:", err);
                }
                
                // Initialize analyzer with a delay
                setTimeout(() => {
                  if (mountedRef.current && !analyzerRef.current) {
                    initializeAudioAnalysis();
                  }
                }, 300);
                
                setInitComplete(true);
              }, 300);
              
              pendingTimeoutsRef.current.push(wsTimeout);
            } else {
              console.warn("FullScreenVisualizer: Audio initialization failed, using fallback");
              
              // Fall back to direct audio element
              if (audioUrl && isValidUrl(audioUrl)) {
                createFallbackAudioElement(audioUrl);
                setAudioLoaded(true);
                setIsLoadingAudio(false);
              }
              
              // Create fallback analyzer for visualization
              setTimeout(() => {
                if (mountedRef.current && !analyzerRef.current) {
                  createFallbackAnalyzer();
                }
              }, 300);
              
              setInitComplete(true);
            }
          } else {
            // Simple initialization without utilities
            if (audioUrl && isValidUrl(audioUrl)) {
              createFallbackAudioElement(audioUrl);
            } else if (audioElementRef.current && isValidUrl(audioElementRef.current.src)) {
              setAudioUrl(audioElementRef.current.src);
            }
            
            // Initialize analyzer
            setTimeout(() => {
              if (mountedRef.current && !analyzerRef.current) {
                initializeAudioAnalysis();
              }
            }, 300);
            
            setAudioLoaded(true);
            setIsLoadingAudio(false);
            setInitComplete(true);
          }
        }
        
        setIsInitialized(true);
      } catch (error) {
        console.error("FullScreenVisualizer: Error during initialization:", error);
        setIsInitialized(true);
        setInitComplete(true);
        
        // Try fallback initialization for recovery
        try {
          // Create fallback audio element
          let url = audioUrl;
          
          if (!url && audioElementRef.current) {
            url = audioElementRef.current.src;
          }
          
          if (url && isValidUrl(url)) {
            createFallbackAudioElement(url);
          } else {
            const silentUrl = createFallbackAudioBlob();
            createFallbackAudioElement(silentUrl);
          }
          
          // Create fallback analyzer
          createFallbackAnalyzer();
          
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        } catch (fallbackError) {
          console.error("FullScreenVisualizer: Fallback initialization failed:", fallbackError);
        }
      }
    };
    
    // Start initialization process
    setupAudioPlayer();
    
    // Set up a safety timeout to ensure audio eventually loads
    const safetyTimeout = setTimeout(() => {
      if (mountedRef.current && !audioLoaded) {
        console.warn("FullScreenVisualizer: Audio load timed out, using fallback");
        
        // Try to get a valid URL from various sources
        let fallbackUrl = (window.globalAudioState && typeof window.globalAudioState.getBestAudioUrl === 'function' &&
                          window.globalAudioState.getBestAudioUrl()) ||
                          audioUrl || 
                          (audioElementRef.current && audioElementRef.current.src) ||
                          '';
        
        // Create silent audio as last resort
        if (!fallbackUrl || !isValidUrl(fallbackUrl)) {
          fallbackUrl = createFallbackAudioBlob();
        }
        
        if (fallbackUrl) {
          createFallbackAudioElement(fallbackUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
        
        // Ensure we have an analyzer
        if (!analyzerRef.current) {
          createFallbackAnalyzer();
        }
      }
    }, 8000);
    
    pendingTimeoutsRef.current.push(safetyTimeout);
    
    // Return cleanup function
    return () => {
      console.log("FullScreenVisualizer: Component unmounting, cleaning up...");
      mountedRef.current = false;
      
      // Clear all pending timeouts
      pendingTimeoutsRef.current.forEach(id => clearTimeout(id));
      pendingTimeoutsRef.current = [];
      
      // Cancel any animation frames
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      
      // Clean up WaveSurfer instance
      if (waveSurferRef.current && typeof safeDestroyWaveSurfer === 'function') {
        try {
          safeDestroyWaveSurfer(waveSurferRef.current);
        } catch (err) {
          console.warn("FullScreenVisualizer: Error destroying WaveSurfer:", err);
        }
        waveSurferRef.current = null;
      } else if (waveSurferRef.current && typeof waveSurferRef.current.destroy === 'function') {
        try {
          waveSurferRef.current.destroy();
        } catch (err) {
          console.warn("FullScreenVisualizer: Error destroying WaveSurfer:", err);
        }
        waveSurferRef.current = null;
      }
      
      // Clean up analyzer if not shared
      if (analyzerRef.current && analyzerRef.current._cleanup && !window.globalAudioState?._analyzer) {
        try {
          analyzerRef.current._cleanup();
        } catch (err) {
          // Ignore cleanup errors
        }
      }
    };
  }, [
    audioUrl, 
    isValidUrl,
    createFallbackAudioElement, 
    loadAudioIntoWaveSurfer, 
    initializeAudioAnalysis,
    createFallbackAnalyzer,
    createFallbackAudioBlob,
    audioLoaded
  ]);

  // Render the player UI with modern design
  return (
    <div
      className="fullscreen-visualizer"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: backgroundColor,
        color: 'white',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        overflow: 'hidden',
        zIndex: 1000,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif'
      }}
      data-fullscreen="true"
    >
      {/* Animated background gradient */}
      <div
        className="background-gradient"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `radial-gradient(circle at 10% 20%, ${colorPrimary}15 0%, transparent 20%), 
                      radial-gradient(circle at 90% 80%, ${colorSecondary}15 0%, transparent 20%)`,
          zIndex: 1,
          pointerEvents: 'none'
        }}
      />
      
      {/* Main content area */}
      <div
        className="player-main"
        style={{
          flex: isMobile ? 'none' : '0 0 60%',
          height: isMobile ? '70%' : '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: isMobile ? '20px' : '40px',
          position: 'relative',
          zIndex: 2
        }}
      >
        {/* Header area with title and back button */}
        <div
          className="player-header"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: isMobile ? '20px' : '40px'
          }}
        >
          <button
            onClick={toggleFullscreen}
            style={{
              background: 'transparent',
              border: 'none',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '16px',
              cursor: 'pointer',
              padding: '8px 0'
            }}
          >
            <IoArrowBack size={20} />
            <span>Back</span>
          </button>
          
          <div style={{
            fontSize: '14px',
            opacity: 0.7,
            textAlign: 'right'
          }}>
            {trackInfo.artist || 'Pause Meditation'}
          </div>
        </div>
        
        {/* Main visualization area */}
        <div
          className="visualization-container"
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* Album art with visualizer overlay */}
          <div
            className="album-cover"
            style={{
              width: albumCoverSize,
              height: albumCoverSize,
              borderRadius: '50%',
              backgroundColor: '#222',
              position: 'relative',
              overflow: 'hidden',
              boxShadow: `0 10px 30px rgba(0,0,0,0.4), 0 0 0 1px rgba(255,255,255,0.05), 
                          0 0 30px ${colorPrimary}40`,
              cursor: 'pointer'
            }}
            onClick={cycleVisualizerStyle}
          >
            {/* Actual visualizer */}
            <ThirdEyeVisualizer
              analyzerNode={analyzerNode}
              isPlaying={isPlaying}
              primaryColor={colorPrimary}
              secondaryColor={colorSecondary}
              sensitivity={sensitivity}
              id="modern-player-viz"
            />
            
            {/* Circular border highlight */}
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: '50%',
                border: `1px solid ${colorPrimary}30`,
                pointerEvents: 'none'
              }}
            />
          </div>
          
          {/* Track title */}
          <div
            className="track-title"
            style={{
              marginTop: '30px',
              fontSize: isMobile ? '20px' : '24px',
              fontWeight: '600',
              textAlign: 'center'
            }}
          >
            {trackInfo.title || selectedMusic || 'Your Meditation'}
          </div>
        </div>
        
        {/* Progress bar and time indicators */}
        <div
          className="progress-container"
          style={{
            marginBottom: '20px'
          }}
        >
          <div
            className="progress-bar-container"
            ref={progressBarRef}
            style={{
              height: '4px',
              backgroundColor: 'rgba(255,255,255,0.1)',
              borderRadius: '2px',
              cursor: 'pointer',
              marginBottom: '8px'
            }}
            onClick={handleSeek}
          >
            <div
              className="progress-bar"
              style={{
                width: `${(currentTime / (duration || 1)) * 100}%`,
                height: '100%',
                background: `linear-gradient(to right, ${colorPrimary}, ${colorSecondary || '#ff3384'})`,
                borderRadius: '2px',
                transition: 'width 0.1s linear'
              }}
            />
          </div>
          
          <div
            className="time-display"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              opacity: 0.7
            }}
          >
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
        
        {/* Playback controls */}
        <div
          className="playback-controls"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: isMobile ? '20px' : '40px'
          }}
        >
          <div className="left-controls" style={{ display: 'flex', gap: '20px' }}>
            <button
              onClick={toggleFavorite}
              style={{
                background: 'transparent',
                border: 'none',
                color: isFavorite ? (colorSecondary || '#ff3384') : 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px'
              }}
            >
              {isFavorite ? <IoHeart size={24} /> : <IoHeartOutline size={24} />}
            </button>
            
            <button
              onClick={toggleMute}
              style={{
                background: 'transparent',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px'
              }}
            >
              {isMutedState ? <IoVolumeOff size={24} /> : <IoVolumeHigh size={24} />}
            </button>
          </div>
          
          <div
            className="center-controls"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <button
              onClick={() => handleSkip(-10)}
              style={{
                background: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px'
              }}
            >
              <IoPlaySkipBack size={24} />
            </button>
            
            <button
              onClick={togglePlayback}
              style={{
                background: `linear-gradient(135deg, ${colorPrimary}, ${colorSecondary || '#ff3384'})`,
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70px',
                height: '70px',
                boxShadow: `0 5px 15px ${colorPrimary}40`
              }}
            >
              {isPlaying ? <IoPauseCircle size={36} /> : <IoPlayCircle size={36} />}
            </button>
            
            <button
              onClick={() => handleSkip(10)}
              style={{
                background: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px'
              }}
            >
              <IoPlaySkipForward size={24} />
            </button>
          </div>
          
          <div className="right-controls" style={{ display: 'flex', gap: '20px' }}>
            <button
              onClick={() => {}} // Shuffle functionality
              style={{
                background: 'transparent',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px'
              }}
            >
              <IoShuffle size={22} />
            </button>
            
            <button
              onClick={togglePlaylist}
              style={{
                background: 'transparent',
                border: 'none',
                color: showPlaylist ? colorPrimary : 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px'
              }}
            >
              <IoList size={24} />
            </button>
          </div>
        </div>
      </div>
      
      {/* Playlist panel (always visible on desktop, toggleable on mobile) */}
      <div
        className="playlist-panel"
        style={{
          flex: isMobile ? 'none' : '0 0 40%',
          height: isMobile ? (showPlaylist ? '70%' : '0') : '100%',
          backgroundColor: 'rgba(0,0,0,0.3)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)', // For Safari
          borderLeft: isMobile ? 'none' : '1px solid rgba(255,255,255,0.1)',
          borderTop: isMobile ? '1px solid rgba(255,255,255,0.1)' : 'none',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
          position: 'relative',
          zIndex: 3
        }}
      >
        {/* Header for playlist */}
        <div
          className="playlist-header"
          style={{
            padding: '20px',
            borderBottom: '1px solid rgba(255,255,255,0.1)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h2 style={{ margin: 0, fontSize: '20px' }}>Playlist</h2>
          {isMobile && (
            <button
              onClick={togglePlaylist}
              style={{
                background: 'transparent',
                border: 'none',
                color: 'white',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <IoClose size={24} />
            </button>
          )}
        </div>
        
        {/* Playlist items */}
        <div
          className="playlist-items"
          style={{
            overflowY: 'auto',
            height: 'calc(100% - 70px)',
            padding: '10px 0'
          }}
        >
          {Array.isArray(musicLibrary) && musicLibrary.length > 0 ? (
            musicLibrary.map((track, index) => {
              if (!track || !track.name) return null;
              
              const displayName = track.displayName ||
                track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
              
              const isActive = track.name === selectedMusic;
              
              return (
                <div
                  key={track.name || index}
                  onClick={() => {
                    onMusicSelect(track.name);
                    if (isMobile) setShowPlaylist(false);
                  }}
                  style={{
                    padding: '15px 20px',
                    display: 'flex',
                    alignItems: 'center',
                    borderLeft: isActive ? `3px solid ${colorPrimary}` : '3px solid transparent',
                    backgroundColor: isActive ? 'rgba(255,255,255,0.05)' : 'transparent',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease'
                  }}
                >
                  <div style={{ marginRight: '15px', opacity: 0.6 }}>
                    {(index + 1).toString().padStart(2, '0')}
                  </div>
                  
                  <div style={{ flex: 1 }}>
                    <div style={{
                      fontWeight: isActive ? 'bold' : 'normal',
                      color: isActive ? colorPrimary : 'white'
                    }}>
                      {displayName}
                    </div>
                    <div style={{ fontSize: '14px', opacity: 0.6, marginTop: '4px' }}>
                      {track.artist || 'Pause Music'}
                    </div>
                  </div>
                  
                  <div style={{ opacity: 0.6 }}>
                    {track.duration ? formatTime(track.duration) : '0:00'}
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ padding: '30px 20px', textAlign: 'center', opacity: 0.6 }}>
              No tracks available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/* =============================================================================
   5. RobustThirdEyeVisualizer
   (A robust version of the ThirdEyeVisualizer that attempts to gracefully handle errors)
============================================================================= */
export const RobustThirdEyeVisualizer = ({
  analyzerNode = null,
  isPlaying = false,
  size = 300,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  tertiaryColor = '#9e65ff',
  sensitivity = 1.5,
  voiceFocus = true,
  audioConnected = false,
  id = 'default-visualizer',
  visualizationProcessor = null
}) => {
  const canvasRef = useRef(null);
  const rafRef = useRef(null);
  const dataArrayRef = useRef(null);
  const timeRef = useRef(0);
  const lastTimeRef = useRef(0);
  const isMountedRef = useRef(true);
  const processorRef = useRef(visualizationProcessor);

  const [dataDetected, setDataDetected] = useState(false);
  const [analyzerReady, setAnalyzerReady] = useState(false);
  const [error, setError] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const safeColors = {
    primary: primaryColor,
    secondary: secondaryColor,
    tertiary: tertiaryColor,
    background: '#02073c'
  };
  const safeSensitivity = typeof sensitivity === 'number' && !isNaN(sensitivity) ? sensitivity : 1.5;

  useEffect(() => {
    const updateDimensions = () => {
      if (!canvasRef.current) return;
      const dpr = window.devicePixelRatio || 1;
      const rect = canvasRef.current.getBoundingClientRect();
      const safeWidth = Math.max(100, rect.width || 100);
      const safeHeight = Math.max(100, rect.height || 100);
      setDimensions({ width: safeWidth, height: safeHeight, dpr });
      canvasRef.current.width = safeWidth * dpr;
      canvasRef.current.height = safeHeight * dpr;
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) ctx.scale(dpr, dpr);
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [size]);

  useEffect(() => {
    isMountedRef.current = true;
    processorRef.current = visualizationProcessor;
    const validateAnalyzer = () => {
      if (!analyzerNode) {
        setAnalyzerReady(false);
        return;
      }
      try {
        const hasGetByteFrequency = typeof analyzerNode.getByteFrequencyData === 'function';
        const hasValidFFTSize = typeof analyzerNode.fftSize === 'number' && analyzerNode.fftSize > 0;
        if (hasValidFFTSize && !dataArrayRef.current) {
          dataArrayRef.current = new Uint8Array(analyzerNode.frequencyBinCount || 1024);
        }
        if (hasGetByteFrequency && hasValidFFTSize) {
          try {
            analyzerNode.getByteFrequencyData(dataArrayRef.current);
            setAnalyzerReady(true);
          } catch (err) {
            setAnalyzerReady(false);
          }
        } else {
          setAnalyzerReady(false);
        }
      } catch (err) {
        setAnalyzerReady(false);
      }
    };
    validateAnalyzer();
    return () => { isMountedRef.current = false; if (rafRef.current) cancelAnimationFrame(rafRef.current); };
  }, [analyzerNode, visualizationProcessor]);

  const mapValue = useCallback((value, inMin, inMax, outMin, outMax) => {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }, []);

  const polygon = useCallback((ctx, x, y, radius, npoints, rotation = 0) => {
    const angle = (Math.PI * 2) / npoints;
    ctx.beginPath();
    for (let a = rotation; a < Math.PI * 2 + rotation; a += angle) {
      const sx = x + Math.cos(a) * radius;
      const sy = y + Math.sin(a) * radius;
      if (a === rotation) ctx.moveTo(sx, sy);
      else ctx.lineTo(sx, sy);
    }
    ctx.closePath();
  }, []);

  const getAudioData = useCallback(() => {
    let bass = 0, mid = 0, treble = 0, hasReal = false;
    if (processorRef.current) {
      try {
        const levels = processorRef.current.getAudioLevels({ sensitivity: safeSensitivity, voiceFocus, isPlaying });
        if (levels) {
          return { bass: levels.bass * safeSensitivity, mid: levels.mid * safeSensitivity, treble: levels.treble * safeSensitivity, isReal: !levels.isSimulated };
        }
      } catch (err) {
        console.warn("RobustThirdEyeVisualizer: Error from processor:", err);
      }
    }
    if (analyzerNode && analyzerReady) {
      try {
        if (!dataArrayRef.current) dataArrayRef.current = new Uint8Array(analyzerNode.frequencyBinCount || 1024);
        analyzerNode.getByteFrequencyData(dataArrayRef.current);
        let bassSum = 0, bassCount = 0;
        for (let i = 1; i < 15 && i < dataArrayRef.current.length; i++) {
          bassSum += dataArrayRef.current[i] || 0; bassCount++;
        }
        bass = bassCount ? bassSum / bassCount : 0;
        let midSum = 0, midCount = 0;
        for (let i = 15; i < 80 && i < dataArrayRef.current.length; i++) {
          midSum += dataArrayRef.current[i] || 0; midCount++;
        }
        mid = midCount ? midSum / midCount : 0;
        let trebleSum = 0, trebleCount = 0;
        for (let i = 80; i < 200 && i < dataArrayRef.current.length; i++) {
          trebleSum += dataArrayRef.current[i] || 0; trebleCount++;
        }
        treble = trebleCount ? trebleSum / trebleCount : 0;
        if (voiceFocus) { mid *= 1.2; bass *= 0.8; treble *= 0.7; }
        hasReal = true;
      } catch (err) {
        console.warn("RobustThirdEyeVisualizer: Error getting analyzer data:", err);
      }
    }
    if (!hasReal) {
      const t = timeRef.current;
      bass = isPlaying ? 50 + Math.sin(t * 0.6) * 45 : 30 + Math.sin(t * 0.3) * 20;
      mid = isPlaying ? 60 + Math.sin(t * 0.8 + 0.5) * 50 : 25 + Math.sin(t * 0.4 + 0.3) * 15;
      treble = isPlaying ? 55 + Math.sin(t * 0.7 + 0.9) * 40 : 20 + Math.sin(t * 0.35 + 0.7) * 15;
    }
    return { bass: bass * safeSensitivity, mid: mid * safeSensitivity, treble: treble * safeSensitivity, isReal: hasReal };
  }, [analyzerNode, analyzerReady, isPlaying, safeSensitivity, voiceFocus]);

  useEffect(() => {
    if (!canvasRef.current || !isMountedRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) { setError(true); return; }
    const dpr = dimensions.dpr || window.devicePixelRatio || 1;
    const canvasWidth = dimensions.width || canvas.width / dpr;
    const canvasHeight = dimensions.height || canvas.height / dpr;
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const animate = (timestamp) => {
      if (!isMountedRef.current || !canvasRef.current) return;
      const deltaTime = timestamp - (lastTimeRef.current || timestamp);
      lastTimeRef.current = timestamp;
      const cappedDelta = Math.min(deltaTime, 100);
      timeRef.current += cappedDelta * 0.001;
      ctx.fillStyle = safeColors.background;
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
      const { bass, mid, treble } = getAudioData();
      const mapBass = Math.max(30, mapValue(bass, 0, 255, 30, 200));
      const mapMid = Math.max(20, mapValue(mid, 0, 255, -80, 200));
      const mapTreble = Math.max(40, mapValue(treble, 0, 255, 180, 350));
      ctx.save();
      ctx.translate(centerX, centerY);
      const pieces = isPlaying ? 20 : 15;
      const rotationSpeed = timeRef.current * (isPlaying ? 0.25 : 0.15);
      for (let i = 0; i < pieces; i += 0.5) {
        ctx.rotate((Math.PI * 2) / (pieces / 2));
        ctx.save();
        ctx.strokeStyle = safeColors.primary;
        ctx.rotate(timeRef.current * 0.002);
        ctx.lineWidth = isPlaying ? 0.7 : 0.4;
        polygon(ctx, mapBass + i, mapBass - i, 3, 3, rotationSpeed);
        ctx.stroke();
        ctx.restore();
        if (i % 1 === 0) {
          ctx.save();
          ctx.strokeStyle = safeColors.secondary;
          ctx.lineWidth = isPlaying ? 0.3 : 0.2;
          polygon(ctx, mapMid + i / 2, mapMid - i * 2, 7, 7, -rotationSpeed * 0.5);
          ctx.stroke();
          ctx.restore();
        }
        if (i % 1.5 < 0.1) {
          ctx.save();
          ctx.strokeStyle = safeColors.tertiary;
          ctx.lineWidth = isPlaying ? 0.8 : 0.5;
          const scaleAmt = 0.5 + Math.sin(timeRef.current * 0.2) * 0.2;
          ctx.scale(scaleAmt, scaleAmt);
          ctx.rotate(timeRef.current * 0.002);
          polygon(ctx, mapTreble + i / 2, mapTreble - i / 2, 3, 3, rotationSpeed * 0.3);
          ctx.stroke();
          ctx.restore();
        }
      }
      ctx.restore();
      if (isPlaying) {
        const glowRadius = 20 + Math.sin(timeRef.current * 2) * 8;
        const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, centerX + glowRadius);
        gradient.addColorStop(0, 'rgba(125,18,255,0.01)');
        gradient.addColorStop(0.7, 'rgba(125,18,255,0.05)');
        gradient.addColorStop(1, 'rgba(125,18,255,0)');
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
      }
      rafRef.current = requestAnimationFrame(animate);
    };
    rafRef.current = requestAnimationFrame(animate);
    return () => {
      if (rafRef.current) { cancelAnimationFrame(rafRef.current); rafRef.current = null; }
    };
  }, [safeColors, isPlaying, mapValue, polygon, getAudioData, dimensions]);

  if (error) return null;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        boxShadow: 'inset 0 0 30px rgba(0,0,0,0.5)'
      }}
      data-visualizer-id={id}
    >
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover' }} />
    </div>
  );
};

export default React.memo(RobustThirdEyeVisualizer);
